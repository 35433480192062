import axios from "axios";
import { getTokenData } from ".";
import { getClientDigitalInformation } from "./ipify";
const IP_CACHE_ENABLED = process.env.REACT_APP_IP_CACHE_ENABLED === "true";
const AXIOS_TIMEOUT_MS = 1000 * Number(process.env.REACT_APP_AXIOS_TIMEOUT_SECONDS || 300); // In Milliseconds (default 5minutes)
let AXIOS_OPTIONS = { timeout: AXIOS_TIMEOUT_MS }; // (in ms) timeout of zero means there is no timeout limit 

// API Calls 
const UserAPI = {
  session: async () => {
    let { ip } = IP_CACHE_ENABLED ? await getClientDigitalInformation() : {};
    return await axios.get(`/api/session${ip ? `?ip=${ip}` : ''}`, AXIOS_OPTIONS);
  },
  getLoginFields: async (queryParams = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, params: queryParams, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }

    return await axios.get(`/auth`, axiosOptions);
  },
  tryLogin: (payload = {}) => axios.post(`/auth`, payload, { responseType: 'json' }),
  logout: async () => {
    ['access_token', 'access_token_exp', 'refresh_token', 'refresh_token_exp'].forEach((key) => (
      typeof localStorage !== 'undefined' ? localStorage.removeItem(key)
        : (typeof window?.localStorage !== 'undefined' ? window.localStorage.removeItem(key) : null)
    ));
    return await axios.get(`/logout`, AXIOS_OPTIONS);
  },
  updateUser: async ({ user_id, ...payload } = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.patch(`/api/users/${user_id}`, payload, axiosOptions);
  },
  getUser: async (params, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    if (typeof params === 'string') {
      params = { user_id: params };
    }
    const { user_id, ...queryParams } = params || {}

    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, params: queryParams, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.get(`/api/users/${user_id}`, axiosOptions);
  },
  getAllUsers: async (queryParams = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, params: queryParams, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.get(`/api/users`, axiosOptions);
  },
  newUser: async (payload = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.post(`/api/users`, payload, axiosOptions);
  },
  removeUser: async (params, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    if (typeof params === 'string') {
      params = { user_id: params };
    }
    const { user_id } = params || {}

    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.delete(`/api/users/${user_id}`, axiosOptions);
  },
  getUserRoles: async (queryParams = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, params: queryParams, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.get(`/api/users/roles`, axiosOptions);
  },
  generatePassword: async (queryParams = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, params: queryParams, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    const resp = await axios.get(`/api/generate/password`, axiosOptions);
    let { status, statusText, data = {} } = resp || {};
    let { password } = (data != null && typeof data === 'object') ? data : { password: data };
    const error = status === 200 ? null : statusText;
    return { error, password };
  },
  generateAPIKey: async (queryParams = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, params: queryParams, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    const resp = await axios.get(`/api/generate/api-key`, axiosOptions);
    let { status, statusText, data = {} } = resp || {};
    let { apikey } = (data != null && typeof data === 'object') ? data : { apikey: data };
    const error = status === 200 ? null : statusText;
    return { error, apikey };
  }
};

const AccountAPI = {
  updateAccount: async ({ account_id, ...payload } = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.patch(`/api/accounts/${account_id}`, payload, axiosOptions);
  },
  getAccount: async (params, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    if (typeof params === 'string') {
      params = { account_id: params };
    }
    const { account_id, ...queryParams } = params || {}

    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, params: queryParams, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.get(`/api/accounts/${account_id}`, axiosOptions);
  },
  getAllAccounts: async (queryParams = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, params: queryParams, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.get(`/api/accounts`, axiosOptions);
  },
  newAccount: async (payload = {}, tokenData = {}) => {
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.post(`/api/accounts`, payload, axiosOptions);
  },
  removeAccount: async (params, tokenData = {}) => {
    if (typeof params === 'string') {
      params = { account_id: params };
    }
    const { account_id } = params || {}

    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { ...AXIOS_OPTIONS, responseType: 'json' };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.delete(`/api/accounts/${account_id}`, axiosOptions);
  },
};

const API = {
  UserAPI,
  AccountAPI,
};

export {
  UserAPI,
  AccountAPI,
};
export default API;

/* NOTE: getAllUsers can be accessed by any of the following methods
import { getAllUsers } from "./utils/api"; // Because Of the 'export' above

import API from "./utils/api"; // Because of the 'default export'
const { getAllUsers } = API;

import API from "./utils/api";
API.getAllUsers
*/