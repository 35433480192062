export const TEMPLATES = {
  visibleFields: ["template_id", "template_name", "tag", "is_published"],
  initialValues: {
    template_id: "",
    template_name: "",
    tag: "", // VZuHvyDxLh
    is_published: ""
  },
  labels: {
    template_id: "Template ID",
    template_name: "Name",
    tag: "Tags",
    is_published: "Published"
  }
};

export const TAGS = {
  visibleFields: ["tag_id", "title"],
  initialValues: {
    tag_id: "",
    title: ""
  },
  labels: {
    tag_id: "Tag ID",
    title: "Title"
  }
};

export const REQUESTS = {
  visibleFields: ["request_id", "requestor_external_id", "requestor_name", "requestor_email", "recipient_external_id", "recipient_name", "recipient_email", "template_name", "valid_until", "completed"],
  visibleTotalFields: ['account_name', 'requestor_external_id', 'total'],
  initialValues: {
    request_id: "",
    requestor_external_id: "",
    requestor_email: "",
    recipient_external_id: "",
    recipient_email: "",
    template_name: "",
    valid_until: "",
    completed: "",
    start_date: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString().replace(/T.*/, ''), // First day of last month 
    end_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().replace(/T.*/, ''), // First day of this month
  },
  labels: {
    request_id: "Request ID",
    requestor_external_id: "Firm ID",
    recipient_external_id: "Matter ID",
    requestor_name: "From",
    requestor_email: "From Email",
    recipient_name: "To",
    recipient_email: "To Email",
    template_name: "Template",
    valid_until: "Expires After",
  }
};

export const USERS = {
  visibleFields: ["user_id", "name", "email", "role_name", "account_name", "enabled"],
  initialValues: {
    user_id: "",
    name: "",
    email: "",
    role_name: "",
    account_name: "",
    enabled: "",
  },
  labels: {
    user_id: "User ID",
    role_name: "Role",
    account_name: "Account",
  }
};

export const ACCOUNTS = {
  visibleFields: ["account_id", "external_id", "name", "enabled"],
  initialValues: {
    account_id: "",
    external_id: "",
    name: "",
    enabled: "",
  },
  labels: {
    account_id: "Account ID",
    external_id: "External ID",
    name: "Account Name",
  }
};

export const formatTagArray = (tags = [], toString = false) => {
  if (typeof tags === "string" && toString) {
    return tags; // Tag should be a string so return as is 
  }
  if (Array.isArray(tags) && !toString) {
    return tags; // Tag should be an array so return as is 
  }

  if (!tags || (Array.isArray(tags) && !tags.length === 0)) {
    return toString ? "" : [];
  } else if (!toString && Array.isArray(tags)) {
    tags = tags.join(","); // Tag should be a string 
  }

  return toString ? tags.join(",")
    : tags.split(",").map((tag) => `${tag}`.trim())
};

export const convertTagsApiFormat = (tags, availableTags) => {
  return availableTags.filter((tag) => {
    return tags.includes(tag.title);
  });
};

export const convertTemplateName = (name = "", toUnderscore = false) => {
  if (!name) {
    return "";
  }
  return toUnderscore ? name.replaceAll(" ", "_")
    : name.replaceAll("_", " ");
};

export const colorOptions = [
  '#00B8D9',
  '#0052CC',
  '#5243AA',
  '#FF5630',
  '#FF8B00',
  '#FFC400',
  '#36B37E',
  '#00875A',
  '#253858',
  '#666666'
];