import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, Form as BootstrapForm } from 'react-bootstrap';
import { Formik, Field, Form } from "formik";
import IconButton from './common/IconButton';
import { removeEmptyFromObj } from '../utils/';
import { pdfAPI } from '../utils/pdf-api';
const { exportDataToExcel } = pdfAPI || {};

/**
 * ExportButton 
 * @param {object} params - { 
 * cb - (function) callback function to run after api call, 
 * controllerName - (string) controller where function to call is located,
 * fnName - (string) function to call on contextController,
 * saveAs - (string) name of file to save as, 
 * params - (object) params to pass to function, 
 * additionalParamsArray - (array) array of additional params to pass to function
 * flattenData - (boolean) whether to flatten data or not,
 * appendDuplicates - (boolean) whether to append duplicates or not,
 * overwriteDuplicates - (boolean) whether to overwrite duplicates or not
 * isDark - (boolean) whether to use dark theme or not
 * label - (string) label for button
 * }
 * @returns handleConfirm - function to call api endpoint with provided params that will export data as excel and finally run args.cb if provided
 */
const ExportButton = ({ cb, controllerName, fnName, saveAs, params = {}, additionalParamsArray = [], flattenData = false, appendDuplicates = true, overwriteDuplicates = false, isDark = false, label = "Save To Excel", size = "md", ...props } = {}) => {
  if (!Array.isArray(additionalParamsArray)) {
    if (additionalParamsArray != null && typeof additionalParamsArray === 'object') {
      additionalParamsArray = [additionalParamsArray]; // Convert to array
    } else {
      additionalParamsArray = []; // Set to empty array
    }
  }
  if (params == null || typeof params !== 'object') {
    params = {};
  }

  const rowsSelectedCount = (params?.selectedIds || []).length || (params?.selectedRowIds || []).length;
  const hasRowsSelected = rowsSelectedCount > 0;
  const [modalIsShown, setModalState] = useState(false);
  const handleOpen = () => (setModalState(true));
  const handleClose = () => (setModalState(false));

  if (typeof cb !== 'function') {
    cb = ({ status, statusText, data } = {}) => {
      let { ok, statusMessage, error } = data || {};
      if (ok === undefined) {
        ok = Number(status) === 200;
      }
      if (statusMessage === undefined) {
        statusMessage = statusText || ok ? "Success!" : "Failed!";
      }
      if (error) {
        statusMessage = `${error}`;
        ok = false;
      }

      const message = ok ? statusMessage : `[${status}] ${statusMessage}`;
      const toastParams = { position: "top-right", autoClose: 10000, pauseOnHover: true, theme: isDark ? "dark" : "light" };

      if (ok) {
        console.log(message);
        toast.success(message, toastParams)
      } else {
        console.error(message);
        toast.warning(message, toastParams);
      }

      return { status, statusText, data };
    };
  }

  const handleConfirm = async (opt = {}) => {
    const { removeEmptyOrNull = true, updatedSaveAs = null } = opt || {};
    if (removeEmptyOrNull) { // Maybe we want to search where a field is null or empty string so we may not always want to remove empty params
      let keepParams = 'completed' in params && typeof params.completed === 'boolean' ? { completed: params.completed } : {};
      params = { ...removeEmptyFromObj(params || {}), ...keepParams };
    }

    let payload = {
      controllerName,
      fnName,
      saveAs: (updatedSaveAs && saveAs !== updatedSaveAs) ? updatedSaveAs : saveAs,
      flattenData,
      appendDuplicates,
      overwriteDuplicates,
      argArray: [params, ...additionalParamsArray]
    };

    try {
      await exportDataToExcel(payload, cb);
    } catch (e) {
      let { status, statusText, headers = {}, data } = e?.response || {};
      let type = headers['content-type'] || 'application/pdf'; // Extract type from header 

      if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
        let tmpData = typeof data?.text === 'function' ? await data.text() : data;
        if (type.includes('application/json')) {
          try {
            data = JSON.parse(tmpData); // { error }
          } catch (err) {
            console.error("JSON.parse Error", err);
            data = { error: tmpData };
          }
        } else {
          data = {
            ok: status === 200,
            statusCode: status,
            statusMessage: tmpData,
          };
        }
      }
      cb({ status, statusText, data });
    }
    return handleClose();
  };

  return (<>
    <IconButton variant={isDark ? "falcon-default" : "primary"} size={size} icon="excel" iconAlign="right" transform="shrink-3" onClick={handleOpen}>
      <span className="d-none d-sm-inline-block ms-1">{label}</span>
    </IconButton>
    <Modal show={modalIsShown} onHide={handleClose}  {...props} >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Confirm Export</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={{ updatedSaveAs: saveAs }} onSubmit={async (values, { setSubmitting }) => {
          await handleConfirm({ ...values, removeEmptyOrNull: true });
          setSubmitting(false);
        }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm, setFieldValue }) => (<Form className="mt-4 position-relative">
            <BootstrapForm.Group className="my-2">
              <BootstrapForm.Label className="h6">Save As</BootstrapForm.Label>
              <Field className="form-control-sm" as={BootstrapForm.Control} type="text" name="updatedSaveAs" value={values.updatedSaveAs} onChange={handleChange} onBlur={handleBlur}></Field>
            </BootstrapForm.Group>
            <div className="d-flex justify-content-end">
              <Button type="submit" variant="primary" className="my-3 me-2" disabled={isSubmitting}>
                Export {hasRowsSelected ? rowsSelectedCount + " Selected" : "All of Search"}
              </Button>
              <Button type="button" variant="outline-danger" className="my-3" onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
            </div>
          </Form>)}
        </Formik>
      </Modal.Body>
    </Modal>
  </>);
};

export default ExportButton;