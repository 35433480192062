import axios from "axios";
import { AxiosError } from 'axios';
import { getTokenData } from ".";
import { getClientDigitalInformation } from "./ipify";
const IP_CACHE_ENABLED = process.env.REACT_APP_IP_CACHE_ENABLED === "true";
const BASIC_AXIOS_OPTIONS = { responseType: 'json', timeout: 1000 * 60 * 5 };
export const pdfAPI = {
  streamPDF: async ({ request_id = 0, template_id } = {}) => {
    let streamURL = "";
    if (request_id) {
      streamURL = `/doc/${request_id}`;
      if (template_id) {
        streamURL += `?template_id=${template_id}`;
      }
    } else if (template_id) {
      streamURL = `/doc-preview/${template_id}`;
      if (request_id) {
        streamURL += `?request_id=${request_id}`;
      }
    }
    if (!streamURL) {
      return {
        error: "Missing request_id or template_id"
      };
    }

    return await axios.get(streamURL, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let { status, statusText, headers = {}, data } = response || {};
      const type = headers['content-type'] || 'application/pdf'; // Extract type from header 

      const extractedHeaders = {};
      const customResponseHeaders = ['document-signed', 'request_id', 'template_id', 'template_name', 'filename', 'signature_options', 'recipient_name'];
      customResponseHeaders.forEach((h) => {
        if (h in headers) {
          if (h === 'document-signed') {
            extractedHeaders.documentSigned = headers[h] === 'true'; // Convert to boolean and use camelCase 
          } if (h === 'signature_options') {
            extractedHeaders[h] = Array.isArray(headers[h]) ? headers[h].map((opt) => opt.trim()) : headers[h].split(',').map((opt) => opt.trim());
          } else {
            extractedHeaders[h] = headers[h];
          }
        }
      });
      if (!extractedHeaders.filename) { // Extract filename from header
        let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
        const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
        let m;
        if ((m = regex.exec(disposition)) !== null) {
          m.forEach((match, groupIndex) => {
            if (groupIndex === 1) {
              extractedHeaders.filename = match;
            }
          });
        }
      }

      if (status === 200) {
        if (type.includes("application/json")) {
          let tmpData = typeof data?.text === 'function' ? await data.text() : data;
          try {
            data = JSON.parse(tmpData);
          } catch (e) {
            console.error("JSON.parse Error", e);
          }
        }
        const pdfStream = 'pdfStream' in data ? new Blob([data.pdfStream], { type: 'application/pdf' }) : (type === 'application/pdf' ? new Blob([data], { type }) : null);
        const dataObj = type === 'application/pdf' ? {} : data;
        return Object.keys(extractedHeaders).length > 0 ? { ...extractedHeaders, ...dataObj, pdfStream } : pdfStream || dataObj;
      } else {
        if (status === 204 || data.size === 0) {
          data = {
            ok: status === 200,
            statusCode: status,
            statusMessage: `No preview found with request_id: ${request_id} (${statusText || 'No Data Found'})`,
            message: `No preview found with request_id: ${request_id} (${statusText || 'No Data Found'})`,
          };
        } else if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
          let tmpData = typeof data?.text === 'function' ? await data.text() : data;
          if (tmpData && type.includes('application/json')) {
            try {
              data = JSON.parse(tmpData);
            } catch (e) {
              console.error("JSON.parse Error", e);
            }
          } else {
            data = {
              ok: status === 200,
              statusCode: status,
              statusMessage: `No preview found with request_id: ${request_id} (${tmpData || statusText || 'No Data Found'})`,
            };
          }
        }

        let errorMessage = data?.statusMessage ? `${data.statusMessage} (${status})` : `Unable to retrieve file blob (${status})`;
        console.log(data);
        console.error(errorMessage);
        return { ...extractedHeaders, ...data, error: errorMessage };
      }
    });
  },
  streamPreview: async ({ uuid = 0 } = {}) => {
    if (!uuid) {
      let errorMessage = "Access Denied! Missing uuid";
      return {
        error: errorMessage, message: errorMessage,
        response: { err: 400, error: errorMessage, status: errorMessage, message: errorMessage }
      };
    }
    let streamURL = `/api/preview/${uuid}`;
    let { ip } = await getClientDigitalInformation();
    if (ip) {
      streamURL += `?ip=${ip}`;
    }

    return await axios.get(streamURL, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let { status, statusText, headers = {}, data } = response || {};
      const type = headers['content-type'] || 'application/pdf'; // Extract type from header 

      const extractedHeaders = { type };
      const customResponseHeaders = ['document-signed', 'uuid', 'template_id', 'template_name', 'account_id', 'ip', 'filename'];
      customResponseHeaders.forEach((h) => {
        if (h in headers) {
          if (h === 'document-signed') {
            extractedHeaders.documentSigned = headers[h] === 'true'; // Convert to boolean and use camelCase 
          } else {
            extractedHeaders[h] = headers[h];
          }
        }
      });
      if (!extractedHeaders.filename) { // Extract filename from header
        let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
        const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
        let m;
        if ((m = regex.exec(disposition)) !== null) {
          m.forEach((match, groupIndex) => {
            if (groupIndex === 1) {
              extractedHeaders.filename = match;
            }
          });
        }
      }

      if (status === 200) {
        if (type.includes("application/json")) {
          let tmpData = typeof data?.text === 'function' ? await data.text() : data;
          try {
            data = JSON.parse(tmpData);
          } catch (e) {
            console.error("JSON.parse Error", e);
          }
        }
        const pdfStream = 'pdfStream' in data ? new Blob([data.pdfStream], { type: 'application/pdf' }) : (type === 'application/pdf' ? new Blob([data], { type }) : null);
        const dataObj = type === 'application/pdf' ? {} : data;
        return Object.keys(extractedHeaders).length > 0 ? { ...extractedHeaders, ...dataObj, pdfStream } : pdfStream || dataObj;
      } else {
        if (status === 204 || data.size === 0) {
          data = {
            ok: status === 200,
            statusCode: status,
            statusMessage: `No preview found with uuid: ${uuid} (${statusText || 'No Data Found'})`,
            message: `No preview found with uuid: ${uuid} (${statusText || 'No Data Found'})`,
          };
        } else if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
          let tmpData = typeof data?.text === 'function' ? await data.text() : data;
          if (tmpData && type.includes('application/json')) {
            try {
              data = JSON.parse(tmpData);
            } catch (e) {
              console.error("JSON.parse Error", e);
            }
          } else {
            data = {
              ok: status === 200,
              statusCode: status,
              statusMessage: `No preview found with uuid: ${uuid} (${tmpData || statusText || 'No Data Found'})`,
            };
          }
        }

        let errorMessage = data?.statusMessage ? `${data.statusMessage} (${status})` : `Unable to retrieve file blob (${status})`;
        console.log(data);
        console.error(errorMessage);
        return { ...extractedHeaders, ...data, error: errorMessage };
      }
    });
  },
  downloadCompletedDocument: async ({ request_id = 0, ...queryParams } = {}, tokenData = {}) => {
    if (!request_id) {
      return {
        error: "Bad Request!",
        message: "request_id is required!",
        response: {
          ok: false,
          statusCode: 400,
          statusMessage: "Bad Request!"
        }
      };
    }
    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    queryParams.format = 'pdf';
    let axiosOptions = { params: queryParams, responseType: 'blob', timeout: 1000 * 60 * 5 };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }

    let resp = await axios.get(`/api/esign/${request_id}`, axiosOptions);
    let { status, statusText, headers = {}, data } = resp || {};
    let type = headers['content-type'] || 'application/json'; // Extract type from header 

    if (status === 204 || data.size === 0) {
      data = {
        ok: status === 200,
        statusCode: status,
        statusMessage: statusText || 'No Data Found',
      };
    } else if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
      let tmpData = typeof data?.text === 'function' ? await data.text() : data;
      if (tmpData && type.includes('application/json')) {
        try {
          data = JSON.parse(tmpData);
        } catch (e) {
          console.error("JSON.parse Error", e);
        }
      } else {
        data = {
          ok: status === 200,
          statusCode: status,
          statusMessage: tmpData || statusText || 'No Data Found',
        };
      }
    } else { // Download the file 
      const blob = new Blob([data], { type });

      // Extract filename from header
      let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
      const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
      let m;
      let filename = headers.filename || '';
      if (!filename && (m = regex.exec(disposition)) !== null) {
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            filename = match;
          }
        });
      }

      // Download the file 
      const link = document.createElement('a');
      link.download = filename;
      link.href = URL.createObjectURL(blob);
      link.addEventListener('click', () => {
        setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
      });
      link.click();
      data = {
        ok: status === 200,
        statusCode: status,
        statusMessage: `${filename} Successfully Downloaded!`
      };
    }

    return { error: status > 399 ? statusText : null, message: data.statusMessage || statusText, response: data };
  },
  saveFieldData: async ({ request_id = 0, field_id = 0, ...payload } = {}, tokenData = {}) => {
    // Get client IP and User Agent 
    let { ip, user_agent } = await getClientDigitalInformation();
    if (ip) {
      payload.ip = ip;
    }
    if (user_agent) {
      payload.ua = user_agent;
    }

    // Construct URL 
    let saveURL = `/save/${request_id}`;
    if (field_id) {
      saveURL += `/${field_id}`;
    }

    // Set Headers 
    const requiredTokenDataFields = ['access_token', 'access_token_exp', 'refresh_token', 'refresh_token_exp'];
    if (!requiredTokenDataFields.every(key => key in tokenData)) {
      tokenData = getTokenData(requiredTokenDataFields);
    }
    let { access_token, refresh_token } = tokenData || {};
    let setHeaders = {};
    if (access_token || refresh_token) {
      setHeaders = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    let axiosOptions = {
      ...BASIC_AXIOS_OPTIONS,
      ...(Object.keys(setHeaders).length > 0 && { headers: setHeaders })
    };

    // Remove Unnecessary Fields
    if (['page', 'rect'].some((k) => k in payload)) {
      ['page', 'rect'].forEach((k) => k in payload && delete payload[k]);
    }

    let resp;
    try {
      resp = await axios.post(saveURL, payload, axiosOptions);
    } catch (error) {
      if (error instanceof AxiosError) {
        let { response = {} } = error || {};
        let { status, statusText, data = {} } = response || {};
        resp = { status, statusText, data };
        console.error("saveFieldData AxiosError:", resp);
      } else {
        resp = { status: 400, statusText: error?.message, data: error };
        console.error("saveFieldData Error:", resp);
      }
    }
    let { status, statusText, headers = {}, data: response = null } = resp || {};
    let responseType = headers['content-type'] || 'application/json'; // Extract type from header
    if (status === 204) {
      response = {
        statusCode: status,
        statusMessage: statusText || 'No Data Found',
        data: response
      };
    } else if (["text/html", "text/plain"].some((t) => (responseType.includes(t)))) {
      response = {
        statusCode: status,
        statusMessage: statusText || 'No Data Found',
        data: response
      };
    } else if (!response) {
      response = {
        statusCode: status,
        statusMessage: statusText || 'No Data Found',
        data: response
      };
    }
    if ('statusCode' in response === false) {
      response.statusCode = status;
    }
    if ('statusMessage' in response === false) {
      response.statusMessage = statusText;
    }
    return response;
  },
  saveFilledPdf: async ({ request_id = 0, template_id, pdf_template, ...data } = {}) => {
    let { ip } = IP_CACHE_ENABLED ? await getClientDigitalInformation() : {};
    let resp = await axios.post(`/doc/${request_id}`, { template_id, pdf_template, cleanup_files: !request_id, ...data, ...(ip && { ip }) }, { responseType: 'blob', timeout: 1000 * 60 * 5 });

    let { status, statusText, headers, data: response } = resp || {};
    let type = headers['content-type'] || 'application/pdf'; // Extract type from header
    let message = statusText;

    const extractedHeaders = {};
    const customResponseHeaders = ['document-signed', 'request_id', 'template_id', 'template_name', 'filename', 'email_completed_to_recipient', 'recipient_email', 'recipient_name', 'signature_options'];
    customResponseHeaders.forEach((h) => {
      if (h in headers) {
        if (h === 'document-signed') {
          extractedHeaders.documentSigned = typeof headers[h] === 'string' ? headers[h] === 'true' : headers[h]; // Convert to boolean and use camelCase 
        } if (h === 'signature_options') {
          extractedHeaders[h] = Array.isArray(headers[h]) ? headers[h] : headers[h].split(',');
        } else if (h === 'email_completed_to_recipient') {
          extractedHeaders.emailCompletedToRecipient = typeof headers[h] === 'string' ? headers[h] === 'true' : headers[h]; // Convert to boolean and use camelCase
        } else {
          extractedHeaders[h] = headers[h];
        }
      }
    });

    if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
      let tmpData = typeof response?.text === 'function' ? await response.text() : response;
      if (type.includes("application/json")) {
        try {
          response = JSON.parse(tmpData);
        } catch (e) {
          console.error("JSON.parse Error", e);
        }
        response.statusCode = status;
        response.statusMessage = message;
      } else {
        message = tmpData;
      }
    } else {
      let disposition = headers['content-disposition'];
      const filenameRegex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
      let m;
      let filename = '';
      if ((m = filenameRegex.exec(disposition)) !== null) {
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            filename = match;
          }
        });
      }
      if (!filename) {
        filename = `${template_id}_${pdf_template}.pdf`;
      }

      // Download the file 
      const link = document.createElement('a');
      link.download = filename;
      link.href = URL.createObjectURL(response);
      link.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
      });
      link.click();

      message = `Successfully Downloaded File ${filename}`;
      response = {
        statusCode: status,
        statusMessage: message,
      };
    }

    if (Object.keys(extractedHeaders).length > 0) {
      response = { ...response, ...extractedHeaders };
    }
    let error = status > 399 ? message : null;
    return { error, response, message };
  },
  getAllEsignRequests: async (queryParams = {}, tokenData = {}) => {
    const params = Object.fromEntries(Object.entries(queryParams).filter(([_, v]) => v)); // Remove empty values 
    if ('completed' in queryParams && typeof queryParams.completed === 'boolean') {
      params.completed = queryParams.completed;
    }
    const axiosOptions = { params, responseType: 'json', timeout: 1000 * 60 * 5 };

    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }
    return await axios.get(`/api/esign`, axiosOptions);
  },
  getEsignRequest: async ({ request_id = 0, ...queryParams } = {}, tokenData = {}) => {
    if (!request_id) {
      return {
        status: 400,
        statusText: "Bad Request",
        data: {
          message: "request_id is required!"
        }
      };
    }

    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }

    const { access_token, refresh_token } = tokenData || {};
    const axiosOptions = { params: queryParams, responseType: 'json', timeout: 1000 * 60 * 5 };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }

    return await axios.get(`/api/esign/${request_id}`, axiosOptions);
  },
  getEsignRequestAuthenticationFields: async ({ request_id = 0, ...queryParams } = {}, tokenData = {}) => {
    if (!request_id) {
      return {
        status: 400,
        statusText: "Bad Request! request_id is required!",
        data: "Bad Request! request_id is required!",
      };
    }

    if (Object.keys(tokenData).length === 0) {
      tokenData = getTokenData();
    }
    let { access_token, refresh_token } = tokenData || {};
    let axiosOptions = { params: queryParams, responseType: 'json', timeout: 1000 * 60 * 5 };
    if (access_token || refresh_token) {
      axiosOptions.headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }

    let resp = await axios.get(`/api/esign/${request_id}`, axiosOptions);
    const { status, statusText, data = [] } = resp || {};
    if (status !== 200) {
      return { status, statusText, data };
    }

    const { protected_route = false, isVerified = false, hasAccessToPage = null, authentication = {}, ...d } = Array.isArray(data) ? data[0] || {} : data || {};
    if (protected_route !== isVerified) {
      console.log(`Protected route: ${protected_route} !== isVerified: ${isVerified}`);
    }

    const { fields = [] } = authentication || {};
    return { status, statusText, data: { protected_route, fields, isVerified, hasAccessToPage, tokenData, ...d } };
  },
  verifyAccessToEsignRequest: async ({ request_id = 0, ...postData } = {}) => {
    if (!request_id) {
      return {
        status: 400, statusText: "Bad Request",
        data: {
          isVerified: false, request_id, hasAccessToPage: null, message: "request_id is required!"
        }
      };
    }
    return await axios.post(`/auth/${request_id}`, postData, { responseType: 'json', timeout: 1000 * 60 * 5 });
  },
  modifyEsignRequest: async ({ request_id = 0, ...postData } = {}, tokenData = {}) => {
    if (!request_id) {
      return { error: "Bad Request!", message: "request_id is required!", response: postData };
    }

    const requiredTokenDataFields = ['access_token', 'access_token_exp', 'refresh_token', 'refresh_token_exp'];
    if (!requiredTokenDataFields.every(key => key in tokenData)) {
      tokenData = getTokenData(requiredTokenDataFields);
    }

    let { access_token, refresh_token } = tokenData || {};
    let headers = {};
    if (access_token || refresh_token) {
      headers = {
        Authorization: `Bearer ${access_token || refresh_token}`
      };
    }

    let axiosOptions = {
      responseType: 'json',
      timeout: 1000 * 60 * 5,
      ...(Object.keys(headers).length > 0 && { headers })
    };

    /**
     * Possible actions from postData.action (defaults to updating the esign request):
     * check_status: Sends a reminder email to recipients if the request is still unsigned 
     * send_completed_document: Sends the completed document to the recipient 
     */
    const resp = await axios.patch(`/api/esign/${request_id}`, postData, axiosOptions) || {};
    let { status, statusText, data } = resp || {};
    let { message } = data[0] || data || {};
    const error = status === 200 ? null : message || statusText;
    return { error, response: data, message };
  },

  getAllPDFTemplates: async (queryParams) => {
    const populatedValues = Object.fromEntries(Object.entries(queryParams).filter(([_, v]) => v));
    const axiosOptions = { params: populatedValues, ...BASIC_AXIOS_OPTIONS };
    if ('is_published' in queryParams) {
      populatedValues.is_published = queryParams.is_published;
    }

    return await axios.get(`/api/pdf_template`, axiosOptions) || {};
  },

  getPDFTemplate: async (templateId) => {
    return await axios.get(`/api/pdf_template/${templateId}`, BASIC_AXIOS_OPTIONS) || {};
  },

  getAllTags: async (queryParams = {}) => {
    const populatedValues = Object.fromEntries(Object.entries(queryParams).filter(([_, v]) => v));
    const axiosOptions = { ...(Object.keys(populatedValues).length > 0 && { params: populatedValues }), ...BASIC_AXIOS_OPTIONS };
    return await axios.get(`/api/tag`, axiosOptions) || {};
  },

  updateTemplate: async (template_id, payload, headers = {}) => {
    return template_id ? await axios.patch(`/api/pdf_template/${template_id}`, payload, { ...BASIC_AXIOS_OPTIONS, headers })
      : await axios.post('/api/pdf_template', payload, { ...BASIC_AXIOS_OPTIONS, headers });
  },

  deleteTemplate: async (template_id) => {
    return await axios.delete(`/api/pdf_template/${template_id}`, BASIC_AXIOS_OPTIONS) || {};
  },

  getTag: async (tag_id) => {
    return await axios.get(`/api/tag/${tag_id}`, BASIC_AXIOS_OPTIONS) || {};
  },

  updateTag: async (tag_id, payload) => {
    return tag_id ? await axios.patch(`/api/tag/${tag_id}`, payload, BASIC_AXIOS_OPTIONS)
      : await axios.post('/api/tag', payload, BASIC_AXIOS_OPTIONS);
  },

  deleteTagById: async (tag_id) => {
    return await axios.delete(`/api/tag/${tag_id}`, BASIC_AXIOS_OPTIONS) || {};
  },

  exportDataToExcel: async (data, cb = () => { }) => { // Export data to excel should work with any controller function! 
    return await axios.post(`/api/export`, data, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let { status, statusText, headers = {}, data } = response || {};
      let type = headers['content-type'] || 'application/json'; // Extract type from header 

      if (status === 204 || data.size === 0) {
        data = {
          ok: status === 200,
          statusCode: status,
          statusMessage: statusText || 'No Data Found',
        };
      } else if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
        let tmpData = typeof data?.text === 'function' ? await data.text() : data;
        if (tmpData && type.includes('application/json')) {
          try {
            data = JSON.parse(tmpData);
          } catch (e) {
            console.error("JSON.parse Error", e);
          }
        } else {
          data = {
            ok: status === 200,
            statusCode: status,
            statusMessage: tmpData || statusText || 'No Data Found',
          };
        }
      } else { // Download the file 
        const blob = new Blob([data], { type });

        // Extract filename from header
        let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
        const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
        let m;
        let filename = '';
        if ((m = regex.exec(disposition)) !== null) {
          m.forEach((match, groupIndex) => {
            if (groupIndex === 1) {
              filename = match;
            }
          });
        }

        // Download the file 
        const link = document.createElement('a');
        link.download = filename;
        link.href = URL.createObjectURL(blob);
        link.addEventListener('click', () => {
          setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
        });
        link.click();
        data = {
          ok: status === 200,
          statusCode: status,
          statusMessage: `${filename} Successfully Downloaded!`
        };
      }

      if (typeof cb === 'function') {
        return cb({ status, statusText, data });
      }
      return { status, statusText, data };
    });
  },
}; 