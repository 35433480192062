import { useEffect, useState } from "react";
import { useParams, useLocation, Link, Navigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import UserDetailsForm from "../components/forms/UserDetailsForm";
import { UserAPI } from "../utils/API";

export default function UserDetails({ theme, children, isModal = false, hideModal, ...props } = {}) {
  const { user_id } = useParams() || {};
  const [user, setUser] = useState({});
  const [invokeUpdate, setInvokeUpdate] = useState(false);

  const fetchUser = async (userId = user_id) => {
    let response;
    try {
      response = await UserAPI.getUser({ user_id: userId, returnApiKey: true }); // Will return apikey if returnApiKey is true 
    } catch (error) {
      console.error(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    let { status, statusText, data = [] } = response || {};
    if (Array.isArray(data)) {
      data = data[0] || {};
    }
    if (status !== 200) {
      let { err, error, details } = data || {};
      console.error(`${status}: ${statusText}`, data);
      console.error(`${err}: ${error}`, details);
      return;
    }
    if (Object.keys(data || {}).length > 0) {
      setUser(data); // data = { apikey, user_id, role_id, account_id, email, name, phone_number, enabled, account_name, account_external_id, account_phone_number, account_description, role_name } 
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    const init = async (userId = user_id) => {
      if (userId) {
        await fetchUser(userId);
      }
    };
    if (mounted) init(user_id);
    return () => mounted = false;
  }, [invokeUpdate, user_id]);

  return (
    <div className="page-container my-4"  {...props}>
      <Row className="row border-bottom">
        <Col>
          <h2 className="mb-3">
            {user_id ? <>
              <Link to='/users'>
                <FontAwesomeIcon className="text-primary me-4 cursor-pointer" icon={faCaretLeft} />
              </Link>
              User ID: <span className="h4 fw-light ms-2">{user_id}</span>
            </> : "Create New User"}
          </h2>
        </Col>
      </Row>

      <Row>
        <Col className={`pe-3 ${user_id ? "border-end" : ""}`}>
          <UserDetailsForm theme={theme} user={user} isNewUser={!user_id} isModal={isModal} hideModal={hideModal} setInvokeParentUpdate={setInvokeUpdate} invokeParentUpdate={invokeUpdate} />
        </Col>
      </Row>
    </div>
  );
}