import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Button, Spinner, Modal, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { TEMPLATES, convertTemplateName } from "../utils/constants";
import { pdfAPI } from "../utils/pdf-api";
import TemplatesFilter from "../components/forms/TemplatesFilter";
import TemplateDetails from "./TemplateDetails";
import TagModal from "../components/Modals/TagModal";
import { AxiosError } from 'axios';
const defaultQueryParams = { page: 1, limit: 25, navSize: 5, showLog: false };

export default function TemplatesView({ theme, children, ...props } = {}) {
  const navigate = useNavigate();
  const [templateRows, setTemplateRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [templateModalState, setTemplateModalState] = useState(false);
  const [lastRequestPayload, setLastRequestPayload] = useState({});
  const openTemplateModal = () => (setTemplateModalState(true));
  const closeTemplateModal = () => {
    setTemplateModalState(false);
  };

  const fetchTemplates = async (params = {}) => {
    let queryParams = {
      ...defaultQueryParams,
      ...params,
    };
    setLastRequestPayload(queryParams);

    let resp;
    try {
      setLoadingTable(true);
      const { status = 500, statusText = 'Internal Server Error', data = {} } = await pdfAPI.getAllPDFTemplates(queryParams);
      resp = { status, statusText, data };
      let { rows = [], count = 0, limit = 500, page = 1, pageCount = 1, pages = [1] } = data || {};

      // Set pages so we always have the first and last page in the pagination 
      pages = pages.reduce((acc, pageNumber, index) => {
        if (index === 0 && pageNumber !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(pageNumber);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      if (Array.isArray(rows)) {
        setTemplateRows(rows);
        setRowCount(count);
        setPages(pages);
      }
    } catch (error) {
      setTemplateRows([]);
      setRowCount(0);
      setPages([1]);
      if (error instanceof AxiosError) {
        let { response = {} } = error || {};
        let { status, statusText, data = {} } = response || {};
        resp = { status, statusText, data };
      } else {
        resp = { status: 400, statusText: error?.message, data: error };
      }
    } finally {
      setLoadingTable(false);
    }

    if (resp?.status !== 200) {
      console.info(resp);
      console.error(`fetchTemplates Error: ${resp?.data?.message ? resp.data.message + ". " : ""}${resp?.statusText} (${resp?.status})`);
    }
    return resp;
  } // END fetchTemplates 

  const navigateTemplateDetails = (template) => {
    navigate(`/templates/${template.template_id}`, {
      state: { template }
    });
  };

  useEffect(() => {
    fetchTemplates(TEMPLATES.initialValues);
  }, []);

  const mapTemplatesToRows = (templates = templateRows) => {
    if (!Array.isArray(templates) || templates.length === 0) {
      return (<tr><td colSpan={TEMPLATES.visibleFields.length + 1}>No templates found</td></tr>);
    }

    return templates.map((template) => (<tr key={template.template_id}>
      <td className="text-center align-middle">
        <FontAwesomeIcon className="text-muted cursor-pointer" onClick={() => navigateTemplateDetails(template)} icon={faPenToSquare} />
      </td>

      {TEMPLATES.visibleFields.map((field) => {
        let fieldValue = template[field];

        if (field === "is_published") {
          fieldValue = fieldValue
            ? <FontAwesomeIcon className="text-success" size="lg" icon={faCheck} />
            : <FontAwesomeIcon className="text-danger" size="lg" icon={faXmark} />
        }
        else if (field === "template_id") {
          fieldValue = <Link target="_blank" to={`/preview-pdf/${template[field]}`} className="small">{template[field]}</Link>
        }
        else if (field === "template_name") {
          fieldValue = convertTemplateName(fieldValue);
        }

        return (
          <td key={field} className={classNames({ "text-center": field === "is_published", "align-middle": field === "is_published" })}>{fieldValue}</td>
        );
      })}
    </tr>)
    );
  }

  return (<div className="page-container"  {...props}>
    <Row className="justify-content-between border-bottom mb-3 pb-2">
      <Col>
        <h2>Document Templates</h2>
      </Col>
      <Col className="d-flex justify-content-end">
        <TagModal className="mx-2" tag_id={0} showLink={false} />
        <Button size="lg" onClick={openTemplateModal}>+ Add Template</Button>
        <Modal
          show={templateModalState}
          onHide={closeTemplateModal}
          animation={false}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TemplateDetails theme={theme} />
          </Modal.Body>
        </Modal>
      </Col>
    </Row>
    <TemplatesFilter handleFilter={fetchTemplates} handleClear={() => setActivePage(1)} initialValues={TEMPLATES.initialValues} />
    <Table hover className="templates-table" variant={theme} >
      <thead style={{ "borderBottom": "2px solid black" }}>
        <tr>
          <th>Count: {rowCount}</th>
          {TEMPLATES.visibleFields.map((field, i) => (<th key={`${i}_${field}`}>{TEMPLATES.labels[field]}</th>))}
        </tr>
      </thead>
      <tbody>{loadingTable ? <tr><td colSpan={TEMPLATES.visibleFields.length + 1}><Spinner as="span" animation="border" role="status" aria-hidden="true"><span className="visually-hidden">Loading...</span></Spinner></td></tr> : mapTemplatesToRows(templateRows)}</tbody>
      {Pages.length > 1 && (<tfoot>
        <tr>
          <td className="text-right" colSpan={TEMPLATES.visibleFields.length + 1} >
            <nav>
              <ul className="pagination justify-content-end" data-active-page={activePage} >
                {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                  <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                    <Link
                      to={`#page-${pageNumber}`}
                      className="page-link"
                      onClick={(e) => {
                        fetchTemplates({
                          ...lastRequestPayload,
                          page: pageNumber
                        });
                        e.preventDefault();
                        setActivePage(pageNumber);
                      }}
                      {...(pageNumber === activePage) && { tabIndex: -1 }}
                    >{pageNumber}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          </td>
        </tr>
      </tfoot>)}
    </Table>
  </div>);
}