import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import TagForm from '../forms/TagForm';
import { pdfAPI } from "../../utils/pdf-api";

const TagModal = ({ tag_id, setInvokeParentUpdate = () => { }, refreshParentFn, parentModalState, setParentModalState, showLink = true, parentCloseModal, ...props } = {}) => {
  const [tag, setTag] = useState({});
  const [invokeUpdate, setInvokeUpdate] = useState(false);
  const [modalState, setModalState] = useState(false);
  const { pathname } = useLocation() || {}; // Current url the user tried to access  

  const openModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (typeof setParentModalState === 'function') {
      setParentModalState(true);
    } else {
      setModalState(true);
    }
  };
  const closeModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Reset tag on closing the modal 
    setTag({});

    if (typeof parentCloseModal === 'function') { // NOTE: Will end up calling setParentModalState(false) through parentCloseModal function 
      // setParentModalState(false);
      parentCloseModal();
    } else {
      setModalState(false);
    }

    if (typeof setInvokeParentUpdate === 'function') {
      setInvokeParentUpdate((prevState) => !prevState);
    }
    setInvokeUpdate((prevState) => !prevState);
  };
  const fetchTag = async (tagId = tag_id) => {
    let response;
    try {
      response = await pdfAPI.getTag(tagId);
    } catch (error) {
      console.error(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    const { status, statusText, data = {} } = response || {};
    if (status !== 200) {
      let { err, error, details } = data || {};
      console.error(`${status}: ${statusText}`, data);
      console.error(`${err}: ${error}`, details);
      return;
    }
    if (Object.keys(data || {}).length > 0) {
      setTag(data); // data = { tag_id: number, title: string }
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    const init = async (tagId = tag_id) => {
      if (tagId) {
        await fetchTag(tagId);
      }
      if (typeof refreshParentFn === 'function') {
        refreshParentFn();
      }
    };
    if (mounted) init(tag_id);
    return () => mounted = false;
  }, [invokeUpdate, tag_id]);

  return (
    <div {...(showLink && { className: "d-inline mx-2" })} {...props} >
      {showLink ? <Link to="#" onClick={openModal}>{tag_id ? "Update Tag" : "Add Tag"}</Link>
        : <Button size="lg" onClick={openModal}>{tag_id ? "Update Tag" : "+ Add Tag"}</Button>}
      <Modal
        show={typeof setParentModalState === 'function' ? parentModalState : modalState}
        onHide={closeModal}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title {...tag_id && { title: `(tag_id: ${tag_id})` }}><span>{tag_id ? "Update Tag" : "Add Tag"}</span><span className="mx-3 small">{pathname !== "/tags" && <Link to="/tags">View All Tags</Link>}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TagForm tag={tag} isNewTag={!tag_id} closeModal={closeModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { TagModal };
export default TagModal;