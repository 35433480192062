import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ChangePasswordForm from "../forms/ChangePasswordForm";

const ResetPasswordModal = ({ user_id, ...props } = {}) => {
  const [modalState, setModalState] = useState(false);
  const openModal = () => (setModalState(true));
  const closeModal = () => (setModalState(false));

  return (
    <div>
      <Button onClick={() => openModal()} {...props}>Change Password</Button>
      <Modal show={modalState} onHide={closeModal} animation={false} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePasswordForm user_id={user_id} closeModal={closeModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { ResetPasswordModal };
export default ResetPasswordModal;