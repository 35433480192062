import React from "react";
import { Link } from "react-router-dom";
import { Card } from 'react-bootstrap';

const clientErrorResponses = {
  400: { title: "", message: "", error: "Bad Request" },
  401: {
    title: "Halt!",
    message: <span>
      You do not have permission to access this page. If you think this is a mistake,
      <Link to="/check-access" className="ms-1">
        log in
      </Link>
      .</span>,
    error: "Unauthorized"
  },
  402: { title: "", message: "", error: "Payment Required" },
  403: { title: "", message: "", error: "Forbidden" },
  404: {
    title: "The page you're looking for is not found.",
    message: <span>Make sure the address is correct and that the page hasn't moved. If
      you think this is a mistake,
      <a href="mailto:noreply@esignapp.com" className="ms-1">
        contact us
      </a>
      .</span>,
    error: "Not Found"
  },
  405: { title: "", message: "", error: "Method Not Allowed" },
  406: { title: "", message: "", error: "Not Acceptable" },
  407: { title: "", message: "", error: "Proxy Authentication Required" },
  408: { title: "", message: "", error: "Request Timeout" },
  409: { title: "", message: "", error: "Conflict" },
  410: { title: "", message: "", error: "Gone" },
  411: { title: "", message: "", error: "Length Required" },
  412: { title: "", message: "", error: "Precondition Failed" },
  413: { title: "", message: "", error: "Payload Too Large" },
  414: { title: "", message: "", error: "URI Too Long" },
  415: { title: "", message: "", error: "Unsupported Media Type" },
  416: { title: "", message: "", error: "Range Not Satisfiable" },
  417: { title: "", message: "", error: "Expectation Failed" },
  418: { title: "", message: "", error: "I'm a teapot" },
  421: { title: "", message: "", error: "Misdirected Request" },
  422: { title: "", message: "", error: "Unprocessable Entity" },
  423: { title: "", message: "", error: "Locked" },
  424: { title: "", message: "", error: "Failed Dependency" },
  425: { title: "", message: "", error: "Too Early" },
  426: { title: "", message: "", error: "Upgrade Required" },
  428: { title: "", message: "", error: "Precondition Required" },
  429: {
    title: "Whoa, slow down there!",
    message: <span>Too many requests have been made from your IP address. If you think this is a mistake,
      <a href="mailto:noreply@esignap.com" className="ms-1">
        contact us
      </a>
      .</span>,
    error: "Too Many Requests"
  },
  431: { title: "", message: "", error: "Request Header Fields Too Large" },
  451: { title: "", message: "", error: "Unavailable For Legal Reasons" }
};
const serverErrorResponses = {
  500: {
    title: "Whoops, something went wrong!",
    message: <span>Try refreshing the page, or going back and attempting the action again.
      If this problem persists,
      <a href="mailto:noreply@esignapp.com" className="ms-1">
        contact us
      </a>
      .</span>,
    error: "Internal Server Error"
  },
  501: { title: "", message: "", error: "Not Implemented" },
  502: { title: "", message: "", error: "Bad Gateway" },
  503: { title: "", message: "", error: "Service Unavailable" },
  504: { title: "", message: "", error: "Gateway Timeout" },
  505: { title: "", message: "", error: "HTTP Version Not Supported" },
  506: { title: "", message: "", error: "Variant Also Negotiates" },
  510: { title: "", message: "", error: "Not Extended" },
  511: { title: "", message: "", error: "Network Authentication Required" }
};
const ErrorResponses = { ...clientErrorResponses, ...serverErrorResponses };

const Unauthorized = ({ status = 500, theme, ...props } = {}) => {
  const title = ErrorResponses[status]?.title || status;
  const message = ErrorResponses[status]?.message || ErrorResponses[status]?.error || "You do not have permission to access this page.";

  return (<div className="container" {...props}>
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">{status || "Halt!"}</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">{title}</p>
        <hr />
        <p>{message}</p>
      </Card.Body>
    </Card>
  </div>);
};

export default Unauthorized;
