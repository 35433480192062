import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form as BootstrapForm, Button, Spinner } from "react-bootstrap";
import { Formik, Field, Form, FieldArray, useFormik } from "formik";
import MultiSelect from "../common/MultiSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faFloppyDisk, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { StyledGreenCheckbox } from "../../styled/Forms";
import TagModal from "../Modals/TagModal";
import { pdfAPI } from "../../utils/pdf-api";
import { convertTagsApiFormat, convertTemplateName, formatTagArray } from "../../utils/constants";

export default function TemplateDetailsForm({ template = null, isNewTemplate = false, setInvokeParentUpdate = () => { }, invokeParentUpdate, children, ...props } = {}) {
  const navigate = useNavigate();
  const { template_id, template_name: initialTemplateName, tag: initialTag = '', is_published: initialIsPublished = false, fieldDataArray = [] } = template || {};
  const initialFormValues = {
    template_id, // Pass this so formik can update whenever this values changes. NOTE: enableReinitialize={true} is required for this to work  
    template_name: convertTemplateName(initialTemplateName),
    tag: initialTag, // will be a string separated by commas initially 
    is_published: typeof initialIsPublished === 'string' ? `${initialIsPublished}`.toLowerCase() === 'true' : initialIsPublished || false,
  };

  const formRef = useRef(null);
  const [editable, setEditable] = useState(isNewTemplate);
  const [availableTags, setAvailableTags] = useState([]);

  // Helper functions 
  const mapSelectedTags = (tags = []) => {
    if (!Array.isArray(tags)) {
      tags = formatTagArray(tags);
    }
    if (tags[0]?.value) {
      return tags;
    }
    return tags.map(tag => ({ value: tag, label: tag }));
  };

  const mapTagOptions = (tags) => {
    if (!Array.isArray(tags)) {
      return [];
    }
    return tags.map(tag => ({ value: tag.title, label: tag.title }));
  };

  // API call functions 
  const fetchAvailableTags = async () => {
    let response; // { status, statusText, data } 
    try {
      response = await pdfAPI.getAllTags();
    }
    catch (error) {
      console.error(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    const { status, statusText, data = {} } = response || {};
    let { rows = [], count = 0, limit = 500, page = 1, pageCount = 1, pages = [1] } = data || {};
    if (status > 399) {
      let { err, error, details } = data || {};
      console.error(`${status}: ${statusText}`, data);
      console.error(`${err}: ${error}`, details);
      return;
    }
    if (Array.isArray(rows)) {
      setAvailableTags(rows);
    }
    return;
  };

  const updateTemplate = async (values = {}) => {
    let { is_published = false, template_name = '', tag, files, ...otherValues } = values || {};
    tag = convertTagsApiFormat(tag, availableTags);
    const name = convertTemplateName(template_name, true);
    const formValues = { is_published, name, tag, ...otherValues };

    let headers = {};
    let formData = null;
    if (files) {
      formValues.tag = formValues.tag.map((t) => t.title || t.tag_id || t);
      formValues.files = files;
      formData = new FormData(formRef.current);
      for (let v in formValues) {
        if (Array.isArray(formValues[v])) {
          formValues[v].forEach((value, index) => {
            formData.append(`${v}[${index}]`, value);
          });
        } else {
          formData.append(v, formValues[v]);
        }
      }
      headers['content-type'] = "multipart/form-data";
    }

    let resp;
    try {
      setEditable(false);
      const payload = formData ? formData : formValues;
      resp = await pdfAPI.updateTemplate(template_id, payload, headers);
    }
    catch (error) {
      console.error(error)
    } finally {
      setInvokeParentUpdate((prevState) => !prevState);
    }

    let { status, statusText, data = {} } = resp || {};
    if (status !== 200) {
      console.log(data);
      console.error(`updateTemplate Error: ${statusText} (${status})`);
    }
    return data;
  };

  const deleteTemplate = async (values = {}, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let templateID = values?.template_id || template_id;
    if (!templateID) {
      console.error("deleteTemplate Error: No template ID provided", values);
    }

    let resp;
    try {
      resp = await pdfAPI.deleteTemplate(templateID);
    }
    catch (error) {
      console.error(error)
    } finally {
      setInvokeParentUpdate((prevState) => !prevState);
    }

    let { status, statusText, data = {} } = resp || {};
    if (status !== 200) {
      console.log(data);
      console.error(`deleteTemplate Error: ${statusText} (${status})`);
      return data;
    }

    // Navigate back to the templates page 
    navigate("/templates");
    return;
  };

  useEffect(() => {
    let mounted = true;
    const init = async () => editable && await fetchAvailableTags();
    if (mounted) init();
    return () => mounted = false;
  }, [editable]);

  return (<Formik
    enableReinitialize
    initialValues={initialFormValues}
    onSubmit={async (values, { setSubmitting }) => {
      await updateTemplate(values);
      setSubmitting(false);
    }}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm, setFieldValue }) => (<Form ref={formRef} className="mt-4 position-relative" encType="multipart/form-data">
      <BootstrapForm.Group className="my-2">
        <BootstrapForm.Label className="h6">Template Name</BootstrapForm.Label>
        <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="template_name"></Field>
      </BootstrapForm.Group>

      <BootstrapForm.Group className="my-2">
        <BootstrapForm.Label className="h6">Tags <TagModal /></BootstrapForm.Label>
        {editable ? <MultiSelect
          name="tag"
          isDisabled={!editable || isSubmitting}
          defaultValue={mapSelectedTags(values?.tag)}
          options={mapTagOptions((availableTags || []).length > 0 ? availableTags : (Array.isArray(values?.tag) ? values?.tag : `${values?.tag}`.split(",")))}
        /> : (<Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="tag"></Field>)}
      </BootstrapForm.Group>

      <BootstrapForm.Group className="my-2">
        <BootstrapForm.Label className="h6">PDF Doc</BootstrapForm.Label>
        <StyledGreenCheckbox className="d-block">
          <Field as={BootstrapForm.Control} type="file" name="pdf_doc" disabled={!editable || isSubmitting} className="form-control" onChange={(event) => {
            if (event?.currentTarget?.files[0]?.name) {
              const fileName = convertTemplateName(`${event.currentTarget.files[0].name}`.replace(".pdf", ""), true);
              setFieldValue("template_name", fileName);
            }
            setFieldValue("files", event.currentTarget.files[0]);
          }}></Field>
        </StyledGreenCheckbox>
      </BootstrapForm.Group>

      <BootstrapForm.Group className="my-2">
        <BootstrapForm.Label className="h6">Published</BootstrapForm.Label>
        <StyledGreenCheckbox className="d-block">
          <Field as={BootstrapForm.Control} type="checkbox" name="is_published" disabled={!editable || isSubmitting} className="form-control"></Field>
        </StyledGreenCheckbox>
      </BootstrapForm.Group>

      <div className="d-flex justify-content-end">
        {editable ? <Button type="submit" className="my-3 me-2" variant="success">
          <FontAwesomeIcon icon={faFloppyDisk} size="1x" /> {template_id ? "Save" : "Create"}
        </Button> : <Button className="my-3 me-2" variant="danger" onClick={(e) => deleteTemplate(values, e)}>
          <FontAwesomeIcon icon={faTrashCan} size="1x" /> Delete
        </Button>}
        <Button className="my-3" variant="primary" onClick={() => {
          editable && resetForm();
          setEditable((prevState) => !prevState);
        }}><FontAwesomeIcon icon={faPenToSquare} size="1x" /> {editable ? "Cancel Edit" : "Edit"}
        </Button>
      </div>

      {Object.keys(template || {}).length === 0 && !isNewTemplate &&
        <Spinner animation="border" role="status" aria-hidden="true" className="spinner-lg position-absolute bottom-50 start-50">
          <span className="visually-hidden">Loading...</span>
        </Spinner>}
    </Form>)}
  </Formik>);
}