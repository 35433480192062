import axios from "axios";

function get(url) {
  return axios.get(url).then(res => res.data).catch(err => { console.log(err); return null; });
}

const ipify = {
  ipv4() {
    return get('https://api.ipify.org');
  },
  ipv64() {
    return get('https://api64.ipify.org');
  },
  geolocation() {
    return get('https://geolocation-db.com/json/');
  },
};

/**
 * Uses ipify functions to get external ip 
 * @returns string IP 
 */
const getExternalIP = async (checkLocalStorage = true, expireAfterHours = 12) => {
  let external_ip = null;

  // Get ip from localStorage if available 
  let foundInLocalStorage = false;
  if (checkLocalStorage) {
    let response = null;
    try {
      let esign_client_info = (typeof localStorage !== 'undefined') ? localStorage.getItem("esign_client_info")
        : (typeof window?.localStorage !== 'undefined' ? window.localStorage.getItem("esign_client_info") : null);
      if (esign_client_info) {
        response = JSON.parse(esign_client_info);
      }

      // Expire after 12 hours 
      if (response && response?.timestamp) {
        let { timestamp } = response;
        const now = new Date();
        const diffMilliSeconds = now - new Date(timestamp);
        const hoursInMilliseconds = 60 * 60 * 1000;
        if (diffMilliSeconds > hoursInMilliseconds * expireAfterHours) {
          response = null;
          if (typeof localStorage !== 'undefined') {
            localStorage.removeItem("esign_client_info");
          } else if (typeof window?.localStorage !== 'undefined') {
            window.localStorage.removeItem("esign_client_info");
          }
        }
      } else if (response) {
        response = null;
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem("esign_client_info");
        } else if (typeof window?.localStorage !== 'undefined') {
          window.localStorage.removeItem("esign_client_info");
        }
      }
    } catch (ex) {  // Ignore
      response = null;
    }
    if (response?.ip) {
      external_ip = response.ip;
      foundInLocalStorage = true;
    }
  } // END checkLocalStorage

  if (!external_ip) {
    external_ip = await ipify.ipv4();
  }
  if (!external_ip) {
    external_ip = await ipify.ipv64();
  }
  if (!external_ip) {
    const { IPv4 } = await ipify.geolocation(); // returns { country_code, country_name, city, postal, latitude, longitude, IPv4, state }
    external_ip = IPv4;
  }

  // Save to localStorage 
  if (!foundInLocalStorage) {
    let { userAgent: user_agent } = navigator || window?.navigator || {};
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem("esign_client_info", JSON.stringify({ timestamp: new Date(), ip: external_ip, user_agent }));
    } else if (typeof window?.localStorage !== 'undefined') {
      window.localStorage.setItem("esign_client_info", JSON.stringify({ timestamp: new Date(), ip: external_ip, user_agent }));
    }
  }

  return external_ip;
};

const getClientDigitalInformation = async () => {
  let { userAgent: user_agent } = navigator || window?.navigator || {};
  let { location } = window || {};
  let { hostname } = location || {};

  let response = {
    timestamp: new Date(),
    ip: await getExternalIP(),
    user_agent,
  };

  // Detect if running from localhost 
  if (["localhost", "127.0.0.1"].includes(hostname)) {
    console.log(`Running from ${hostname} at ip ${response.ip}`);
  }
  return response;
};

export {
  ipify,
  getExternalIP,
  getClientDigitalInformation,
};