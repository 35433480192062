import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Row, Col, Table, Spinner, Form as BootstrapForm } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { StyledGreenCheckbox } from "../styled/Forms";
import classNames from "classnames";
import moment from "moment-timezone";
import AccountDetailsForm from "../components/forms/AccountDetailsForm";
import { USERS } from "../utils/constants";
import { UserAPI, AccountAPI } from "../utils/API";
import { humanize, handleTableRowClick } from "../utils/";
import Debug from "../components/Debug";

export default function AccountDetails({ theme, children, isModal = false, hideModal, ...props } = {}) {
  const navigate = useNavigate();
  const { account_id } = useParams() || {};
  const [account, setAccount] = useState({});
  const [allAccountUsers, setAccountUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [invokeUpdate, setInvokeUpdate] = useState(false);

  const fetchAccount = async (accountID = account_id) => {
    let response;
    try {
      response = await AccountAPI.getAccount(accountID);
    } catch (error) {
      console.error(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    const { status, statusText, data = {} } = response || {};
    if (status !== 200) {
      let { err, error, details } = data || {};
      console.error(`${status}: ${statusText}`, data);
      console.error(`${err}: ${error}`, details);
      return;
    }
    if (Object.keys(data || {}).length > 0) {
      setAccount(data); // data = { account_id, external_id, name, description, phone_number, enabled } 
    }
    return;
  };

  const fetchAccountUsers = async (accountID = account_id, loadPage = 1) => {
    let response; // { status, statusText, data } 
    try {
      setFetchingUsers(true);
      if (!accountID) {
        throw new Error("No account ID provided");
      }
      response = await UserAPI.getAllUsers({ account_id: accountID, page: loadPage });
    }
    catch (error) {
      console.error(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    } finally {
      setFetchingUsers(false);
    }

    const { status, statusText, data = {} } = response || {};
    let { rows = [], count = 0, limit = 500, page = 1, pageCount = 1, pages = [1] } = data || {};
    if (status > 399) {
      let { err, error, details } = data || {};
      console.error(`${status}: ${statusText}`, data);
      console.error(`${err}: ${error}`, details);
      setAccountUsers([]);
      setUserCount(0);
      setPages([1]);
      return;
    }
    if (rows && !Array.isArray(rows)) {
      rows = [rows];
    }
    if (Array.isArray(rows)) {
      setAccountUsers(rows);
    }
    setUserCount(count);
    setPages(pages);
    return;
  };

  const mapUserRows = (tableRows = allAccountUsers) => {
    if (!Array.isArray(tableRows) || tableRows.length === 0) {
      return (<tr><td colSpan={USERS.visibleFields.filter((f) => f !== 'user_id').length + 1}>No users found</td></tr>);
    }

    return tableRows.map((r, tr_index) => {
      const visibleFields = USERS.visibleFields.filter((field) => field !== "user_id");
      const className = "valid_until" in r && r.valid_until && moment().isAfter(new Date(r.valid_until)) ? "table-secondary" : "";
      return (<tr key={r.user_id} {...(className) && { className }} onClick={(e) => (handleTableRowClick.call(null, e))}>
        <td className="text-center align-middle" width={45} data-col={0}>
          <StyledGreenCheckbox w={20}>
            <BootstrapForm.Control
              className="me-2"
              type="checkbox"
              name={`row_${tr_index}`}
              title={`Select ${tr_index}`}
              defaultChecked={false}
              onClick={(e) => {
                if (e) {
                  e.stopPropagation();
                }
                const checked = e.target.checked;
                setSelectedUsers((prevState) => (checked ? [...prevState, r] : prevState.filter((row) => row.request_id !== r.request_id)));
              }}
            />
          </StyledGreenCheckbox>
        </td>

        {visibleFields.map((field) => {
          let fieldValue = r[field];

          if (field === "enabled") {
            fieldValue = fieldValue
              ? <FontAwesomeIcon className="text-success" size="lg" icon={faCheck} />
              : <FontAwesomeIcon className="text-danger" size="lg" icon={faXmark} />
          }
          else if (field === "name") {
            fieldValue = r?.user_id ? <Link target="_blank" to={`/users/${r.user_id}`} className="small" onClick={(e) => { navigate(`/users/${r.user_id}`) }}>{r[field]}</Link> : r[field];
          } else if (field === "role_name") {
            fieldValue = humanize(fieldValue);
          }

          return (
            <td key={field} className={classNames({ "text-center": field === "completed", "align-middle": field === "enabled" })}>{fieldValue}</td>
          );
        })}
      </tr>)
    });
  };

  useEffect(() => {
    let mounted = true;
    const init = async (accountID = account_id) => {
      if (accountID) {
        await fetchAccount(accountID);
        await fetchAccountUsers(accountID);
      }
    };
    if (mounted) init(account_id);
    return () => mounted = false;
  }, [invokeUpdate, account_id]);

  return (
    <div className="page-container my-4"  {...props}>
      <Row className="row border-bottom">
        <Col>
          <h2 className="mb-3">
            {account_id ? <>
              <Link to='/accounts'>
                <FontAwesomeIcon className="text-primary me-4 cursor-pointer" icon={faCaretLeft} />
              </Link>
              Account ID: <span className="h4 fw-light ms-2">{account_id}</span>
            </> : "Create New Account"}
          </h2>
        </Col>
      </Row>

      <Row>
        <Col className={`pe-3 ${account_id ? "border-end" : ""}`}>
          <AccountDetailsForm theme={theme} account={account} isNewAccount={!account_id} isModal={isModal} hideModal={hideModal} setInvokeParentUpdate={setInvokeUpdate} invokeParentUpdate={invokeUpdate} />
        </Col>
        {account_id && <div className="col">
          <Table hover className="templates-table" variant={theme}>
            <thead style={{ "borderBottom": "2px solid black" }}>
              <tr>
                <th>Count: {userCount}</th>
                {USERS.visibleFields.filter((f) => f !== 'user_id').map((field, i, a) => (<th key={`${i + 1}_${field}`}>{USERS.labels[field] || humanize(field)}</th>))}
              </tr>
            </thead>
            <tbody>{fetchingUsers
              ? <tr><td colSpan={USERS.visibleFields.filter((f) => f !== 'user_id').length + 1}><Spinner as="span" animation="border" role="status" aria-hidden="true"><span className="visually-hidden">Loading...</span></Spinner></td></tr>
              : mapUserRows(allAccountUsers)}
            </tbody>
            {Pages.length > 1 && (<tfoot>
              <tr>
                <td className="text-right" colSpan={USERS.visibleFields.filter((f) => f !== 'user_id').length + 1} >
                  <nav>
                    <ul className="pagination justify-content-end" data-active-page={activePage} >
                      {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                        <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                          <Link
                            to={`#page-${pageNumber}`}
                            className="page-link"
                            onClick={(e) => {
                              fetchAccountUsers(account_id, pageNumber);
                              e.preventDefault();
                              setActivePage(pageNumber);
                            }}
                            {...(pageNumber === activePage) && { tabIndex: -1 }}
                          >{pageNumber}</Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </td>
              </tr>
            </tfoot>)}
          </Table>
          <Debug debugData={selectedUsers} debugLabel="Selected Users" />
        </div>}
      </Row>
    </div>
  );
}