/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from "react";
export default () => {
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    const localStorageTheme = (typeof localStorage !== 'undefined') ? localStorage.getItem("theme")
      : (typeof window?.localStorage !== 'undefined' ? window.localStorage.getItem("theme") : null);
    setTheme(localStorageTheme || "light");
  }, []);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem("theme", "dark");
      } else if (typeof window?.localStorage !== 'undefined') {
        window.localStorage.setItem("theme", "dark");
      }

    } else {
      setTheme("light");
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem("theme", "light");
      } else if (typeof window?.localStorage !== 'undefined') {
        window.localStorage.setItem("theme", "light");
      }
    }
  };

  return [theme, toggleTheme];
};
