import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { useUserData } from "../contexts/AuthContext";
import IconButton from './common/IconButton';
import { humanize } from "../utils/";

const APP_NAME = process.env.REACT_APP_NAME || "assure-esign";
const HeaderMenu = [
  { page_title: "Requests", page_link: "/requests", disabled: false, superAdminOnly: false },
  { page_title: "Templates", page_link: "/templates", disabled: false, superAdminOnly: false },
  { page_title: "Tags", page_link: "/tags", disabled: false, superAdminOnly: false },
  { page_title: "Users", page_link: "/users", disabled: false, superAdminOnly: false, adminOnly: true },
  { page_title: "Accounts", page_link: "/accounts", disabled: false, superAdminOnly: true },
];

const MainHeader = ({ toggleTheme, theme, showBrand = true, showMenu = false, ...props } = {}) => {
  const navigate = useNavigate();
  const { pathname = "/login" } = useLocation();
  const { userData, handleLogout } = useUserData();

  let { email, profile_picture = '', role_name, isAuthenticated = false, user_id } = userData || {};
  const isSuperAdmin = role_name === 'super-admin';
  const isAdmin = role_name === 'admin';

  const page = HeaderMenu.find(({ page_link = "#", page_title = '' } = {}) => {
    return pathname === page_link || pathname.includes(page_link) || pathname.includes(page_title);
  })?.page_title;

  return (<header {...props}>
    <Navbar collapseOnSelect expand="lg" variant={theme} className="header-main-nav">
      {showBrand && <Navbar.Brand onClick={() => navigate("/")}>{humanize(APP_NAME)}</Navbar.Brand>}
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          {showMenu && HeaderMenu.filter(({ disabled = false, superAdminOnly = false, adminOnly = false } = {}) => {
            return !disabled && (isSuperAdmin || (!superAdminOnly && !adminOnly) || (!superAdminOnly && adminOnly && isAdmin));
          }).map(({ page_title, page_link }, index) => {
            return (<span key={`header-${page_title}-${index}`} className="header-nav-link">
              <Link to={page_link} className={`nav-link ${(page === page_title) ? "active" : ""}`}>{page_title}</Link>
            </span>)
          })}
        </Nav>
        <Nav>
          {showBrand && <Nav.Item as="li">
            <IconButton variant={theme} size="lg" icon={theme} className="ms-2" iconAlign="left" onClick={toggleTheme} />
          </Nav.Item>}
          {isAuthenticated && <Dropdown navbar={true} as="li">
            <Dropdown.Toggle as={Link} to="#!" bsPrefix="toggle" className="nav-link pe-0 ps-2" >
              <div>
                <img style={{ width: "40px", borderRadius: "50%" }} src={profile_picture} alt={email || "User Profile"} />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
              <Dropdown.Item  {...user_id && { as: Link, to: `/users/${user_id}` }}>{email}</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>)
};

export default MainHeader;