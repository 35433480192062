import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form as BootstrapForm, Table, Spinner, Row, Col, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { StyledGreenCheckbox } from "../styled/Forms";
import classNames from "classnames";
import moment from "moment-timezone";
import ExportButton from "../components/ExportButton";
import RequestsFilter from "../components/forms/RequestsFilter";
import { REQUESTS, convertTemplateName } from "../utils/constants";
import { pdfAPI } from "../utils/pdf-api";
import { humanize, dehumanize, formatDate, handleTableRowClick } from "../utils/";
import { AxiosError } from 'axios';
const defaultQueryParams = { page: 1, limit: 25, navSize: 5, showLog: false, orderASC: false, orderby: "valid_until", groupBy: "requestor_external_id" };
const excludedGroupByFields = ['request_id', 'valid_until', 'template_name']; // Fields to exclude from the group by dropdown 

export default function Requests({ theme, children, ...props } = {}) {
  const [requestRows, setRequestRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [lastRequestPayload, setLastRequestPayload] = useState({});

  const setGroupBy = (event, field = "") => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setSelectedRows([]);
    const queryParams = {
      ...lastRequestPayload,
      ...excludedGroupByFields.reduce((acc, f) => ({ ...acc, [f]: "" }), {}),
      [field]: "",
      groupBy: field
    };
    fetchRequests(queryParams);
  };

  const fetchRequests = async (params = {}) => {
    const selectedRowIds = (selectedRows || []).map(({ request_id } = {}) => request_id).filter(Boolean);
    const queryParams = {
      ...defaultQueryParams,
      request_id: selectedRowIds.length > 0 ? selectedRowIds : "",
      ...params,
    };
    setLastRequestPayload(queryParams);

    let resp;
    try {
      setLoadingTable(true);
      const { status = 500, statusText = 'Internal Server Error', data = {} } = await pdfAPI.getAllEsignRequests(queryParams);
      resp = { status, statusText, data };
      let { rows = [], count = 0, limit = 500, page = 1, pageCount = 1, pages = [1] } = data || {};

      // Set pages so we always have the first and last page in the pagination 
      pages = pages.reduce((acc, pageNumber, index) => {
        if (index === 0 && pageNumber !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(pageNumber);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      if (Array.isArray(rows)) {
        setRequestRows(rows);
        setRowCount(count);
        setPages(pages);
      }
    } catch (error) {
      setRequestRows([]);
      setRowCount(0);
      setPages([1]);
      if (error instanceof AxiosError) {
        let { response = {} } = error || {};
        let { status, statusText, data = {} } = response || {};
        resp = { status, statusText, data };
      } else {
        resp = { status: 400, statusText: error?.message, data: error };
      }
    } finally {
      setLoadingTable(false);
    }

    if (resp?.status !== 200) {
      console.info(resp);
      console.error(`fetchRequests Error: ${resp?.data?.message ? resp.data.message + ". " : ""}${resp?.statusText} (${resp?.status})`);
    }
    return resp;
  };

  useEffect(() => {
    fetchRequests(REQUESTS.initialValues);
  }, []);

  const mapRequestsToRows = (requests = requestRows) => {
    if (!Array.isArray(requests) || requests.length === 0) {
      return (<tr><td colSpan={REQUESTS.visibleFields.length + 1}>No requests found</td></tr>);
    }

    return requests.map((r, tr_index) => {
      const visibleFields = r.total ? REQUESTS.visibleTotalFields.filter((f) => Object.keys(r).includes(f)) : REQUESTS.visibleFields;
      const className = "valid_until" in r && r.valid_until && moment().isAfter(new Date(r.valid_until)) ? "table-secondary" : "";
      return (<tr key={r.request_id} {...r?.message && { title: `subject: ${r?.subject}\nmessage: ${r.message}` }} {...(className) && { className }} onClick={(e) => (handleTableRowClick.call(null, e))}>
        <td className="text-center align-middle" width={45} data-col={0}>
          <StyledGreenCheckbox w={20}>
            <BootstrapForm.Control
              className="me-2"
              type="checkbox"
              name={`row_${tr_index}`}
              title={`Select ${tr_index}`}
              defaultChecked={false}
              onClick={(e) => {
                if (e) {
                  e.stopPropagation();
                }
                const checked = e.target.checked;
                setSelectedRows((prevState) => (checked ? [...prevState, r] : prevState.filter((row) => row.request_id !== r.request_id)));
              }}
            />
          </StyledGreenCheckbox>
        </td>

        {visibleFields.map((field) => {
          let fieldValue = r[field];

          if (field === "completed") {
            fieldValue = fieldValue
              ? <FontAwesomeIcon className="text-success" size="lg" icon={faCheck} />
              : <FontAwesomeIcon className="text-danger" size="lg" icon={faXmark} />
          }
          else if (field === "valid_until") {
            fieldValue = formatDate(fieldValue, "MM/DD/YY hh:mm:ss A z");
          }
          else if (field === "template_id") {
            fieldValue = <Link target="_blank" to={`/preview-pdf/${r[field]}`} className="small">{r[field]}</Link>
          }
          else if (field === "template_name") {
            fieldValue = convertTemplateName(fieldValue);
          }

          return (
            <td key={field} className={classNames({ "text-center": field === "completed", "align-middle": field === "completed" })}>{fieldValue}</td>
          );
        })}
      </tr>)
    });
  };

  return (<div className="page-container"  {...props}>
    <Row className="justify-content-between border-bottom mb-3 pb-2">
      <Col>
        <h2>Requests</h2>
      </Col>
      <Col className="d-flex justify-content-end">
        <Dropdown className="me-2">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="lg">
            Group By{lastRequestPayload.groupBy ? `: ${REQUESTS.labels[lastRequestPayload.groupBy] || humanize(lastRequestPayload.groupBy)}` : ""}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={(e) => setGroupBy.call(null, e, "")}>None</Dropdown.Item>
            {REQUESTS.visibleFields.filter((f) => excludedGroupByFields.includes(f) === false).map((field, i) => (
              <Dropdown.Item key={i} onClick={(e) => setGroupBy.call(null, e, field)}>{REQUESTS.labels[field] || humanize(field)}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <ExportButton
          controllerName="esign"
          fnName="getAllEsignRequests"
          saveAs={`Requests_${lastRequestPayload.groupBy ? dehumanize(REQUESTS.labels[lastRequestPayload.groupBy]) + "_" || lastRequestPayload.groupBy + "_" : ""}${formatDate(new Date(), "YYYY-MM-DD")}.xlsx`}
          size="lg"
          params={{
            ...lastRequestPayload,
            offset: 0,
            limit: null,
            selectedRowIds: selectedRows.map(({ request_id } = {}) => request_id).filter(Boolean)
          }}
        />
      </Col>
    </Row>
    <RequestsFilter handleFilter={fetchRequests} handleClear={() => { setLastRequestPayload({}); setActivePage(1); }} initialValues={{ ...REQUESTS.initialValues, ...lastRequestPayload }} />
    <Table hover className="templates-table" variant={theme}>
      <thead style={{ "borderBottom": "2px solid black" }}>
        <tr>
          <th>Count: {rowCount}</th>
          {(requestRows[0]?.total
            ? REQUESTS.visibleTotalFields.filter((f) => Object.keys(requestRows[0]).includes(f))
            : REQUESTS.visibleFields).map((field, i, a) => (<th key={`${i + 1}_${field}`}>{REQUESTS.labels[field] || humanize(field)}</th>))}
        </tr>
      </thead>
      <tbody>{loadingTable
        ? <tr><td colSpan={requestRows[0]?.total ? REQUESTS.visibleTotalFields.filter((f) => Object.keys(requestRows[0]).includes(f)).length : REQUESTS.visibleFields.length + 1}><Spinner as="span" animation="border" role="status" aria-hidden="true"><span className="visually-hidden">Loading...</span></Spinner></td></tr>
        : mapRequestsToRows(requestRows)}
      </tbody>
      {Pages.length > 1 && (<tfoot>
        <tr>
          <td className="text-right" colSpan={requestRows[0]?.total ? REQUESTS.visibleTotalFields.filter((f) => Object.keys(requestRows[0]).includes(f)).length : REQUESTS.visibleFields.length + 1} >
            <nav>
              <ul className="pagination justify-content-end" data-active-page={activePage} >
                {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                  <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                    <Link
                      to={`#page-${pageNumber}`}
                      className="page-link"
                      onClick={(e) => {
                        fetchRequests({
                          ...lastRequestPayload,
                          page: pageNumber
                        });
                        e.preventDefault();
                        setActivePage(pageNumber);
                      }}
                      {...(pageNumber === activePage) && { tabIndex: -1 }}
                    >{pageNumber}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          </td>
        </tr>
      </tfoot>)}
    </Table>
  </div>);
}