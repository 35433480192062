import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Button, Modal, Spinner, ListGroup } from 'react-bootstrap';
import IconButton from "../components/common/IconButton";
import PDFViewerWrapper from '../components/pdfjs-viewer/PDFViewerWrapper';
import { pdfAPI } from "../utils/pdf-api";
const defaultHeaderButtons = []; // NOTE: If we remove all buttons than all of them will show up. Removed 'sendReminderEmailButton', 'moreButton', 'menuButton'

const EsignDocument = ({ toggleTheme, theme, headerButtons = defaultHeaderButtons, request_id, template_id, pdf_template, children, ...props } = {}) => {
  let params = useParams();
  const queryURL = new URLSearchParams(window.location.search);
  const queryParams = {};
  for (let [key, value] of queryURL.entries()) {
    queryParams[key] = value;
  }

  if (!request_id && (params.request_id || queryParams?.request_id)) {
    request_id = params.request_id || queryParams?.request_id || 0;
  }
  if (!template_id && (params.template_id || queryParams?.template_id)) {
    template_id = params.template_id || queryParams?.template_id;
  }
  if (!pdf_template && (params.pdf_template || queryParams?.pdf_template)) {
    pdf_template = params.pdf_template || queryParams?.pdf_template;
  }
  if (!Array.isArray(headerButtons)) {
    headerButtons = defaultHeaderButtons;
  }
  if (Array.isArray(headerButtons) && headerButtons.length > 0 && headerButtons.some(btn => !`${btn}`.toLowerCase().includes("button"))) {
    headerButtons = headerButtons.map((btn) => !`${btn}`.toLowerCase().includes("button") ? `${btn}Button` : btn); // add 'Button' to the end of each button name if it doesn't already have it
  }

  const headerTextRef = useRef(null);
  const submitButtonRef = useRef(null);
  const [documentIsSigned, setDocumentIsSigned] = useState(false);
  const [isValidDocument, setIsValidDocument] = useState(false);
  const [esignCaptureTrigger, setEsignCaptureTrigger] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [fileDownloaded, setFileDownloaded] = useState(false); // Only allow to download file once 
  const [infoMessageType, setInfoMessageType] = useState('info'); // 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
  const [infoMessages, setInfoMessages] = useState([]);

  const esignCaptureFn = async (params = {}) => {
    setIsSaving(true);
    let error = null, response = {}, message = '';
    try { // Submit -> I Agree
      let { error: saveError, response: saveResponse, message: saveMessage = '' } = await pdfAPI.saveFilledPdf(params);
      error = saveError;
      response = saveResponse || {};
      message = saveMessage;
    } catch (err) {
      console.error("esignCaptureFn Error:", err, err?.message);
      error = err;
      response = err?.response || {};
      message = err?.message || '';
    } finally {
      setIsSaving(false);
    }
    return { error, response, message }; // Return is handled in handleParentCapture where this function is called from 
  };

  const handleIAgree = async (event) => {
    if (!event) {
      console.log("handleIAgree: No event");
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    // Change button text to ✔ I Agree and ✘ disable it
    const { innerText } = event?.target || {};
    if (isValidDocument) { // Submit -> I Agree
      if (!innerText?.toLowerCase().includes("i agree")) {
        event.target.innerText = "I Agree";
        headerTextRef.current.textContent = "✔ Almost Done. All fields have been completed. Click on 'I Agree' to sign this document.";
        return; // Force second click of [I Agree]
      } else {
        event.target.disabled = true;
      }
    }

    // Triggers calling esignCaptureFn through series of functions: 
    // captureFields -> captureCallback -> handleCapture -> handleParentCapture -> esignCaptureFn
    setEsignCaptureTrigger((prev) => !prev);
    return;
  };

  const downloadCompletedDocument = async (e) => {
    e && e.preventDefault() && e.stopPropagation();
    if (e && e.target) {
      e.target.disabled = true;
    }
    if (fileDownloaded) {
      return; // Only allow to download file once 
    }
    if (e && e.target) {
      e.target.textContent = "Downloading...";
    }
    setFileDownloaded(true);
    let msg = [];
    let msgType = 'info';
    try {
      // Download Completed Document 
      let { error, response, message = '' } = await pdfAPI.downloadCompletedDocument({ request_id, flattened: true });
      let { statusMessage, message: _message, error: _error } = response || {};
      if (_error) {
        throw new Error(_error);
      }
      if (error) {
        if (typeof response?.error === 'string') {
          throw new Error(`${error} - ${response.error}`);
        } else if (typeof response === 'string') {
          throw new Error(`${error} - ${response}`);
        } else if (typeof error === 'string' && typeof message === 'string') {
          error = `${error} - ${message}`;
        }
        throw new Error(error);
      }
      msgType = 'success';
      msg.push(_message || statusMessage || message || "Download Completed Document Successfully!");

      // Download Combined Document 
      let { error: e, response: r, message: m = '' } = await pdfAPI.downloadCompletedDocument({ request_id, flattened: false });
      let { statusMessage: _s, message: _m, error: _e } = response || {};
      if (_e) {
        throw new Error(_e);
      }
      if (e) {
        if (typeof r?.error === 'string') {
          throw new Error(`${e} - ${r.error}`);
        } else if (typeof r === 'string') {
          throw new Error(`${e} - ${r}`);
        } else if (typeof e === 'string' && typeof m === 'string') {
          e = `${e} - ${m}`;
        }
        throw new Error(e);
      }

      msg.push(m || _s || _m || "Download Combined Document Successfully!");
    } catch (err) {
      console.error(err, err.message);
      msgType = 'danger';
      msg.push(`downloadCompletedDocument Error ${err.message}`);
    } finally {
      if (e && e.target) {
        e.target.textContent = "Download Completed";
        if (msgType !== 'success') {
          e.target.disabled = false;
        }
      }
      if (msgType !== 'success') {
        setFileDownloaded(false);
      }
    }
    setInfoMessageType(msgType);
    setInfoMessages((prev) => [...new Set([...prev, ...msg])]);
    return;
  }; // END downloadCompletedDocument

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isValidDocument) {
        if (headerTextRef.current.textContent) {
          headerTextRef.current.textContent = "";
        }
        if (submitButtonRef.current?.innerText?.toLowerCase().includes("i agree")) {
          submitButtonRef.current.innerText = "Submit";
        }
        if (submitButtonRef.current?.disabled) { // Check if submitButtonRef is disabled and enable it 
          submitButtonRef.current.disabled = false;
        }
      }
    }
    return () => mounted = false;
  }, [isValidDocument]);

  return (<PDFViewerWrapper
    theme={theme}
    headerButtons={headerButtons}
    request_id={request_id}
    template_id={template_id}
    pdf_template={pdf_template}
    setIsValidDocument={setIsValidDocument}
    documentIsSigned={documentIsSigned}
    setDocumentIsSigned={setDocumentIsSigned}
    parentCaptureFn={esignCaptureFn}
    parentCaptureTrigger={esignCaptureTrigger}
  >
    {documentIsSigned ? (<div className="px-3 py-3 top-bar">
      <h4 className="d-inline-block">Thank you for signing this document!</h4>
      <IconButton variant={theme === "dark" ? "falcon-default" : "success"} disabled={fileDownloaded} size="sm" icon="pdf" iconAlign="right" transform="shrink-3" className="float-end" onClick={downloadCompletedDocument}>
        <span className="d-none d-sm-inline-block ms-1">Download Completed</span>
      </IconButton>
    </div>) : (<div className="px-3 py-3 top-bar">
      <span className="d-inline-block py-2" ref={headerTextRef} ></span>
      <Button
        variant={'success'}
        onClick={handleIAgree}
        className="float-end"
        size="lg"
        ref={submitButtonRef}
      >Submit</Button>
    </div>)}
    {infoMessages.length > 0 && <div><ListGroup variant="flush">{infoMessages.length > 0 && infoMessages.map((message, index) => (
      <ListGroup.Item key={index} variant={infoMessageType} >{message}</ListGroup.Item>))}</ListGroup>
    </div>}

    <Modal show={isSaving} fullscreen={true} className="opacity-75">
      <Modal.Body className={'text-center opacity-75 d-flex'}>
        <div className="justify-content-center m-auto" style={{ width: "50%" }}>
          <h5><strong>Submitting Document...</strong></h5>
          <Spinner animation="border" role="status"> <span className="visually-hidden">Submitting...</span> </Spinner>
        </div>
      </Modal.Body>
    </Modal>
  </PDFViewerWrapper>);
};

export default EsignDocument;