import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Modal, Spinner, ListGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import PDFJSWebViewer from "./PDFJSWebViewer";
import { pdfAPI } from '../../utils/pdf-api';
import { convertTemplateName } from "../../utils/constants";
const SHOW_LOG = process.env.REACT_APP_SHOW_LOG === "true";
const defaultHeaderButtons = ['resetButton', 'infoButton', 'printButton', 'downloadZipButton', 'downloadPdfButton', 'sendReminderEmailButton', 'menuButton', 'moreButton'];
const emptySignatureValues = [
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAScAAABPCAYAAACpigPCAAAAAXNSR0IArs4c6QAAAltJREFUeF7t1EENAAAMArHh3/Rs3KNTQDrCzhEgQCAosGAmkQgQIHDGSQkIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAsYp+RahCBAwTjpAgEBSwDgl3yIUAQLGSQcIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAsYp+RahCBAwTjpAgEBSwDgl3yIUAQLGSQcIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAsYp+RahCBAwTjpAgEBSwDgl3yIUAQLGSQcIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAsYp+RahCBAwTjpAgEBSwDgl3yIUAQLGSQcIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAg9h0QBQLgfClwAAAABJRU5ErkJggg==",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAL4AAAAvCAYAAABdTnB7AAAAAXNSR0IArs4c6QAAAPFJREFUeF7t0gEBAAAIgzDfv7RBmA2O2zkFggUW3GyyAgc+BMkC4CffbjT4DCQLgJ98u9HgM5AsAH7y7UaDz0CyAPjJtxsNPgPJAuAn3240+AwkC4CffLvR4DOQLAB+8u1Gg89AsgD4ybcbDT4DyQLgJ99uNPgMJAuAn3y70eAzkCwAfvLtRoPPQLIA+Mm3Gw0+A8kC4CffbjT4DCQLgJ98u9HgM5AsAH7y7UaDz0CyAPjJtxsNPgPJAuAn3240+AwkC4CffLvR4DOQLAB+8u1Gg89AsgD4ybcbDT4DyQLgJ99uNPgMJAuAn3y70eAzkCzwpaIAMEt7zWwAAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAL4AAAAvCAYAAABdTnB7AAAAAXNSR0IArs4c6QAAAPFJREFUeF7t0gEBAAAIgzDfv7RBmA2O2zkFggUW3GyyAgc+BMkC4CffbjT4DCQLgJ98u9HgM5AsAH7y7UaDz0CyAPjJtxsNPgPJAuAn3240+AwkC4CffLvR4DOQLAB+8u1Gg89AsgD4ybcbDT4DyQLgJ99uNPgMJAuAn3y70eAzkCwAfvLtRoPPQLIA+Mm3Gw0+A8kC4CffbjT4DCQLgJ98u9HgM5AsAH7y7UaDz0CyAPjJtxsNPgPJAuAn3240+AwkC4CffLvR4DOQLAB+8u1Gg89AsgD4ybcbDT4DyQLgJ99uNPgMJAuAn3y70eAzkCzwpaIAMEt7zWwAAAAASUVORK5CYII=",
];
let timeoutID = null;

const PDFViewerWrapper = ({ headerButtons = defaultHeaderButtons, template_id, pdf_template, request_id, preview_id, theme = 'light',
  setIsValidDocument = () => { }, documentIsSigned = false, setDocumentIsSigned = () => { }, parentCaptureFn, parentCaptureTrigger, children, ...props } = {}) => {
  let params = useParams();
  const queryURL = new URLSearchParams(window.location.search);
  const queryParams = {};
  for (let [key, value] of queryURL.entries()) {
    queryParams[key] = value;
  }

  if (!request_id && (params.request_id || queryParams?.request_id)) {
    request_id = params.request_id || queryParams?.request_id || 0;
  }
  if (!template_id && (params.template_id || queryParams?.template_id)) {
    template_id = params.template_id || queryParams?.template_id;
  }
  if (!pdf_template && (params.pdf_template || queryParams?.pdf_template)) {
    pdf_template = params.pdf_template || queryParams?.pdf_template;
  }
  if (!preview_id && (params.preview_id || queryParams?.preview_id)) {
    preview_id = params.preview_id || queryParams?.preview_id;
  }
  if (!Array.isArray(headerButtons)) {
    headerButtons = defaultHeaderButtons;
  }
  if (Array.isArray(headerButtons) && headerButtons.length > 0 && headerButtons.some(btn => !`${btn}`.toLowerCase().includes("button"))) {
    headerButtons = headerButtons.map((btn) => !`${btn}`.toLowerCase().includes("button") ? `${btn}Button` : btn); // add 'Button' to the end of each button name if it doesn't already have it
  }

  const [warningMessageType, setWarningMessageType] = useState('warning'); // 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
  const [warningMessages, setWarningMessages] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(true); // IMPORTANT: must be set true by default so we can wait for this to be false before trying anything else 
  const [toggleResetFromParent, setToggleResetFromParent] = useState(false); // Used to reload pdf when props change. Calls loadDocument which ultimately calls getPdfStream. i.e. setToggleReset((prev) => !prev);
  const [pageTitle, setPageTitle] = useState(!!preview_id ? "Preview" : "PDF Viewer");
  const [fieldData, setFieldData] = useState({});
  const [fieldDataKey, setFieldDataKey] = useState(null); // The last field key that was changed 

  const sendCompletedDocument = async (e, { request_id, recipient_email, recipient_name } = {}) => {
    e && e.preventDefault() && e.stopPropagation();
    if (e.target) { // Disable button after click 
      e.target.disabled = true;
    }
    return modifyEsignRequest({ action: "send_completed_document", request_id, recipient_email, recipient_name });
  };

  /**
   * getPdfStream
   * @param {object} params
   * @returns {blob} pdfStream
   */
  const getPdfStream = async ({ uuid = preview_id, pdfTemplateId = template_id, pdfTemplate = pdf_template, RID = request_id, loadCounter = 0 } = {}) => {
    const isPreviewOnly = !!uuid;
    let pdfStream = null;
    if (pdfTemplate === "undefined") {
      pdfTemplate = undefined;
    }
    if (pdfTemplateId === "undefined") {
      pdfTemplateId = undefined;
    }
    if (RID === "undefined") {
      RID = undefined;
    }
    if (uuid === "undefined") {
      uuid = undefined;
    }

    let params = {
      ...(pdfTemplateId && { template_id: pdfTemplateId }),
      ...(pdfTemplate && { pdf_template: pdfTemplate }),
      ...(RID && { request_id: RID }),
      ...(uuid && { uuid }),
    };
    let resp;
    try {
      loadCounter++;
      resp = isPreviewOnly ? await pdfAPI.streamPreview(params) : await pdfAPI.streamPDF(params);
    } catch (error) {
      console.error(error);
      let errorMessage = `getPdfStream ${Object.entries(params).filter(([k, v]) => v).map(([key, value]) => `${key}: ${value}`).join(" ")} Error ${error.message}`;
      console.error("errorMessage", errorMessage);

      // Retry on error status between 400 and 500 every other time 
      let shouldRetry = loadCounter % 2 === 1 && error?.response?.status <= 400 && error?.response?.status >= 500;
      if (shouldRetry) {
        console.log("Error Response", error?.response);
        console.log(`getPdfStream retrying at loadCounter: ${loadCounter}`);
        return await getPdfStream({ uuid, pdfTemplateId, pdfTemplate, RID, loadCounter });
      }

      resp = error?.response || error || {};
      let { headers = {}, data: response, status, statusText } = resp || {};
      let headerType = headers['content-type'] || "application/json";
      let parsedErrorMessages = statusText || status || "Unknown Error";

      if (["text/html", "text/plain", "application/json"].some((t) => (headerType.includes(t))) && response) {
        let tmpData = typeof response?.text === 'function' ? await response?.text() : response?.data || response;
        if (headerType.includes("application/json")) {
          try {
            resp = JSON.parse(tmpData);
          } catch (e) {
            console.error("JSON.parse Error", e);
          }
          parsedErrorMessages = resp?.error || resp?.message || resp?.statusMessage || resp?.status || resp?.statusText || statusText || status || "Unknown Error";
        } else {
          parsedErrorMessages = tmpData;
        }
      };
      console.error("parsedErrorMessages", parsedErrorMessages);

      setWarningMessageType("danger");
      setWarningMessages((prev) => [...new Set([...prev, errorMessage, parsedErrorMessages])]); // Remove duplicate errors 
      setDocumentLoading(false);
      return { ...resp, request_id: RID };
    }

    if (resp && resp instanceof Blob) {
      pdfStream = resp;
    } else if (resp && 'pdfStream' in resp && resp.pdfStream instanceof Blob) {
      pdfStream = { ...resp, ...(!resp?.request_id && { request_id: RID }) }; // { pdfStream, documentSigned, request_id, template_name, filename, signature_options, recipient_name }
    } else { // response is not a PDF blob 
      pdfStream = resp;
    }

    if (resp && resp instanceof Blob === false && ['documentSigned', 'email_completed_to_recipient', 'recipient_name'].some((k) => k in resp)) {
      if (typeof resp.documentSigned === 'string') {
        resp.documentSigned = resp.documentSigned === 'true';
      }
      if (typeof resp.email_completed_to_recipient === 'string') {
        resp.email_completed_to_recipient = resp.email_completed_to_recipient === 'true';
      }
      const { email_completed_to_recipient = false, recipient_email, recipient_name, request_id: id } = resp || {};
      setDocumentIsSigned(resp.documentSigned);
      if (resp.documentSigned) {
        setIsValidDocument(true);
        setWarningMessageType("success");
        const htmlString = <p>Document signed by <strong>{recipient_name}</strong>. {email_completed_to_recipient === false && <span>Send Completed Document to <a className="pe-auto cursor-pointer" onClick={(e) => sendCompletedDocument(e, { request_id: id, recipient_email, recipient_name })}>{recipient_email}</a>?</span>}</p>;
        const msgArray = recipient_email ? [htmlString] : [];
        setWarningMessages(msgArray); // Reset all warning messages
      }
    } // END if (resp && resp instanceof Blob === false && 'documentSigned' in resp)

    // Set Page Title
    let title = '';
    if (resp.template_name) {
      title = convertTemplateName(resp.template_name);
    } else if (resp.filename) {
      title = resp.filename;
    }
    if (isPreviewOnly) {
      if (title) {
        title = `Previewing: ${title}`;
      } else {
        title = 'Preview';
      }
    }
    if (title) {
      setPageTitle(title);
    }

    setDocumentLoading(false);
    return pdfStream;
  }; // END getPdfStream

  const modifyEsignRequest = async (params = {}) => {
    let { fields = {}, xfdf = '', return_type = "pdf", pdf_filename, pdfTemplate = pdf_template, pdfTemplateId = template_id, RID = request_id, ...extraParams } = params || {};

    if (pdfTemplate === "undefined") {
      pdfTemplate = undefined;
    }
    if (pdfTemplateId === "undefined") {
      pdfTemplateId = undefined;
    }
    if (RID === "undefined") {
      RID = undefined;
    }
    if (["undefined", "undefined.pdf"].includes(pdf_filename)) {
      pdf_filename = undefined;
    }

    params = {
      ...(pdfTemplateId && { template_id: pdfTemplateId }),
      ...(pdfTemplate && { pdf_template: pdfTemplate }),
      ...(RID && { request_id: RID }),
      fields, xfdf,
      return_type,
      ...extraParams
    };

    let msg = '';
    let msgType = 'info';
    try {
      let { error, response, message = '' } = await pdfAPI.modifyEsignRequest(params);
      let { statusMessage, message: _message, error: _error } = response || {};
      if (_error) {
        throw new Error(_error);
      }
      if (error) {
        if (typeof response?.error === 'string') {
          throw new Error(`${error} - ${response.error}`);
        } else if (typeof response === 'string') {
          throw new Error(`${error} - ${response}`);
        } else if (typeof error === 'string' && typeof message === 'string') {
          error = `${error} - ${message}`;
        }
        throw new Error(error);
      }

      msgType = 'success';
      msg = _message || statusMessage || message || `Send Reminder Email Successfully!`;
    } catch (error) {
      console.log(error, error.message);
      msgType = 'danger';
      msg = `modifyEsignRequest Error ${error.message}`;
    }
    setWarningMessageType(msgType);
    setWarningMessages((prev) => [...new Set([...prev, msg])]);
    return;
  }; // END modifyEsignRequest 

  /**
   * saveFilledPdf
   * @param {object} params
   */
  const saveFilledPdf = async (params = {}) => {
    let { fields = {}, xfdf = '', return_type = "pdf", pdf_filename, pdfTemplate = pdf_template, pdfTemplateId = template_id, RID = request_id, missingRequiredFieldsArray, ...extraParams } = params || {};
    if (pdfTemplate === "undefined") {
      pdfTemplate = undefined;
    }
    if (pdfTemplateId === "undefined") {
      pdfTemplateId = undefined;
    }
    if (RID === "undefined") {
      RID = undefined;
    }
    if (["undefined", "undefined.pdf"].includes(pdf_filename)) {
      pdf_filename = undefined;
    }

    let msg = '';
    let msgType = 'info';
    const isValid = missingRequiredFieldsArray?.length === 0;
    if (typeof setIsValidDocument === 'function') {
      setIsValidDocument(isValid);
    }
    if (!isValid) {
      msgType = 'warning';
      msg = "Document is not ready! Please check required fields.";
      console.log(missingRequiredFieldsArray?.length > 0 ? `[saveFilledPdf] Missing required fields: ${missingRequiredFieldsArray.join(", ")}` : msg);
      setWarningMessageType(msgType);
      setWarningMessages([msg]); // Reset all warning messages and add new one 
      return;
    } else {
      setWarningMessages([]); // Reset all warning messages
    }

    params = {
      ...(pdfTemplateId && { template_id: pdfTemplateId }),
      ...(pdfTemplate && { pdf_template: pdfTemplate }),
      ...(RID && { request_id: RID }),
      fields, xfdf,
      return_type,
      ...extraParams
    };

    try {
      let { error, response, message = '' } = await pdfAPI.saveFilledPdf(params);
      let { statusMessage, message: _message, error: _error } = response || {};
      if (_error) {
        throw new Error(_error);
      }
      if (error) {
        if (typeof response?.error === 'string') {
          throw new Error(`${error} - ${response.error}`);
        } else if (typeof response === 'string') {
          throw new Error(`${error} - ${response}`);
        } else if (typeof error === 'string' && typeof message === 'string') {
          error = `${error} - ${message}`;
        }
        throw new Error(error);
      }

      msgType = 'success';
      msg = _message || statusMessage || message || `Save filled PDF Successfully!`;
    } catch (error) {
      console.log(error, error.message);
      msgType = 'danger';
      msg = `saveFilledPdf Error ${error.message}`;
    }
    setWarningMessageType(msgType);
    setWarningMessages((prev) => [...new Set([...prev, msg])]);
    return;
  }; // END saveFilledPdf 

  const handleParentCapture = async (params = {}) => {
    let msg = '', msgType = 'info', error = null, response = {}, message = '';
    if (typeof parentCaptureFn !== 'function') {
      msgType = "warning";
      msg = "parentCaptureFn is not a function";
      setWarningMessageType(msgType);
      setWarningMessages([msg]); // Reset all warning messages and add new one 
      return { error: msg, response, message: msg };
    }
    let { fields = {}, xfdf = '', return_type = "pdf", pdf_filename, pdfTemplate = pdf_template, pdfTemplateId = template_id, RID = request_id, missingRequiredFieldsArray, ...extraParams } = params || {};

    if (pdfTemplate === "undefined") {
      pdfTemplate = undefined;
    }
    if (pdfTemplateId === "undefined") {
      pdfTemplateId = undefined;
    }
    if (RID === "undefined") {
      RID = undefined;
    }
    if (["undefined", "undefined.pdf"].includes(pdf_filename)) {
      pdf_filename = undefined;
    }

    const isValid = missingRequiredFieldsArray?.length === 0;
    if (typeof setIsValidDocument === 'function') {
      setIsValidDocument(isValid);
    }
    if (!isValid) {
      msgType = 'warning';
      msg = "[handleParentCapture] Document is not ready! Please check required fields.";
      console.log(missingRequiredFieldsArray?.length > 0 ? `[handleParentCapture] Missing required fields: ${missingRequiredFieldsArray.join(", ")}` : msg);
      setWarningMessageType(msgType);
      setWarningMessages([msg]); // Reset all warning messages and add new one 
      return { error: msg, response, message: msg };
    } else {
      setWarningMessages([]); // Reset all warning messages
    }

    params = {
      ...(pdfTemplateId && { template_id: pdfTemplateId }),
      ...(pdfTemplate && { pdf_template: pdfTemplate }),
      ...(RID && { request_id: RID }),
      fields, xfdf,
      return_type,
      ...extraParams
    };

    try {
      let { error: captureError, response: captureResponse, message: captureMessage = '' } = await parentCaptureFn(params) || {};
      let { statusMessage, message: responseMessage, error: responseError } = captureResponse || {};
      error = captureError;
      response = captureResponse;
      message = captureMessage;
      if (responseError || captureError) {
        error = `${captureError ? `${captureError?.message} ` : ''}`;
        if (typeof responseError === 'string') {
          error += responseError;
        } else if (typeof captureResponse === 'string') {
          error += captureResponse;
        }
        msg = `Parent Capture Failed! `;
        msgType = 'danger';
      } else {
        msg = `Parent Capture Successfully! `;
        msgType = 'success';
      }
      msg += `${statusMessage ? `${statusMessage} ` : ''}${(captureMessage && captureMessage !== statusMessage) ? `${captureMessage} ` : ''}${responseMessage ? `${responseMessage} ` : ''}`;
    } catch (error) {
      console.log(error, error.message);
      msgType = 'danger';
      msg = `parentCaptureFn Error ${error.message}`;
    }

    let msgArray = [msg];
    if ('documentSigned' in response) {
      if ('email_completed_to_recipient' in response && 'emailCompletedToRecipient' in response === false) {
        response.emailCompletedToRecipient = response.email_completed_to_recipient;
      }
      if (typeof response.documentSigned === 'string') {
        response.documentSigned = response.documentSigned === 'true';
      }
      if (typeof response.emailCompletedToRecipient === 'string') {
        response.emailCompletedToRecipient = response.emailCompletedToRecipient === 'true';
      }
      const { emailCompletedToRecipient, documentSigned, recipient_email, recipient_name, request_id: id } = response || {};

      setDocumentIsSigned(documentSigned);
      if (documentSigned) {
        const htmlString = <p>Document signed by <strong>{recipient_name}</strong>. {emailCompletedToRecipient === false && <span>Send Completed Document to <a className="pe-auto cursor-pointer" onClick={(e) => sendCompletedDocument(e, { request_id: id, recipient_email, recipient_name })}>{recipient_email}</a>?</span>}</p>;
        if (recipient_email) {
          msgArray = [htmlString];
        } // END if (recipient_email) 
      } // END if (documentSigned) 
    } // END if ('documentSigned' in response) 

    setWarningMessageType(msgType);
    setWarningMessages(msgArray); // Reset all warning messages and add new one 

    return { error, response, message };
  }; // END handleParentCapture 

  const previewSumit = async (params = {}) => {
    SHOW_LOG && console.log("previewSumit", params);

    // Prompt the user to complete esign request 
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-lg btn-success",
      },
      buttonsStyling: false
    });
    const { isConfirmed, ...results } = await swalWithBootstrapButtons.fire({
      title: "Done!",
      html: `<p>Preview Only! Not actually submitting anything.</p>`,
      icon: 'success',
      confirmButtonText: 'Ok'
    }); // Returns { isConfirmed: true, isDenied: false, isDismissed: false, dismiss: "backdrop" }

    if (isConfirmed) {
      SHOW_LOG && console.log("User clicked Ok");
    } else {
      SHOW_LOG && console.info(results);
      SHOW_LOG && console.log("User clicked Cancel");
    }
    return;
  }; // END previewSumit

  const handleCapture = async ({ action = "saveFilledPdf", ...params } = {}) => {
    const isPreviewOnly = !!preview_id;
    if (isPreviewOnly) {
      action = "preview";
    }
    params.action = action;
    switch (action) {
      case "preview":
        return await previewSumit(params);
      case "saveFilledPdf":
        return await saveFilledPdf(params);
      case "sendReminderEmail":
        params.action = "check_status"; // check_status action will call sendReminderEmail on the backend 
        return await modifyEsignRequest(params);
      case "send_completed_document":
        return await modifyEsignRequest(params);
      case "handleParentCapture":
        return await handleParentCapture(params);
      default: // Update Esign Request 
        return modifyEsignRequest(params);
    }
  }; // END handleCapture 

  const onFieldChangeCallBack = async ({ key, value, input_type, is_required = false, page = 0, rect = {}, signature_options, action, imported, isUndoRedo, autoFocus, extractedFields, ...extraParams } = {}) => {
    const isAnnotationField = action !== undefined;
    SHOW_LOG && console.log(`onFieldChangeCallBack on listener ${isAnnotationField ? 'annotationChanged' : 'fieldChanged'}:`, { key, value, input_type, is_required, page, rect, signature_options, action, imported, isUndoRedo, autoFocus, extractedFields });
    // SHOW_LOG && console.log("extraParams:", extraParams); // extraParams may include captureFields response { ok, xfdfString, missingRequiredFieldsArray }

    let msgArray = [];
    let msg = '';
    let msgType = 'info';
    const valueIsEmpty = emptySignatureValues.some((emptyValue) => value === emptyValue);

    if (is_required && ((input_type === 'signature' && valueIsEmpty) || !value || !key)) {
      setIsValidDocument(false);
      msgType = 'warning';
      msg = key ? `Missing required fields: ${key}` : `Missing required fields`;
    } else {
      setIsValidDocument(true);
      setWarningMessages([]); // Reset all warning messages
    }

    if (msg) {
      msgArray.push(msg);
    }

    let fieldDataParams = {
      ...(request_id && { request_id }),
      ...(template_id && { template_id }),
      key, value, input_type, is_required
    };

    // Update fieldData state preserving previous values
    if (!preview_id) {
      setFieldData((prev) => ({ ...prev, [key]: { ...prev[key], ...fieldDataParams } }));
      setFieldDataKey(key);
    } // END if (!preview_id) 

    // Not sending out warning on field change for now because this loads on page load which we want to wait until client tries to submit document first 
    // setWarningMessageType(msgType);
    // setWarningMessages((prev) => [...new Set([...prev, ...msgArray])]);
    if (msgArray.length > 0) {
      console.log(`${msgType}: ${msgArray.map((m) => m).join(", ")}`);
    }

    return fieldDataParams;
  }; // END onFieldChangeCallBack 

  const saveFieldData = async (key = fieldDataKey) => {
    if (!key || preview_id) {
      return;
    }

    let msgArray = [], msg = '', msgType = 'info';
    let fieldDataParams = fieldData[key] || {};
    if (Object.keys(fieldDataParams).length > 0) {
      let resp = await pdfAPI.saveFieldData(fieldDataParams); // Create or update field data 
      let { statusCode, statusMessage, key: field_name, ...data } = resp || {};
      if (statusCode === 200) {
        setFieldData((prev) => ({ ...prev, [field_name]: { ...prev[field_name], key: field_name, ...data } }));
      } else {
        console.info(resp);
        msg = `pdfAPI.saveFieldData ${field_name} Error ${statusCode} - ${statusMessage}`;
        msgArray.push(msg);
        msgType = 'warning';
      }

      // Capture stamp signature field data 
      if (fieldDataParams.input_type === 'signature') {
        fieldDataParams = fieldData[`${key}_stamp`] || {};
        if (Object.keys(fieldDataParams).length > 0 && fieldDataParams.key === `${key}_stamp`) {
          resp = await pdfAPI.saveFieldData(fieldDataParams); // Create or update stamp field data 
          let { statusCode: sCode, statusMessage: sMessage, key: fieldName, ...stampData } = resp || {};
          if (sCode === 200) {
            setFieldData((prev) => ({ ...prev, [fieldName]: { ...prev[fieldName], key: fieldName, ...stampData } }));
          } else {
            console.info(resp);
            msg = `pdfAPI.saveFieldData Error ${sCode} - ${sMessage}`;
            msgArray.push(msg);
            msgType = 'warning';
          }
        }
      } // END capture stamp signature field

      setFieldDataKey(false); // Reset fieldDataKey after saving field data. False will not trigger useEffect to save field data again 
    } // END create or update field data

    if (msgArray.length > 0) {
      console.log(`${msgType}: ${msgArray.map((m) => m).join(", ")}`);
    }
  }; // END saveFieldData 

  useEffect(() => {
    let mounted = true;
    const triggerUpdate = async () => (await saveFieldData(fieldDataKey));
    mounted && fieldDataKey && triggerUpdate();
    return () => mounted = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldDataKey]);

  useEffect(() => {
    let mounted = true;
    if (template_id || request_id || preview_id) { // reload pdf when props change
      setToggleResetFromParent((prev) => !prev);
    } else { // Display warning message if template_id or request_id is not provided and neither above condition is true 
      let msgType = 'danger';
      let msg = `template_id or request_id or preview_id is required`;
      setWarningMessageType(msgType);
      setWarningMessages((prev) => [...new Set([...prev, msg])]);
    }

    // Timeout documentLoading after 5 seconds 
    const loadingTimeout = async () => {
      if (timeoutID) {
        SHOW_LOG && console.log(`Clearing documentLoading timeout ${timeoutID}...`);
        clearTimeout(timeoutID);
      }
      timeoutID = setTimeout(() => {
        if (documentLoading) {
          SHOW_LOG && console.log("Document Loading Timeout...");
          setDocumentLoading(false);
        } else {
          SHOW_LOG && console.log("Document Loading Completed...");
        }
      }, 3500);
      SHOW_LOG && console.log(`Setting documentLoading timeout ${timeoutID}`);
    }
    mounted && loadingTimeout();
    return () => mounted = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template_id, request_id, preview_id, headerButtons]);

  // return (<div className="vh-100">
  return (<Card className="esign-doc vh-100">
    {!!preview_id && <div className="px-3 py-3 top-bar">
      <h4 className="d-inline-block">{pageTitle || "Preview"}</h4>
    </div>}
    {children}
    <div>{warningMessages.length > 0 && <ListGroup variant="flush">{warningMessages.length > 0 && warningMessages.map((message, index) => (
      <ListGroup.Item key={index} variant={warningMessageType} >{message}</ListGroup.Item>))}</ListGroup>}
    </div>

    {documentIsSigned === false && (template_id || pdf_template || request_id || preview_id) && <PDFJSWebViewer
      theme={theme}
      getPdfStream={getPdfStream}
      captureCallback={handleCapture}
      isPreviewOnly={!!preview_id}
      pdfFilename={request_id
        ? `request_id_${request_id}.pdf`
        : (template_id && pdf_template ? `${template_id}_${pdf_template}.pdf`
          : (template_id ? `template_id_${template_id}.pdf`
            : (preview_id ? `preview_id_${preview_id}.pdf`
              : `${pdf_template}.pdf`)))}
      setWarningMessages={setWarningMessages}
      setWarningMessageType={setWarningMessageType}
      headerButtons={headerButtons}
      toggleResetFromParent={toggleResetFromParent}
      documentLoading={documentLoading}
      setDocumentLoading={setDocumentLoading}
      onFieldChangeCallBack={onFieldChangeCallBack}
      parentCaptureTrigger={parentCaptureTrigger}
      {...props} />}

    <Modal show={documentLoading} fullscreen={true} className="opacity-75">
      <Modal.Body className={'text-center opacity-75 d-flex'}>
        <div className="justify-content-center m-auto" style={{ width: "50%" }}>
          <h5><strong>Loading Document...</strong></h5>
          <Spinner animation="border" role="status"> <span className="visually-hidden">Loading...</span> </Spinner>
        </div>
      </Modal.Body>
    </Modal>
  </Card>);
};

export default PDFViewerWrapper;
