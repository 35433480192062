import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import bgShape from "../assets/img/illustrations/bg-shape.png";
import shape1 from "../assets/img/illustrations/shape-1.png";

const AuthCardLayout = ({ leftSideContent, theme = "light", children, footer = true, ...props }) => {
  return (
    <section className={`App ${theme === 'light' ? 'bg-light' : 'bg-dark'}`} >
      <Row className="d-flex page-container flex-center g-0 min-vh-100" {...props} >
        <Col lg={!!leftSideContent ? 8 : 6} className="py-3 position-relative">
          <img
            className="bg-auth-circle-shape"
            src={bgShape}
            alt=""
            width="250"
          />
          <img
            className="bg-auth-circle-shape-2"
            src={shape1}
            alt=""
            width="150"
          />
          <Card className="overflow-hidden z-index-1 d-flex flex-column justify-content-center align-items-center">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                {leftSideContent && <Col md={5} className="text-center bg-card-gradient">{leftSideContent}</Col>}
                <Col
                  md={!!leftSideContent ? 7 : 12}
                  className="d-flex justify-content-center align-items-center bg-white bg-gradient"
                >
                  <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default AuthCardLayout;
