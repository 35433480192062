import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Spinner, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { TAGS } from "../utils/constants";
import { pdfAPI } from "../utils/pdf-api";
import TagModal from "../components/Modals/TagModal";
import TagsFilter from "../components/forms/TagsFilter";
const defaultQueryParams = { page: 1, limit: 20, navSize: 5, showLog: false };

export default function TagsView({ theme, children, ...props } = {}) {
  const [selectedTagId, setSelectedTagId] = useState(0);
  const [tagRows, setTagRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [tagModalState, setTagModalState] = useState(false);
  const [lastRequestPayload, setLastRequestPayload] = useState({}); //allows page click to select new page when filters have been entered but not applied

  const openTemplateModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setTagModalState(true);
  };
  const closeTemplateModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Reset tag on closing the modal 
    setSelectedTagId(0);
    setTagModalState(false);
  };

  const fetchTags = async (params = {}) => {
    let queryParams = {
      ...defaultQueryParams,
      ...params,
    };
    setLastRequestPayload(queryParams);

    try {
      setLoadingTable(true);
      const { status = 500, statusText = 'Internal Server Error', data = {} } = await pdfAPI.getAllTags(queryParams);
      let { rows = [], count = 0, limit = 500, page = 1, pageCount = 1, pages = [1] } = data || {};
      if (status !== 200) {
        console.log(data);
        console.error(`${status}: ${statusText}`);
      }

      // Set pages so we always have the first and last page in the pagination 
      pages = pages.reduce((acc, pageNumber, index) => {
        if (index === 0 && pageNumber !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(pageNumber);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      if (Array.isArray(rows)) {
        setTagRows(rows);
        setRowCount(count);
        setPages(pages);
      }
    } catch (error) {
      console.error('getAllTags Error:', error, error?.message);
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    fetchTags(TAGS.initialValues);
  }, []);

  const mapTagToRows = (tags = tagRows) => {
    return tags.map((tag) => (<tr key={tag.tag_id}>
      <td className="text-center align-middle">
        <FontAwesomeIcon className="text-muted cursor-pointer" onClick={(e) => {
          setSelectedTagId(tag.tag_id);
          openTemplateModal(e);
        }} icon={faPenToSquare} />
      </td>

      {TAGS.visibleFields.map((field) => (<td key={field}>{tag[field]}</td>))}
    </tr>)
    );
  }

  return (<div className="page-container"  {...props}>
    <Row className="justify-content-between border-bottom mb-3 pb-2">
      <Col>
        <h2>Tags</h2>
      </Col>
      <Col className="d-flex justify-content-end">
        <TagModal className="mx-2" tag_id={selectedTagId} showLink={false} refreshParentFn={() => fetchTags(TAGS.initialValues)} parentModalState={tagModalState} setParentModalState={setTagModalState} parentCloseModal={closeTemplateModal} />
      </Col>
    </Row>
    <TagsFilter handleFilter={fetchTags} handleClear={() => setActivePage(1)} initialValues={TAGS.initialValues} />
    <Table hover className="templates-table" variant={theme}>
      <thead style={{ "borderBottom": "2px solid black" }}>
        <tr>
          <th>Count: {rowCount}</th>
          {TAGS.visibleFields.map((field, i) => (<th key={`${i}_${field}`}>{TAGS.labels[field]}</th>))}
        </tr>
      </thead>
      <tbody>{loadingTable ? <tr><td colSpan={TAGS.visibleFields.length + 1}><Spinner as="span" animation="border" role="status" aria-hidden="true"><span className="visually-hidden">Loading...</span></Spinner></td></tr> : mapTagToRows(tagRows)}</tbody>
      {Pages.length > 1 && (<tfoot>
        <tr>
          <td className="text-right" colSpan={TAGS.visibleFields.length + 1} >
            <nav>
              <ul className="pagination justify-content-end" data-active-page={activePage} >
                {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                  <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                    <Link
                      to={`#page-${pageNumber}`}
                      className="page-link"
                      onClick={(e) => {
                        e.preventDefault();
                        fetchTags({
                          ...lastRequestPayload,
                          page: pageNumber
                        });
                        setActivePage(pageNumber);
                      }}
                      {...(pageNumber === activePage) && { tabIndex: -1 }}
                    >{pageNumber}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          </td>
        </tr>
      </tfoot>)}
    </Table>
  </div>);
}