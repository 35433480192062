import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useUserData } from "./contexts/AuthContext";
import useTheme from "./hooks/UseTheme";

import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalStyle } from "./styled/Global";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./styled/Themes";

import MainHeader from "./components/MainHeader";
import Footer from "./components/Footer";
import PDFViewerWrapper from "./components/pdfjs-viewer/PDFViewerWrapper";

import CheckAccess from "./pages/CheckAccess";
import EsignDocument from "./pages/EsignDocument";
import Unauthorized from "./pages/Unauthorized";
import TemplatesView from './pages/TemplatesView';
import TemplateDetails from './pages/TemplateDetails';
import TagsView from './pages/TagsView';
import Requests from './pages/Requests';
import Users from './pages/Users';
import UserDetails from './pages/UserDetails';
import Accounts from './pages/Accounts';
import AccountDetails from './pages/AccountDetails';
import { getParams, arrayRange } from "./utils/";

const DEFAULT_PAGE = process.env.REACT_APP_DEFAULT_PAGE || '/requests';

const MainLayout = ({ theme, toggleTheme, showMenu = false, ...props } = {}) => {
  return (<div className={`App ${theme === 'light' ? 'bg-light' : 'bg-dark'}`}>
    <MainHeader toggleTheme={toggleTheme} theme={theme} showMenu={showMenu} {...props} />
    <Outlet />
    <Footer theme={theme} />
  </div>);
};

const App = () => {
  const [theme, toggleTheme] = useTheme();
  const currentTheme = theme === "light" ? lightTheme : darkTheme;

  return (<Router basename={process.env.PUBLIC_URL}>
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <Routes>
        <Route path="/check-access" element={<CheckAccess theme={theme} toggleTheme={toggleTheme} />} />
        <Route path="/check-access/:request_id" element={<CheckAccess theme={theme} toggleTheme={toggleTheme} />} />

        <Route path="/preview/:preview_id" element={<PDFViewerWrapper theme={theme} headerButtons={[]} />} />
        <Route path="/preview-pdf/:template_id" element={<PDFViewerWrapper theme={theme} />} />
        <Route path="/esign/:request_id" element={<PrivateAccessRoute theme={theme} toggleTheme={toggleTheme} withHeader={false} />} >
          <Route index element={<EsignDocument />} />
        </Route>

        <Route path="/templates" element={<PrivateAccessRoute theme={theme} toggleTheme={toggleTheme} />} >
          <Route index element={<TemplatesView theme={theme} />} />
          <Route path=":template_id" element={<TemplateDetails theme={theme} />} />
        </Route>
        <Route path="/tags" element={<PrivateAccessRoute theme={theme} toggleTheme={toggleTheme} />} >
          <Route index element={<TagsView theme={theme} />} />
        </Route>
        <Route path="/requests" element={<PrivateAccessRoute theme={theme} toggleTheme={toggleTheme} />} >
          <Route index element={<Requests theme={theme} />} />
        </Route>

        <Route path="/users" element={<PrivateAccessRoute theme={theme} toggleTheme={toggleTheme} />} >
          <Route index element={<Users theme={theme} />} />
          <Route path=":user_id" element={<UserDetails theme={theme} />} />
        </Route>

        <Route path="/accounts" element={<PrivateAccessRoute theme={theme} toggleTheme={toggleTheme} />} >
          <Route index element={<Accounts theme={theme} />} />
          <Route path=":account_id" element={<AccountDetails theme={theme} />} />
        </Route>

        {<Route path="/errors" element={<MainLayout theme={theme} toggleTheme={toggleTheme} />} >
          {[...arrayRange(400, 418), ...arrayRange(421, 431), 451, ...arrayRange(500, 511)].map((status) =>
            <Route key={status} path={status.toString()} element={<Unauthorized status={status} theme={theme} />} />
          )}
        </Route>}

        <Route path="*" element={<Navigate to={DEFAULT_PAGE} replace />} />
      </Routes>
    </ThemeProvider>
  </Router>);
};

let redirectCount = 5;
const MAX_REDIRECTS = 5;
function PrivateAccessRoute({ theme, toggleTheme, withHeader = true, ...props } = {}) {
  let { request_id, ...params } = useParams();
  const queryURL = new URLSearchParams(window.location.search);
  const queryParams = params;
  for (let [key, value] of queryURL.entries()) {
    queryParams[key] = value;
  }

  const { setUserData, userData } = useUserData();
  const { hasAccessToPage = false, hasAccessToPages, referrer } = userData || {};
  const { pathname } = useLocation() || {}; // Current url the user tried to access 

  // Check if user has access to the page
  const checkA = hasAccessToPage === pathname; // pathname=/requests hasAccessToPage = '/requests' will return true
  const checkB = hasAccessToPages.includes(pathname); // pathname=/templates hasAccessToPages = ['/templates', '/tags', '/requests'] will return true
  const checkC = hasAccessToPages.some(page => pathname.includes(page)); // pathname=/templates/1f16b4db-e56b-40d9-a36d-178842f29485 page=/templates will return true 
  const hasAccess = checkA || checkB || checkC;

  let navTo = request_id ? `/check-access/${request_id}${getParams(queryParams)}` : `/check-access${getParams(queryParams)}`;
  if (!hasAccess && pathname !== navTo) {
    if (redirectCount-- <= 0) {
      navTo = `/errors/429`; // Too Many Requests 
    }
    console.log(`Redirecting count: ${MAX_REDIRECTS - redirectCount} from: ${pathname} to location: ${navTo} referrer: ${referrer}`);
  }

  useEffect(() => {
    let mounted = true;
    if (mounted && typeof setUserData === 'function' && pathname && pathname !== referrer) {
      setUserData(prevState => ({ ...prevState, referrer: pathname, ...(request_id && { request_id }) }));
    }
    return () => {
      mounted = false;
    };
  }, [pathname]);

  return (hasAccess || pathname === navTo) ? (
    withHeader ? <MainLayout showMenu={true} theme={theme} toggleTheme={toggleTheme} {...props}><Outlet /></MainLayout> : <Outlet />
  ) : <Navigate to={navTo} />;
};

export default App;

