import { Formik, Field, Form } from "formik";
import { Form as BootstrapForm, Button } from "react-bootstrap";
import { StyledGreenCheckbox } from "../../styled/Forms";
import { TEMPLATES } from "../../utils/constants";

export default function TemplatesFilter({ handleFilter, handleClear, initialValues, children, ...props }) {
  const mapFieldsToControls = (fields = TEMPLATES.visibleFields) => {
    return fields.map((field, idx) => {
      let control = <Field as={BootstrapForm.Control} type="text" name={field} className="form-control-sm"></Field>;

      if (field === "is_published") {
        control = <StyledGreenCheckbox className="mt-3">
          <Field as={BootstrapForm.Control} type="checkbox" name={field} className="form-control-sm"></Field>
          <span className="ms-2">{TEMPLATES.labels[field]}</span>
        </StyledGreenCheckbox>
      }

      return (<div key={idx} className={`col ${field === "is_published" ? "pt-3" : ""}`}>
        <BootstrapForm.Group>
          {field !== "is_published" && <BootstrapForm.Label>{TEMPLATES.labels[field]}</BootstrapForm.Label>}
          {control}
        </BootstrapForm.Group>
      </div>);
    });
  };

  return (<Formik
    initialValues={initialValues}
    onSubmit={async (values, { setSubmitting }) => {
      await handleFilter(values);
      setSubmitting(false);
    }}
  >
    {({ values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      resetForm }) => (<Form>
        <div className="row" {...props}>
          {mapFieldsToControls(TEMPLATES.visibleFields)}
          <div className="col d-flex justify-content-around align-items-end">
            <Button type="submit" variant="primary" size="sm" disabled={isSubmitting}>Apply Filters</Button>
            <Button 
              variant="outline-danger" 
              size="sm" 
              onClick={() => { 
                resetForm();
                handleSubmit();
                handleClear();
              }}
              disabled={isSubmitting}>
                Clear Filters
            </Button>
          </div>
        </div>
      </Form>)}
  </Formik>);
}