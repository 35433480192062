import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Spinner, Row, Col, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import UsersFilter from "../components/forms/UsersFilter";
import UserDetails from "./UserDetails";
import { useUserData } from "../contexts/AuthContext";
import { USERS, convertTemplateName } from "../utils/constants";
import { UserAPI } from "../utils/API";
import { humanize, handleTableRowClick } from "../utils/";
const defaultQueryParams = { page: 1, limit: 25, navSize: 5, showLog: false, orderASC: false, orderby: "name" };

export default function Users({ theme, children, ...props } = {}) {
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { role_name, user_id: loggedInUserID } = userData || {};
  const isSuperAdmin = role_name === 'super-admin';
  const isAdmin = role_name === 'admin';
  const hasPermissionToCreateUsers = [isSuperAdmin, isAdmin].some(Boolean);

  const [userModalState, setUserModalState] = useState(false);
  const openUserModal = () => (setUserModalState(true));
  const closeUserModal = () => (setUserModalState(false));

  const [userRows, setUserRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [Pages, setPages] = useState([1]);
  const [activePage, setActivePage] = useState(1);
  const [lastRequestPayload, setLastRequestPayload] = useState({});

  const fetchUsers = async (params = {}) => {
    const selectedRowIds = (selectedRows || []).map(({ user_id } = {}) => user_id).filter(Boolean);
    const queryParams = {
      ...defaultQueryParams,
      user_id: selectedRowIds.length > 0 ? selectedRowIds : "",
      ...params,
    };
    setLastRequestPayload(queryParams);

    try {
      setLoadingTable(true);
      const { status = 500, statusText = 'Internal Server Error', data = {} } = await UserAPI.getAllUsers(queryParams);
      let { rows = [], count = 0, limit = 500, page = 1, pageCount = 1, pages = [1] } = data || {};
      if (status !== 200) {
        console.log(data);
        console.error(`${status}: ${statusText}`);
      }

      // Set pages so we always have the first and last page in the pagination 
      pages = pages.reduce((acc, pageNumber, index) => {
        if (index === 0 && pageNumber !== 1) {
          acc.push(1); // Always include the first page 
        }
        acc.push(pageNumber);
        if (index === (pages.length - 1) && !acc.includes(pageCount)) {
          acc.push(pageCount); // Always include the last page
        }
        return acc;
      }, []);

      if (Array.isArray(rows)) {
        setUserRows(rows);
        setRowCount(count);
        setPages(pages);
      }
    } catch (error) {
      console.error('getAllUsers Error:', error, error?.message);
      setUserRows([]);
      setRowCount(0);
      setPages([1]);
    } finally {
      setLoadingTable(false);
    }
  };

  const navigateUserDetails = (user) => {
    navigate(`/users/${user.user_id}`, {
      state: { user }
    });
  };

  useEffect(() => {
    fetchUsers(USERS.initialValues);
  }, []);

  const mapUsersToRows = (users = userRows) => {
    if (!Array.isArray(users) || users.length === 0) {
      return (<tr><td colSpan={USERS.visibleFields.length + 1}>No users found</td></tr>);
    }

    return users.map((u, tr_index) => {
      const visibleFields = USERS.visibleFields;
      return (<tr key={u.user_id} onClick={(e) => (handleTableRowClick.call(null, e))}>
        <td className="text-center align-middle">
          <FontAwesomeIcon className="text-muted cursor-pointer" onClick={() => navigateUserDetails(u)} icon={faPenToSquare} />
        </td>

        {visibleFields.map((field) => {
          let fieldValue = u[field];

          if (field === "enabled") {
            fieldValue = fieldValue
              ? <FontAwesomeIcon className="text-success" size="lg" icon={faCheck} />
              : <FontAwesomeIcon className="text-danger" size="lg" icon={faXmark} />
          }

          return (
            <td key={field} className={classNames({ "text-center": field === "enabled", "align-middle": field === "enabled" })}>{fieldValue}</td>
          );
        })}
      </tr>)
    });
  };

  return (<div className="page-container"  {...props}>
    <Row className="justify-content-between border-bottom mb-3 pb-2">
      <Col>
        <h2>Users</h2>
      </Col>
      {hasPermissionToCreateUsers && <Col className="d-flex justify-content-end">
        <Button size="lg" onClick={openUserModal}>+ Add User</Button>
        <Modal
          show={userModalState}
          onHide={closeUserModal}
          animation={false}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserDetails theme={theme} isModal={true} hideModal={closeUserModal} />
          </Modal.Body>
        </Modal>
      </Col>}
    </Row>
    <UsersFilter handleFilter={fetchUsers} handleClear={() => { setLastRequestPayload({}); setActivePage(1); }} initialValues={{ ...USERS.initialValues, ...lastRequestPayload }} />
    <Table hover className="templates-table" variant={theme}>
      <thead style={{ "borderBottom": "2px solid black" }}>
        <tr>
          <th>Count: {rowCount}</th>
          {(USERS.visibleFields).map((field, i, a) => (<th key={`${i + 1}_${field}`}>{USERS.labels[field] || humanize(field)}</th>))}
        </tr>
      </thead>
      <tbody>{loadingTable
        ? <tr><td colSpan={USERS.visibleFields.length + 1}><Spinner as="span" animation="border" role="status" aria-hidden="true"><span className="visually-hidden">Loading...</span></Spinner></td></tr>
        : mapUsersToRows(userRows)}
      </tbody>
      {Pages.length > 1 && (<tfoot>
        <tr>
          <td className="text-right" colSpan={USERS.visibleFields.length + 1} >
            <nav>
              <ul className="pagination justify-content-end" data-active-page={activePage} >
                {Pages && Pages.length > 0 && Pages.map((pageNumber, index) => (
                  <li key={`page-${pageNumber}-${index}`} className={`page-item ${pageNumber === activePage ? "disabled" : ""}`} >
                    <Link
                      to={`#page-${pageNumber}`}
                      className="page-link"
                      onClick={(e) => {
                        fetchUsers({
                          ...lastRequestPayload,
                          page: pageNumber
                        });
                        e.preventDefault();
                        setActivePage(pageNumber);
                      }}
                      {...(pageNumber === activePage) && { tabIndex: -1 }}
                    >{pageNumber}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          </td>
        </tr>
      </tfoot>)}
    </Table>
  </div>);
}; 
