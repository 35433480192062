import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form as BootstrapForm, Button, Spinner, Row, Col, Alert } from "react-bootstrap";
import { Formik, Field, Form, FieldArray, useFormik } from "formik";
import { useUserData } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faFloppyDisk, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { StyledGreenCheckbox } from "../../styled/Forms";
import { AccountAPI } from "../../utils/API";
import { AxiosError } from 'axios';

export default function AccountDetailsForm({ theme = "light", account = null, isNewAccount = false, isModal = false, hideModal, setInvokeParentUpdate = () => { }, invokeParentUpdate, children, ...props } = {}) {
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { role_name, account_id: loggedInAccountID } = userData || {};
  const isSuperAdmin = role_name === 'super-admin';

  // account = {  account_id, external_id, name, description, phone_number, enabled } 
  const { account_id, enabled: initialEnabled = false, ...initialAccountValues } = account || {};
  const initialFormValues = {
    account_id, // Pass this so formik can update whenever this values changes. NOTE: enableReinitialize={true} is required for this to work  
    enabled: typeof initialEnabled === 'string' ? `${initialEnabled}`.toLowerCase() === 'true' : initialEnabled || false,
    ...initialAccountValues,
  };

  const formRef = useRef(null);
  const [editable, setEditable] = useState(isNewAccount);

  // API call functions 
  const upsertAccount = async (formValues = {}, FormikBag = {}) => {
    const { setFormikState, setSubmitting } = FormikBag || {};
    if (!formValues?.account_id && account_id) {
      formValues.account_id = account_id;
    }

    let resp;
    try {
      setEditable(false);
      if (!formValues?.name) {
        throw new Error("Account name is required");
      }
      if (isNewAccount) {
        resp = await AccountAPI.newAccount(formValues);
      } else {
        if (!formValues?.account_id) {
          throw new Error("No account ID provided");
        }
        resp = await AccountAPI.updateAccount(formValues);
      }
    }
    catch (error) {
      if (error instanceof AxiosError) {
        let { response = {} } = error || {};
        let { status, statusText, data = {} } = response || {};
        resp = { status, statusText, data };
        console.error("upsertAccount AxiosError:", resp);
      } else {
        resp = { status: 400, statusText: error?.message, data: error };
        console.error("upsertAccount Error:", resp);
      }
    } finally {
      // setSubmitting(false); // If your onSubmit function is synchronous, then you need to call setSubmitting(false) on your own.
    }

    let { status, statusText, data = {} } = resp || {};
    let message = "", success = false;
    if (status === 200) {
      success = true;
      message = `Account ${isNewAccount ? "created" : "updated"} successfully! ${statusText} (${status})`;
    } else {
      message = `Account ${isNewAccount ? "creation" : "update"} failed! ${data?.message ? data.message + ". " : ""}${statusText} (${status})`;
    }
    if (typeof setFormikState === 'function') {
      setFormikState((prev) => ({ ...prev, status: { success, message } }));
    } else {
      if (success) {
        console.info(message);
      } else {
        console.error(message);
      }
    }

    let timeoutSeconds = 2;
    return await new Promise(resolve => setTimeout(() => {
      if (success && isModal && typeof hideModal === 'function') {
        hideModal();
      }
      if (!isNewAccount && typeof setInvokeParentUpdate === 'function') {
        setInvokeParentUpdate((prevState) => !prevState);
      }
      return resolve(resp);
    }, timeoutSeconds * 1000));
  }; // END upsertAccount 

  const removeAccount = async ({ account_id: accountID } = {}, event, setStatusFn) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!accountID && account_id) {
      accountID = account_id;
    }

    let resp;
    try {
      if (!accountID) {
        throw new Error("No user ID provided");
      }

      resp = await AccountAPI.updateAccount({ account_id: accountID, enabled: false });
      // resp = await AccountAPI.removeAccount(accountID); // This will delete the account!
    }
    catch (error) {
      if (error instanceof AxiosError) {
        let { response = {} } = error || {};
        let { status, statusText, data = {} } = response || {};
        resp = { status, statusText, data };
        console.error("removeAccount AxiosError:", resp);
      } else {
        resp = { status: 400, statusText: error?.message, data: error };
        console.error("removeAccount Error:", resp);
      }
    } finally {
      setInvokeParentUpdate((prevState) => !prevState);
    }

    let { status, statusText, data = {} } = resp || {};
    let message = "", success = false;
    if (status === 200) {
      success = true;
      message = `Account removed successfully! ${statusText} (${status})`;
    } else {
      message = `Account removal failed! ${data?.message ? data.message + ". " : ""}${statusText} (${status})`;
    }
    if (typeof setStatusFn === 'function') {
      setStatusFn({ success, message });
    } else {
      if (success) {
        console.info(message);
      } else {
        console.error(message);
      }
    }

    if (success) {
      if (isModal && typeof hideModal === 'function') {
        hideModal();
      } else {
        navigate("/accounts");
      }
    }
    return resp;
  }; // END removeAccount 

  return (<Formik
    enableReinitialize
    initialValues={initialFormValues}
    initialStatus={{ success: null, message: "" }}
    onSubmit={upsertAccount}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm, setFieldValue, status, setStatus }) => (<Form ref={formRef} className="mt-4 position-relative">
      <BootstrapForm.Group className="my-2" controlId="formGridName">
        <BootstrapForm.Label className="h6">Name</BootstrapForm.Label>
        <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="name" value={values?.name}></Field>
        {errors.name && touched.name && <div className="text-danger">{errors.name}</div>}
      </BootstrapForm.Group>

      <Row className="my-2">
        <BootstrapForm.Group as={Col} xs={6} controlId="formGridExternalID">
          <BootstrapForm.Label className="h6">External ID</BootstrapForm.Label>
          <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="external_id" value={values?.external_id}></Field>
          {errors.external_id && touched.external_id && <div className="text-danger">{errors.external_id}</div>}
        </BootstrapForm.Group>
        <BootstrapForm.Group as={Col} xs={6} controlId="formGridPhoneNumber">
          <BootstrapForm.Label className="h6">Phone</BootstrapForm.Label>
          <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="phone_number" value={values?.phone_number}></Field>
          {errors.phone_number && touched.phone_number && <div className="text-danger">{errors.phone_number}</div>}
        </BootstrapForm.Group>
      </Row>

      <BootstrapForm.Group className="my-2" controlId="formGridDescription">
        <BootstrapForm.Label className="h6">Description</BootstrapForm.Label>
        <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="description" value={values?.description}></Field>
        {errors.description && touched.description && <div className="text-danger">{errors.description}</div>}
      </BootstrapForm.Group>

      <BootstrapForm.Group className="mt-2 mb-3" controlId="formGridEnabled">
        <BootstrapForm.Label className="h6">Enabled</BootstrapForm.Label>
        <StyledGreenCheckbox className="d-block">
          <Field as={BootstrapForm.Control} type="checkbox" name="enabled" disabled={loggedInAccountID === account_id || !editable || isSubmitting} className="form-control"></Field>
        </StyledGreenCheckbox>
      </BootstrapForm.Group>

      <div className="d-flex justify-content-end">
        {editable ? <Button type="submit" className="my-3 me-2" variant="success">
          <FontAwesomeIcon icon={faFloppyDisk} size="1x" /> {account_id ? "Save" : "Create"}
        </Button> : loggedInAccountID !== account_id && isSuperAdmin && !isNewAccount && <Button className="my-3 me-2" variant="danger" onClick={(e) => removeAccount(values, e, setStatus)}>
          <FontAwesomeIcon icon={faTrashCan} size="1x" /> Disable
        </Button>}
        {!isNewAccount && <Button className="my-3" variant="primary" onClick={() => {
          editable && resetForm();
          setEditable((prevState) => !prevState);
        }}><FontAwesomeIcon icon={faPenToSquare} size="1x" /> {editable ? "Cancel Edit" : "Edit"}
        </Button>}
      </div>

      {status?.message && <BootstrapForm.Group controlId="formGridStatus">
        <Alert variant={status?.success === true ? "success" : (status?.success === false ? "danger" : "info")}>{status.message}</Alert>
      </BootstrapForm.Group>}

      {Object.keys(account || {}).length === 0 && !isNewAccount &&
        <Spinner animation="border" role="status" aria-hidden="true" className="spinner-lg position-absolute bottom-50 start-50">
          <span className="visually-hidden">Loading...</span>
        </Spinner>}
    </Form>)}
  </Formik>);
}