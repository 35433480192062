import { useState, useRef } from "react";
import { Form as BootstrapForm, Button, Spinner, ListGroup } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { StyledListNoBorder } from "../../styled/List";
import { useUserData } from "../../contexts/AuthContext";
import { UserAPI } from "../../utils/API";

export default function ChangePasswordForm({ user_id = null, closeModal = () => { }, children, ...props } = {}) {
  const { userData } = useUserData();
  let { role_name, user_id: loggedInUserID } = userData || {};
  const isSuperAdmin = role_name === 'super-admin';
  const isAdmin = role_name === 'admin';
  const hasPermissionToChangePassword = [isSuperAdmin, isAdmin].some(Boolean) || user_id === loggedInUserID;

  const initialFormValues = {
    user_id, // Pass this so formik can update whenever this values changes. NOTE: enableReinitialize={true} is required for this to work  
    new_password: "",
    retype_password: "",
  };

  const formRef = useRef(null);
  const [editable, setEditable] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState({ special: true, upper: true, number: true, eight: true, match: true });

  const updateUserPassword = async (formValues = {}) => {
    const { new_password, retype_password, user_id } = formValues || {};

    let resp;
    try {
      setEditable(false);
      if (!hasPermissionToChangePassword) {
        throw new Error("updateUserPassword Error: User does not have permission to change password");
      }
      if (new_password !== retype_password) {
        throw new Error(`Passwords do not match ${new_password} !== ${retype_password}`);
      }
      if (!user_id) {
        throw new Error("updateUserPassword Error: No user ID provided");
      }
      let params = { user_id, password: new_password };
      resp = await UserAPI.updateUser(params);
    }
    catch (error) {
      console.error(error)
    } finally {
      setEditable(true);
      if (typeof closeModal === 'function') {
        closeModal();
      }
    }

    let { status, statusText, data = {} } = resp || {};
    if (status !== 200) {
      console.log(data);
      console.error(`updateUserPassword Error: ${statusText} (${status})`);
    }
    return data;
  };

  const validatePassword = (password, retypePassword) => {
    let special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    let number = /[0-9]/;
    let uppercase = /[A-Z]/;
    let length = /.{8,}/;
    let e = { ...passwordErrors };

    // Reset passwordErrors values to false 
    Object.entries(e).forEach(([key]) => {
      e[key] = false;
    });

    e.match = !password || (password !== retypePassword);
    e.upper = !uppercase.test(password);
    e.eight = !length.test(password);
    e.special = !special.test(password);
    e.number = !number.test(password);
    setPasswordErrors(e);

    let hasErrors = Object.values(e).some(i => i);
    return hasErrors ? "Password does not meet requirements" : undefined;
  }

  return (<Formik
    enableReinitialize
    initialValues={initialFormValues}
    onSubmit={async (values, { setSubmitting }) => {
      await updateUserPassword(values);
      setSubmitting(false);
    }}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValidating, isSubmitting, resetForm, setFieldValue }) => (<Form ref={formRef} className="mt-4 position-relative" encType="multipart/form-data">
      <BootstrapForm.Group className="my-2">
        <BootstrapForm.Label className="h6">New Password</BootstrapForm.Label>
        <Field as={BootstrapForm.Control} type="password" disabled={!editable || isSubmitting} name="new_password" validate={(pw) => validatePassword(pw, values.retype_password)} required></Field>
      </BootstrapForm.Group>

      <BootstrapForm.Group className="my-2">
        <BootstrapForm.Label className="h6">Retype Password</BootstrapForm.Label>
        <Field as={BootstrapForm.Control} type="password" disabled={!editable || isSubmitting} name="retype_password" validate={(retype_pw) => validatePassword(values.new_password, retype_pw)} required></Field>
      </BootstrapForm.Group>

      <BootstrapForm.Group>
        <strong>Your password must:</strong>
        <StyledListNoBorder>
          <ListGroup.Item key={`special-${passwordErrors.special}`} className={passwordErrors.special ? 'text-danger' : 'text-success'}>{(passwordErrors.special ? "✘" : "✔")} Contains at least 1 special character.</ListGroup.Item>
          <ListGroup.Item key={`upper-${passwordErrors.upper}`} className={passwordErrors.upper ? 'text-danger' : 'text-success'}>{(passwordErrors.upper ? "✘" : "✔")} Contains at least 1 upper-case character.</ListGroup.Item>
          <ListGroup.Item key={`number-${passwordErrors.number}`} className={passwordErrors.number ? 'text-danger' : 'text-success'}>{(passwordErrors.number ? "✘" : "✔")} Contains at least 1 number.</ListGroup.Item>
          <ListGroup.Item key={`eight-${passwordErrors.eight}`} className={passwordErrors.eight ? 'text-danger' : 'text-success'}>{(passwordErrors.eight ? "✘" : "✔")} Contains at least 8 characters.</ListGroup.Item>
          <ListGroup.Item key={`match-${passwordErrors.match}`} className={passwordErrors.match ? 'text-danger' : 'text-success'}>{(passwordErrors.match ? "✘" : "✔")} Passwords match.</ListGroup.Item>
        </StyledListNoBorder>
      </BootstrapForm.Group>

      <div className="d-flex justify-content-end">
        <Button type="submit" className="my-3 me-2" variant="success" disabled={isValidating || !values?.new_password || values?.new_password !== values?.retype_password || !editable || isSubmitting} >
          <FontAwesomeIcon icon={faFloppyDisk} size="1x" />{isSubmitting ? " Saving..." : " Save"}
        </Button>

        <Button className="my-3" variant="primary" onClick={() => {
          editable && resetForm();
          setEditable((prevState) => !prevState);
        }}><FontAwesomeIcon icon={faPenToSquare} size="1x" />{editable ? " Cancel Edit" : " Edit"}
        </Button>
      </div>

      {!user_id &&
        <Spinner animation="border" role="status" aria-hidden="true" className="spinner-lg position-absolute bottom-50 start-50">
          <span className="visually-hidden">Loading...</span>
        </Spinner>}
    </Form>)}
  </Formik>);
}; 