import { Formik, Field, Form } from "formik";
import { Form as BootstrapForm, Button, Row, Col } from "react-bootstrap";
import { StyledGreenCheckbox } from "../../styled/Forms";
import { humanize } from "../../utils/";
import { REQUESTS } from "../../utils/constants";

export default function RequestsFilter({ handleFilter, handleClear, initialValues, formFields = Object.keys(REQUESTS.initialValues), children, ...props }) {
  const mapFieldsToControls = (fields = formFields) => {
    return fields.map((field, idx) => {
      let control = <Field as={BootstrapForm.Control} type={['valid_until', 'start_date', 'end_date'].includes(field) ? "date" : "text"} name={field} className="form-control-sm"></Field>;

      if (field === "completed") {
        control = <StyledGreenCheckbox className="mt-3">
          <Field as={BootstrapForm.Control} type="checkbox" name={field} className="form-control-sm"></Field>
          <span className="ms-2">{REQUESTS.labels[field] || humanize(field)}</span>
        </StyledGreenCheckbox>
      }

      return (<div key={idx} className={`col ${field === "completed" ? "pt-3" : ""}`}>
        <BootstrapForm.Group>
          {field !== "completed" && <BootstrapForm.Label>{REQUESTS.labels[field] || humanize(field)}</BootstrapForm.Label>}
          {control}
        </BootstrapForm.Group>
      </div>);
    });
  };

  return (<Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={async (values, { setSubmitting }) => {
      values = Object.fromEntries(Object.entries(values).map(([k, v]) => [k, typeof v === "string" ? v.trim() : v]));
      await handleFilter(values);
      setSubmitting(false);
    }}
  >
    {({ values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      resetForm }) => (<Form>
        <Row {...props}>
          {mapFieldsToControls(formFields)}
          <Col className="d-flex justify-content-around align-items-end">
            <Button type="submit" variant="primary" disabled={isSubmitting} className="me-2">Apply</Button>
            <Button
              type="reset"
              variant="outline-danger"
              onClick={() => {
                resetForm();
                handleSubmit();
                handleClear();
              }}
              disabled={isSubmitting}>
              Clear
            </Button>
          </Col>
        </Row>
      </Form>)}
  </Formik>);
}