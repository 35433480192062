import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import TemplateDetailsForm from "../components/forms/TemplateDetailsForm";
import { pdfAPI } from "../utils/pdf-api";
import Debug from "../components/Debug";

export default function TemplateDetails({ theme, children, ...props } = {}) {
  const { template_id } = useParams() || {};
  const [template, setTemplate] = useState({});
  const [invokeUpdate, setInvokeUpdate] = useState(false);

  const fetchTemplate = async (templateId = template_id) => {
    let response;
    try {
      response = await pdfAPI.getPDFTemplate(templateId);
    } catch (error) {
      console.error(error);
      response = error?.response || {};
      response.status = 500;
      response.statusText = `${error.message}`;
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
    }

    const { status, statusText, data = {} } = response || {};
    if (status !== 200) {
      let { err, error, details } = data || {};
      console.error(`${status}: ${statusText}`, data);
      console.error(`${err}: ${error}`, details);
      return;
    }
    if (Object.keys(data || {}).length > 0) {
      setTemplate(data); // data = { description: string, fieldDataArray: array, is_published: boolean, tag: string, tag_id: array, template_id: string, template_name: string }
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    const init = async (templateId = template_id) => {
      if (templateId) {
        await fetchTemplate(templateId);
      }
    };

    if (mounted) init(template_id);
    return () => mounted = false;
  }, [invokeUpdate, template_id]);

  return (
    <div className="page-container my-4"  {...props}>
      <Row className="row border-bottom">
        <Col>
          <h2 className="mb-3">
            {template_id ? <>
              <Link to='/templates'>
                <FontAwesomeIcon className="text-primary me-4 cursor-pointer" icon={faCaretLeft} />
              </Link>
              Template ID: <span className="h4 fw-light ms-2">{template_id}</span>
            </> : "Create New Template"}
          </h2>
        </Col>
      </Row>

      <Row>
        <Col className={`pe-3 ${template_id ? "border-end" : ""}`}>
          <TemplateDetailsForm template={template} isNewTemplate={!template_id} setInvokeParentUpdate={setInvokeUpdate} invokeParentUpdate={invokeUpdate} />
        </Col>
        {template_id && <div className="col">
          <Debug debugData={(template?.fieldDataArray || []).map(({ input_type, key, value, testValue, required: is_required } = {}) => (`${key}${value || testValue ? ": " + value || testValue : ""} [${input_type}${is_required ? ":required" : ""}]`))} debugLabel="Fields List" />
        </div>}
      </Row>
    </div>
  );
}