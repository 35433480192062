import styled from "styled-components";
import { Form, InputGroup } from 'react-bootstrap';
import Select from "react-select";

export const StyledGreenCheckbox = styled.label` 
  display: inline-flex;
  cursor: pointer;
  position: relative;

  & > .form-control:disabled,
  & > .form-control[readonly] {
    background-color: #e9ecef; 
    opacity: 1;
  }

  & > input[type="checkbox"] {
    padding: 0; 
    height: ${({ w = 31 } = {}) => w}px;
    width: ${({ w = 31 } = {}) => w}px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #c2c2c2 !important;
    border-radius: 5px;
    outline: none;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
  }

  & > input:checked::after {
    content: '\\2713';
    display: block;
    position: absolute;
    text-align: center;
    color: #41B883;
    font-weight: 900; 
    font-size: ${({ w = 31 } = {}) => w === 31 ? "25" : "20"}px;
    left: ${({ w = 31 } = {}) => w === 31 ? "0.38" : "0.2"}rem;
    top: -0.3rem;
  }

  & > input:active {
    border: 2px solid #34495E;
  }
}
`;