import chroma from "chroma-js";
import Select from "react-select";
import { colorOptions } from "../../utils/constants";
import { useField } from "formik";

const colorStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? 'var(--bs-secondary-bg)' : 'white',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const mapAddColors = (options) => {
  return options.map((option, i) => {
    return {
      ...option,
      color: colorOptions[i % colorOptions.length]
    }
  });
}

export default function MultiSelect({ options, defaultValue, isDisabled, name, ...props }) {
  const [field, state, { setValue }] = useField(name);
  const onChange = (value) => {
    setValue(value.map(item => item.value));
  }
  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      onChange={onChange}
      defaultValue={mapAddColors(defaultValue)}
      isDisabled={isDisabled}
      options={mapAddColors(options)}
      {...props}
      styles={colorStyles}
    />
  )
};
