import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import IconButton from "../common/IconButton";

const LoginForm = ({ checkingAccess = false, showResetButton = false, formFields = {}, handleFormChange = () => { }, handleSubmit = () => { }, handleResetForm = () => { } }) => {
  return (<Form className="d-flex flex-column justify-content-center align-items-center w-100 card-body p-4" onSubmit={handleSubmit} onReset={handleResetForm} >
    {Object.keys(formFields).map((field, index) => (
      <Form.Group className="w-100 mb-2" key={index} controlId={field}>
        <FloatingLabel controlId={field} label={field} size="sm">
          <Form.Control
            size="sm"
            type="text"
            name={field}
            value={formFields[field]}
            onChange={handleFormChange}
            placeholder={`Enter ${field}`}
            {...(['pin', 'password'].includes(`${field}`.toLowerCase()) && { type: "password" })}
            {...(field !== "email" && { required: true })}
          />
        </FloatingLabel>
      </Form.Group>
    ))}
    {Object.keys(formFields).length > 0 && (<Form.Group className="d-flex w-100 pt-4">
      {showResetButton && <IconButton variant="secondary" size="lg" icon="check" className="flex-fill me-4" iconAlign="left" type="reset">
        <span className="ms-1">Clear</span>
      </IconButton>}
      <IconButton variant="primary" size="lg" icon="check" className="flex-fill" iconAlign="left" type="submit">
        <span className="ms-1">{checkingAccess ? "Verifying..." : "Log In"}</span>
      </IconButton>
    </Form.Group>)}
  </Form>);
};

export default LoginForm;
