import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from "react-router-dom";
import { useUserData } from "../contexts/AuthContext";
import { Alert } from 'react-bootstrap';
import AuthCardLayout from "../layouts/AuthCardLayout";
import LoginForm from "../components/forms/LoginForm"
import { getParams } from "../utils/";
import { pdfAPI } from "../utils/pdf-api";
const DEFAULT_PAGE = process.env.REACT_APP_DEFAULT_PAGE || '/requests';

const CheckAccess = ({ theme, toggleTheme, children, ...props } = {}) => {
  let { request_id, template_id, ...params } = useParams();
  const queryURL = new URLSearchParams(window.location.search);
  const queryParams = params
  for (let [key, value] of queryURL.entries()) {
    queryParams[key] = value;
  }
  if (queryParams.template_id) {
    template_id = queryParams.template_id;
    delete queryParams.template_id;
  }

  const { userData, setUserData, verifyAccess, handleLogin } = useUserData();
  const { isAuthenticated = false, hasAccessToPage = false, referrer } = userData || {};

  const [showAlert, setShowAlert] = useState(false);
  const [pageAlert, setPageAlert] = useState({
    message: "",
    alert_type: "info",
  });
  const [checkingAccess, setCheckingAccess] = useState(false);
  const [formFields, setFormFields] = useState({});
  const [loadingFormFields, setLoadingFormFields] = useState(true);

  const handleFormChange = (event) => {
    if (!event?.target) {
      return;
    }
    let { name, value } = event.target || {};
    value = `${value}`.trim();
    setFormFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    if (!event) {
      return;
    }
    event.preventDefault();
    setCheckingAccess(true);
    let resp;
    try {
      resp = request_id ? await verifyAccess({ ...formFields, request_id }) : await handleLogin({ ...formFields, template_id, referrer });
    } catch (error) {
      console.error(`${request_id ? "verifyAccess" : "handleLogin"} Error:`, error, error?.message);
      resp = error?.response;
    } finally {
      setCheckingAccess(false);
    }

    let { isVerified = false, hasAccessToPage = null, hasAccessToPages = [], message = '', status, statusText } = resp || {};
    if (!isVerified || !hasAccessToPage) {
      if (hasAccessToPage) {
        console.log(`Could not grant access to page: ${hasAccessToPage}`);
      }

      if (!message) {
        message = "Failed to verify access";
      }
      console.error(`handleSubmit Error: ${statusText} (${status}) ${message}`);
      setShowAlert(!!message);
      setPageAlert((prev) => ({ ...prev, message, alert_type: status === 200 ? "success" : "danger" }));
    }
    if (isVerified && hasAccessToPage === "/templates" && template_id) {
      hasAccessToPage = `/templates/${template_id}`;
    }
  };

  const handleResetForm = (event) => {
    if (!event) {
      return;
    }
    event.preventDefault();
    setFormFields((prev) => {
      let formFields = {};
      Object.keys(prev).forEach(field => {
        formFields[field] = "";
      });
      return formFields;
    });
    setShowAlert(false);
    setPageAlert((prev) => ({ ...prev, message: "", alert_type: "info" }));
  };

  const loadFormFields = async (RID = request_id) => {
    setLoadingFormFields(true);
    setCheckingAccess(true);
    let tokenData = ['access_token', 'access_token_exp', 'refresh_token', 'refresh_token_exp'].reduce((acc, key) => {
      let value = (typeof localStorage !== 'undefined') ? localStorage.getItem(key)
        : (typeof window?.localStorage !== 'undefined' ? window.localStorage.getItem(key) : null);
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    let resp;
    try {
      if (RID) {
        // Verify access first before trying to load form fields 
        let verifyResp = await verifyAccess({ request_id: RID });
        if (verifyResp?.isVerified) {
          resp = { ...verifyResp };
          resp.protected_route = true;
          resp.tokenData = tokenData;
          resp.fields = []; // No fields needed if access is already verified
        } else {
          resp = await pdfAPI.getEsignRequestAuthenticationFields({ request_id: RID }, tokenData);
          if (!resp?.data?.fields?.length) { // No fields found so push the error message through from the previous response verifyResp 
            resp.data = { ...verifyResp, ...resp.data };
          }
        }
      } else {
        resp = { status: 200, statusText: "OK", data: { fields: ['email', 'password'] } };
      }
    } catch (error) {
      resp = error?.response;
      ['access_token', 'access_token_exp', 'refresh_token', 'refresh_token_exp'].forEach(key => {
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem(key);
        } else if (typeof window?.localStorage !== 'undefined') {
          window.localStorage.removeItem(key);
        }
      });
    } finally {
      setCheckingAccess(false);
      setLoadingFormFields(false);
    }

    let { status, statusText, data = {} } = resp || {};
    let { protected_route = false, message = '', fields = [], isVerified = false, hasAccessToPage = null, tokenData: returnedTokenData = {} } = data || {};
    if (protected_route !== isVerified) {
      console.log(`Protected route: ${protected_route} !== isVerified: ${isVerified}`);
    }

    if (typeof data === 'string') {
      message = data;
    } else if (data?.status && !data?.message) {
      message = data.status;
    }
    if (status === 200) {
      if (fields.length > 0) {
        let formFields = {};
        fields.forEach(field => {
          formFields[field] = "";
        });
        setFormFields(formFields);
      } else {
        setFormFields({});
        if (!message) {
          message = `No fields found for request_id: ${RID}`;
        }
        if (protected_route && isVerified && hasAccessToPage) {
          message = "Access granted. Redirecting...";
          if (Object.keys(returnedTokenData).length > 0) { // Update token data in localStorage 
            Object.entries(returnedTokenData).forEach(([key, value]) => {
              if (typeof localStorage !== 'undefined') {
                localStorage.setItem(key, value);
              } else if (typeof window?.localStorage !== 'undefined') {
                window.localStorage.setItem(key, value);
              }
            });
          }

          // Update user data in context so we can redirect to the correct page 
          setUserData(prevState => ({
            ...prevState,
            hasAccessToPage,
            isAuthenticated: isVerified,
            ...(!prevState?.referrer?.includes(hasAccessToPage) && { referrer: hasAccessToPage })
          }));
        }
      }
    } else {
      setFormFields({});
      if (!message) {
        message = `${statusText} (${status}) ${message}`;
      }
    }
    setShowAlert(!!message);
    setPageAlert((prev) => ({ ...prev, message, alert_type: "warning" }));

    // See if we need to redirect to the protected route (if it exists)...possible redirect bug if this is not here
    if (protected_route) {
      setUserData(prevState => ({
        ...prevState,
        hasAccessToPage: RID ? `/esign/${RID}` : (template_id ? `/templates/${template_id}` : referrer),
        referrer: RID ? `/esign/${RID}` : (template_id ? `/templates/${template_id}` : referrer),
      }));
    }
  };

  useEffect(() => {
    let mounted = true;
    mounted && loadFormFields(request_id);
    return () => mounted = false;
  }, []);

  if (isAuthenticated) {
    const navTo = `${referrer || hasAccessToPage || (template_id ? `/templates/${template_id}` : DEFAULT_PAGE)}${getParams(queryParams)}`;
    return <Navigate to={navTo} />;
  }

  // Show white screen when checking access
  if (loadingFormFields) {
    return null;
  }

  return (<AuthCardLayout theme={theme} >
    <div className="px-4 pt-4 text-center">
      <h3>{request_id ? "Access Document" : "Sign In"}</h3>
      {request_id && (<small>ID: {request_id}</small>)}<br />
      {request_id && Object.keys(formFields).length > 0 && (<p>Please enter the following information to verify your identity.</p>)}
    </div>
    {Object.keys(formFields).length > 0 && <LoginForm checkingAccess={checkingAccess} formFields={formFields} setFormFields={setFormFields} handleFormChange={handleFormChange} handleSubmit={handleSubmit} handleResetForm={handleResetForm} />}
    <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant={pageAlert.alert_type} size="sm" className="w-100 my-3">{pageAlert.message}</Alert>
  </AuthCardLayout>);
};

export default CheckAccess;