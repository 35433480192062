import React from "react";

const Debug = ({ debugData, debugValue, debugLabel, theme, children, ...props } = {}) => {
  if (debugValue) {
    debugData = debugValue;
  }
  if (typeof debugData === "string") {
    debugData = { "value": debugData };
  }

  if (!debugData) {
    return null;
  }
  return (
    <div {...props}>
      {children}
      {Object.keys(debugData).length > 0 && (<div className={`card ${theme === "dark" ? "bg-dark" : ""}`} id="debug">
        <div className="card-header">
          <h2>{debugLabel}</h2>
        </div>
        {(debugData !== undefined && debugLabel !== undefined) && <div className="card-body">
          <pre>{JSON.stringify((debugData || {}), null, 2)}</pre>
        </div>}
      </div>)}
    </div>
  )
}

export default Debug;
