import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form as BootstrapForm, Button, Spinner, ListGroup, Row, Col, Alert } from "react-bootstrap";
import { Formik, Field, Form, FieldArray, useFormik } from "formik";
import { useUserData } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faFloppyDisk, faTrashCan, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import ResetPasswordModal from "../Modals/ResetPasswordModal";
import { StyledGreenCheckbox } from "../../styled/Forms";
import { StyledListNoBorder } from "../../styled/List";
import { UserAPI, AccountAPI } from "../../utils/API";
import { generateRandomNumber } from "../../utils/";
import { AxiosError } from 'axios';

export default function UserDetailsForm({ theme = "light", user = null, isNewUser = false, isModal = false, hideModal, setInvokeParentUpdate = () => { }, invokeParentUpdate, children, ...props } = {}) {
  const navigate = useNavigate();
  const { userData } = useUserData();
  let { role_name, user_id: loggedInUserID, account_id: loggedInAccountID } = userData || {};
  const isAdmin = role_name === 'admin';
  const isSuperAdmin = role_name === 'super-admin';

  // user = { user_id, role_id, account_id, email, name, phone_number, enabled, account_name, account_external_id, account_phone_number, account_description, role_name } 
  const { user_id, enabled: initialEnabled = false, ...initialUserValues } = user || {};
  const initialFormValues = {
    user_id, // Pass this so formik can update whenever this values changes. NOTE: enableReinitialize={true} is required for this to work  
    enabled: typeof initialEnabled === 'string' ? `${initialEnabled}`.toLowerCase() === 'true' : initialEnabled || false,
    ...initialUserValues,
    ...(!initialUserValues?.role_name && role_name && { role_name }),
  };

  const formRef = useRef(null);
  const [editable, setEditable] = useState(isNewUser);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  // const [pwModalState, setPwModalState] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({ special: true, upper: true, number: true, eight: true, match: true });
  const [showPassword, setShowPassword] = useState(false);

  // API call functions 
  const fetchAvailableRoles = async () => {
    let response; // { status, statusText, data } 
    try {
      response = await UserAPI.getUserRoles();
    }
    catch (error) {
      if (error instanceof AxiosError) {
        let { status, statusText, data = {} } = error?.response || {};
        response = { status, statusText, data };
      } else {
        response = { status: 400, statusText: error?.message, data: { err: 400, error: error?.error, details: `${error?.message}` } }
      }
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
      console.error("getUserRoles Error:", response);
    }

    const { status, statusText, data = {} } = response || {};
    let { rows = [], count = 0, limit = 500, page = 1, pageCount = 1, pages = [1] } = data || {};
    if (status > 399) {
      let { err, error, details } = data || {};
      console.error(`${statusText} (${status})`, data);
      console.error(`${err}: ${error}`, details);
      setAvailableRoles([]);
      return;
    }
    if (Array.isArray(rows)) {
      if (!isSuperAdmin && !isAdmin) {
        rows = rows.filter(({ role_name }) => ['super-admin', 'admin'].includes(role_name));
      } else if (!isSuperAdmin && isAdmin) {
        rows = rows.filter(({ role_name }) => role_name !== 'super-admin');
      }
      setAvailableRoles(rows);
    }
    return;
  }; // END fetchAvailableRoles 

  const fetchAllAccounts = async () => {
    let response; // { status, statusText, data } 
    try {
      if (isSuperAdmin) {
        response = await AccountAPI.getAllAccounts();
      } else {
        if (!loggedInAccountID) {
          throw new Error("No account ID provided");
        }
        // Get the account for the user
        let { status, statusText, data: row = {} } = await AccountAPI.getAccount({ account_id: loggedInAccountID });
        response = { status, statusText, data: { rows: [row], count: 1, limit: 1, page: 1, pageCount: 1, pages: [1] } };
      }
    }
    catch (error) {
      if (error instanceof AxiosError) {
        let { status, statusText, data = {} } = error?.response || {};
        response = { status, statusText, data };
      } else {
        response = { status: 400, statusText: error?.message, data: { err: 400, error: error?.error, details: `${error?.message}` } }
      }
      if (!response?.data) {
        response.data = { err: 401, error: error.error, details: `${error.message}` };
      }
      console.error("getAllAccounts Error:", response);
    }

    const { status, statusText, data = {} } = response || {};
    let { rows = [], count = 0, limit = 500, page = 1, pageCount = 1, pages = [1] } = data || {};
    if (status > 399) {
      let { err, error, details } = data || {};
      console.error(`${statusText} (${status})`, data);
      console.error(`${err}: ${error}`, details);
      setAllAccounts([]);
      return;
    }
    if (Array.isArray(rows)) {
      setAllAccounts(rows);
    }
    return;
  }; // END fetchAllAccounts 

  const upsertUser = async (formValues = {}, FormikBag = {}) => {
    const { setFormikState, setSubmitting } = FormikBag || {};
    let { apikey, password, new_password, retype_password, ...payload } = formValues || {};
    if (!payload?.user_id && user_id) {
      payload.user_id = user_id;
    }

    let resp;
    try {
      setEditable(false);
      if (!payload?.name) {
        throw new Error("Name is required");
      }
      if (isNewUser) {
        // Make sure password is set 
        if (!new_password) {
          new_password = generatePassword();
          retype_password = new_password;
        }
        const pwHasErrors = validatePassword(new_password, retype_password); // Returns undefined if no errors
        if (pwHasErrors) {
          throw new Error(pwHasErrors);
        }
        payload.password = new_password;

        // Make sure apikey is set 
        if (!apikey) {
          apikey = generateAPIKey();
        }
        payload.apikey = apikey;
        resp = await UserAPI.newUser(payload);
      } else {
        if (!payload.user_id) {
          throw new Error("No user ID provided");
        }
        resp = await UserAPI.updateUser(payload);
      }
    }
    catch (error) {
      if (error instanceof AxiosError) {
        let { response = {} } = error || {};
        let { status, statusText, data = {} } = response || {};
        resp = { status, statusText, data };
        console.error("upsertUser AxiosError:", resp);
      } else {
        resp = { status: 400, statusText: error?.message, data: error };
        console.error("upsertUser Error:", resp);
      }
    } finally {
      // setSubmitting(false); // If your onSubmit function is synchronous, then you need to call setSubmitting(false) on your own.
    }

    let { status, statusText, data = {} } = resp || {};
    let message = "", success = false;
    if (status === 200) {
      success = true;
      message = `User ${isNewUser ? "created" : "updated"} successfully! ${statusText} (${status})`;
    } else {
      message = `User ${isNewUser ? "creation" : "update"} failed! ${data?.message ? data.message + ". " : ""}${statusText} (${status})`;
    }
    if (typeof setFormikState === 'function') {
      setFormikState((prev) => ({ ...prev, status: { success, message } }));
    } else {
      if (success) {
        console.info(message);
      } else {
        console.error(message);
      }
    }

    let timeoutSeconds = 2;
    return await new Promise(resolve => setTimeout(() => {
      if (success && isModal && typeof hideModal === 'function') {
        hideModal();
      }
      if (!isNewUser && typeof setInvokeParentUpdate === 'function') {
        setInvokeParentUpdate((prevState) => !prevState);
      }
      return resolve(resp);
    }, timeoutSeconds * 1000));
  }; // END updateUser

  const removeUser = async ({ user_id: userID } = {}, event, setStatusFn) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!userID && user_id) {
      userID = user_id;
    }

    let resp;
    try {
      if (!userID) {
        throw new Error("No user ID provided");
      }

      resp = await UserAPI.updateUser({ user_id: userID, enabled: false });
      // resp = await UserAPI.removeUser(userID); // This will delete the user from the database! 
    }
    catch (error) {
      if (error instanceof AxiosError) {
        let { response = {} } = error || {};
        let { status, statusText, data = {} } = response || {};
        resp = { status, statusText, data };
        console.error("removeUser AxiosError:", resp);
      } else {
        resp = { status: 400, statusText: error?.message, data: error };
        console.error("removeUser Error:", resp);
      }
    } finally {
      setInvokeParentUpdate((prevState) => !prevState);
    }

    let { status, statusText, data = {} } = resp || {};
    let message = "", success = false;
    if (status === 200) {
      success = true;
      message = `User removed successfully! ${statusText} (${status})`;
    } else {
      message = `User removal failed! ${data?.message ? data.message + ". " : ""}${statusText} (${status})`;
    }
    if (typeof setStatusFn === 'function') {
      setStatusFn({ success, message });
    } else {
      if (success) {
        console.info(message);
      } else {
        console.error(message);
      }
    }

    if (success) {
      if (isModal && typeof hideModal === 'function') {
        hideModal();
      } else {
        navigate("/users");
      }
    }
    return resp;
  }; // END removeUser 

  const generateAPIKey = async (e, cb) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    let newValue;
    try {
      const { error: err, apikey } = await UserAPI.generateAPIKey({ method: 'string' });
      if (err) {
        throw new Error(err);
      }
      if (!apikey) {
        throw new Error("No API Key generated");
      }
      newValue = apikey;
    } catch (error) {
      console.error(error);
      console.error("Failed to generate API Key");
      newValue = null;
      return;
    }

    if (typeof cb === 'function') {
      await cb('apikey', newValue);
    }
    return newValue;
  }; // END generateAPIKey 

  const generatePassword = async (e, cb) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const hasLength = generateRandomNumber(8, 20);
    const countCapitals = generateRandomNumber(1, 3);
    const countSpecials = generateRandomNumber(1, 3);
    const params = { hasLength, countCapitals, countSpecials, hasUpper: true, hasSpecial: true, possibleForwardSlash: false, possibleBackSlash: false, method: 'crypto', showDebug: false };
    let newValue;
    try {
      const { error: err, password } = await UserAPI.generatePassword(params);
      if (err) {
        throw new Error(err);
      }
      if (!password) {
        throw new Error("No password generated");
      }
      newValue = password;
    } catch (error) {
      console.error(error);
      console.error("Failed to generate password");
      newValue = null;
      return;
    }

    if (typeof cb === 'function') {
      await cb('new_password', newValue);
      await cb('retype_password', newValue);
      validatePassword(newValue, newValue);
    }
    return newValue;
  }; // END generatePassword

  const validatePassword = (password, retypePassword) => {
    let special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    let number = /[0-9]/;
    let uppercase = /[A-Z]/;
    let length = /.{8,}/;
    let e = { ...passwordErrors };

    // Reset passwordErrors values to false 
    Object.entries(e).forEach(([key]) => {
      e[key] = false;
    });

    e.match = !password || (password !== retypePassword);
    e.upper = !uppercase.test(password);
    e.eight = !length.test(password);
    e.special = !special.test(password);
    e.number = !number.test(password);
    setPasswordErrors(e);

    let hasErrors = Object.values(e).some(i => i);
    return hasErrors ? "Password does not meet requirements" : undefined;
  }; // END validatePassword

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      if (editable) {
        await fetchAllAccounts(); // Not really getting all accounts only isSuperAdmin can get all accounts 
        if (isSuperAdmin || isAdmin) {
          await fetchAvailableRoles();
        }
      }
    };
    if (mounted) init();
    return () => mounted = false;
  }, [editable]);

  return (<Formik
    enableReinitialize
    initialValues={initialFormValues}
    onSubmit={upsertUser}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm, setFieldValue, status, setStatus }) => (<Form ref={formRef} className="mt-4 position-relative">
      <BootstrapForm.Group className="my-2" controlId="formGridName">
        <BootstrapForm.Label className="h6">Name</BootstrapForm.Label>
        <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="name"></Field>
        {errors.name && touched.name && <div className="text-danger">{errors.name}</div>}
      </BootstrapForm.Group>

      <Row className="my-2">
        <BootstrapForm.Group as={Col} controlId="formGridEmail">
          <BootstrapForm.Label className="h6">Email</BootstrapForm.Label>
          <Field as={BootstrapForm.Control} type="email" disabled={!editable || isSubmitting} name="email"></Field>
          {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
        </BootstrapForm.Group>

        <BootstrapForm.Group as={Col} controlId="formGridPhone">
          <BootstrapForm.Label className="h6">Phone</BootstrapForm.Label>
          <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="phone_number"></Field>
          {errors.phone_number && touched.phone_number && <div className="text-danger">{errors.phone_number}</div>}
        </BootstrapForm.Group>
      </Row>

      <Row className="my-2">
        <BootstrapForm.Group as={Col} controlId="formGridRoles">
          <BootstrapForm.Label className="h6">Roles</BootstrapForm.Label>
          {editable && loggedInUserID !== user_id && (isSuperAdmin || isAdmin) ? <BootstrapForm.Select as="select" name="role_id" value={values?.role_id || availableRoles[availableRoles.length - 1]?.role_id} onChange={handleChange} onBlur={handleBlur} isdisabled={`${loggedInUserID === user_id || (!isSuperAdmin && !isAdmin) || !editable || isSubmitting}`}>
            {availableRoles.map((role, idx) => <option key={idx} value={role.role_id}>{role.role_name}</option>)}
          </BootstrapForm.Select> : <Field as={BootstrapForm.Control} type="text" disabled={loggedInUserID === user_id || (!isSuperAdmin && !isAdmin) || !editable || isSubmitting} name="role_name" value={values?.role_name} />}
        </BootstrapForm.Group>
        <BootstrapForm.Group as={Col} controlId="formGridAccount">
          <BootstrapForm.Label className="h6">Account</BootstrapForm.Label>
          {editable ? <BootstrapForm.Select as="select" name="account_id" value={values?.account_id} onChange={handleChange} onBlur={handleBlur} isdisabled={`${!editable || isSubmitting}`}>
            {allAccounts.map((account, idx) => <option key={idx} value={account.account_id}>{account.name}</option>)}
          </BootstrapForm.Select> : <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="account_name" value={values?.account_name} />}
        </BootstrapForm.Group>
      </Row>

      <Row className="my-2">
        <BootstrapForm.Group as={Col} xs={isNewUser ? 10 : 12} sm={isNewUser ? 11 : 12} controlId="formGridApiKey">
          <BootstrapForm.Label className="h6">API Key</BootstrapForm.Label>
          <Field as={BootstrapForm.Control} type="text" disabled={true} name="apikey"></Field>
          {errors.apikey && touched.apikey && <div className="text-danger">{errors.apikey}</div>}
        </BootstrapForm.Group>
        {isNewUser && <BootstrapForm.Group as={Col} xs={2} sm={1} className="d-flex justify-content-end">
          <Button type="button" className="mt-4" variant="primary" onClick={(e) => generateAPIKey(e, setFieldValue)}>
            <FontAwesomeIcon icon={faArrowsRotate} size="1x" />
          </Button>
        </BootstrapForm.Group>}
      </Row>

      {isNewUser && <BootstrapForm.Group className="my-2" controlId="formGridNewPassword">
        <BootstrapForm.Label className="h6">New Password</BootstrapForm.Label>
        <Field as={BootstrapForm.Control} type={showPassword ? "text" : "password"} disabled={!editable || isSubmitting} name="new_password" validate={(pw) => validatePassword(pw, values.retype_password)} required></Field>
        {errors.new_password && touched.new_password && <div className="text-danger">{errors.new_password}</div>}
      </BootstrapForm.Group>}

      {isNewUser && <Row className="my-2">
        <BootstrapForm.Group as={Col} xs={8} sm={10} controlId="formGridRetypePassword">
          <BootstrapForm.Label className="h6">Retype Password</BootstrapForm.Label>
          <Field as={BootstrapForm.Control} type={showPassword ? "text" : "password"} disabled={!editable || isSubmitting} name="retype_password" validate={(retype_pw) => validatePassword(values.new_password, retype_pw)} required></Field>
        </BootstrapForm.Group>
        <BootstrapForm.Group as={Col} xs={4} sm={2} className="d-flex justify-content-end" controlId="formGridPasswordButtons">
          <Button type="button" className="mt-4 me-2" variant="primary" onClick={(e) => {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            setShowPassword((prevState) => !prevState);
          }}>
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" />
          </Button>
          <Button type="button" className="mt-4" variant="primary" onClick={(e) => generatePassword(e, setFieldValue)}>
            <FontAwesomeIcon icon={faArrowsRotate} size="1x" />
          </Button>
        </BootstrapForm.Group>
        {touched.new_password && <BootstrapForm.Group as={Col} xs={12} className="my-2" controlID="formGridPasswordErrors" >
          <strong>Your password must:</strong>
          <StyledListNoBorder>
            <ListGroup.Item key={`special-${passwordErrors.special}`} className={passwordErrors.special ? 'text-danger' : 'text-success'}>{(passwordErrors.special ? "✘" : "✔")} Contains at least 1 special character.</ListGroup.Item>
            <ListGroup.Item key={`upper-${passwordErrors.upper}`} className={passwordErrors.upper ? 'text-danger' : 'text-success'}>{(passwordErrors.upper ? "✘" : "✔")} Contains at least 1 upper-case character.</ListGroup.Item>
            <ListGroup.Item key={`number-${passwordErrors.number}`} className={passwordErrors.number ? 'text-danger' : 'text-success'}>{(passwordErrors.number ? "✘" : "✔")} Contains at least 1 number.</ListGroup.Item>
            <ListGroup.Item key={`eight-${passwordErrors.eight}`} className={passwordErrors.eight ? 'text-danger' : 'text-success'}>{(passwordErrors.eight ? "✘" : "✔")} Contains at least 8 characters.</ListGroup.Item>
            <ListGroup.Item key={`match-${passwordErrors.match}`} className={passwordErrors.match ? 'text-danger' : 'text-success'}>{(passwordErrors.match ? "✘" : "✔")} Passwords match.</ListGroup.Item>
          </StyledListNoBorder>
        </BootstrapForm.Group>}
      </Row>}

      <BootstrapForm.Group className="my-2" controlId="formGridEnabled">
        <BootstrapForm.Label className="h6">Enabled</BootstrapForm.Label>
        <StyledGreenCheckbox className="d-block">
          <Field as={BootstrapForm.Control} type="checkbox" name="enabled" disabled={loggedInUserID === user_id || !editable || isSubmitting} className="form-control"></Field>
        </StyledGreenCheckbox>
      </BootstrapForm.Group>

      <div className="d-flex justify-content-end">
        {!isNewUser && <ResetPasswordModal user_id={user_id} className="m-3" />}
        {editable ? <Button type="submit" className={`my-3 ${loggedInUserID !== user_id && isSuperAdmin ? "" : "me-3"}`} variant="success">
          <FontAwesomeIcon icon={faFloppyDisk} size="1x" /> {user_id ? "Save" : "Create"}
        </Button> : loggedInUserID !== user_id && isSuperAdmin && <Button className="my-3" variant="danger" onClick={(e) => removeUser(values, e, setStatus)}>
          <FontAwesomeIcon icon={faTrashCan} size="1x" /> Disable
        </Button>}
        {!isNewUser && <Button className={`my-3 ${loggedInUserID !== user_id && isSuperAdmin ? "ms-3" : ""}`} variant="primary" onClick={() => {
          editable && resetForm();
          setEditable((prevState) => !prevState);
        }}><FontAwesomeIcon icon={faPenToSquare} size="1x" /> {editable ? "Cancel Edit" : "Edit"}
        </Button>}
      </div>

      {status?.message && <BootstrapForm.Group controlId="formGridStatus">
        <Alert variant={status?.success === true ? "success" : (status?.success === false ? "danger" : "info")}>{status.message}</Alert>
      </BootstrapForm.Group>}

      {Object.keys(user || {}).length === 0 && !isNewUser &&
        <Spinner animation="border" role="status" aria-hidden="true" className="spinner-lg position-absolute bottom-50 start-50">
          <span className="visually-hidden">Loading...</span>
        </Spinner>}
    </Form>)}
  </Formik>);
}