import { Formik, Field, Form } from "formik";
import { Form as BootstrapForm, Button } from "react-bootstrap";

export default function TagsFilter({ handleFilter, handleClear, initialValues, ...props }) {

    return (<Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
            await handleFilter(values); // Calls fetchTags(values)
            setSubmitting(false);
        }}
    >
        {({ values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm }) => (<Form>
                <div className="row" {...props}>
                    <div className="col-3">
                        <BootstrapForm.Group>
                            <BootstrapForm.Label>Tag ID</BootstrapForm.Label>
                            <Field as={BootstrapForm.Control} type="text" name="tag_id" className="form-control-sm"></Field>
                        </BootstrapForm.Group>
                    </div>
                    <div className="col-3">
                        <BootstrapForm.Group>
                            <BootstrapForm.Label>Title</BootstrapForm.Label>
                            <Field as={BootstrapForm.Control} type="text" name="title" className="form-control-sm"></Field>
                        </BootstrapForm.Group>
                    </div>
                    <div className="col d-flex justify-content-end align-items-end">
                        <Button className="mx-3" type="submit" variant="primary" size="sm" disabled={isSubmitting}>Apply Filters</Button>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                                resetForm();
                                handleSubmit();
                                handleClear();
                            }}
                            disabled={isSubmitting}>
                            Clear Filters
                        </Button>
                    </div>
                </div>
            </Form>)}
    </Formik>);
}