import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import WebViewer from '@pdftron/pdfjs-express';
import Swal from 'sweetalert2';
import { asyncForEach, asyncMap, isObject, sleep, humanize, renameKeys, getImageSize } from "../../utils";
const SHOW_LOG = process.env.REACT_APP_SHOW_LOG === "true";
const PDFJS_LICENSE_KEY = process.env.REACT_APP_PDFJS_LICENSE_KEY || 'BKsKm8SUj14LaRqHXEFV'; // Plus License Key for esign.assure.app 
const defaultHeaderButtons = ['resetButton', 'infoButton', 'printButton', 'downloadZipButton', 'downloadPdfButton', 'sendReminderEmailButton', 'menuButton', 'moreButton'];
const emptySignatureValues = [
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAScAAABPCAYAAACpigPCAAAAAXNSR0IArs4c6QAAAltJREFUeF7t1EENAAAMArHh3/Rs3KNTQDrCzhEgQCAosGAmkQgQIHDGSQkIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAsYp+RahCBAwTjpAgEBSwDgl3yIUAQLGSQcIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAsYp+RahCBAwTjpAgEBSwDgl3yIUAQLGSQcIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAsYp+RahCBAwTjpAgEBSwDgl3yIUAQLGSQcIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAsYp+RahCBAwTjpAgEBSwDgl3yIUAQLGSQcIEEgKGKfkW4QiQMA46QABAkkB45R8i1AECBgnHSBAIClgnJJvEYoAAeOkAwQIJAWMU/ItQhEgYJx0gACBpIBxSr5FKAIEjJMOECCQFDBOybcIRYCAcdIBAgSSAg9h0QBQLgfClwAAAABJRU5ErkJggg==",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAL4AAAAvCAYAAABdTnB7AAAAAXNSR0IArs4c6QAAAPFJREFUeF7t0gEBAAAIgzDfv7RBmA2O2zkFggUW3GyyAgc+BMkC4CffbjT4DCQLgJ98u9HgM5AsAH7y7UaDz0CyAPjJtxsNPgPJAuAn3240+AwkC4CffLvR4DOQLAB+8u1Gg89AsgD4ybcbDT4DyQLgJ99uNPgMJAuAn3y70eAzkCwAfvLtRoPPQLIA+Mm3Gw0+A8kC4CffbjT4DCQLgJ98u9HgM5AsAH7y7UaDz0CyAPjJtxsNPgPJAuAn3240+AwkC4CffLvR4DOQLAB+8u1Gg89AsgD4ybcbDT4DyQLgJ99uNPgMJAuAn3y70eAzkCzwpaIAMEt7zWwAAAAASUVORK5CYII=",
];

const PDFJSWebViewer = ({ getPdfStream = async () => { }, captureCallback = async () => { },
  pdfFilename = 'myfile.pdf', xfdfInitialState = '', setWarningMessages = () => { }, setWarningMessageType = () => { }, headerButtons = defaultHeaderButtons,
  toggleResetFromParent = false, documentLoading = true, setDocumentLoading = () => { }, onFieldChangeCallBack = async () => { }, parentCaptureTrigger = false,
  isPreviewOnly = false, theme = 'light', ...props
} = {}) => {
  const navigate = useNavigate();

  if (!pdfFilename || !pdfFilename.endsWith(".pdf")) {
    pdfFilename = pdfFilename ? pdfFilename + ".pdf" : "myfile.pdf";
  }
  if (!Array.isArray(headerButtons)) {
    headerButtons = defaultHeaderButtons;
  }
  headerButtons.map((btn) => !`${btn}`.toLowerCase().includes("button") ? `${btn}Button` : btn); // add 'Button' to the end of each button name if it doesn't already have it

  const viewerRef = useRef(null);
  const instanceRef = useRef(null);
  const [toggleReset, setToggleReset] = useState(true);

  /**
  * generateAnnotationImage
  * @param {*} annotation - Annotation object
  * @param {integer} paddingSize - Padding size around the annotation
  * @param {*} instance - WebViewer instance
  * @param {integer} stackMultiplier - How many times to stack the image 
  * @returns {string} - Data URL of the generated image
  */
  const generateAnnotationImage = (annotation, paddingSize = 0, instance = instanceRef.current, stackMultiplier = 4) => {
    if (!annotation) {
      setWarningMessageType("danger");
      setWarningMessages((prev) => [...prev, "generateAnnotationImage: annotation is null"]);
      return null;
    }
    if (isNaN(paddingSize) || paddingSize < 0) {
      paddingSize = 0;
    }
    const canvas = document.createElement('canvas');
    canvas.width = annotation.Width + (2 * paddingSize);
    canvas.height = annotation.Height + (2 * paddingSize);

    // Set the canvas context to the annotation's matrix 
    const ctx = canvas.getContext('2d');
    ctx.translate(-annotation.X + paddingSize, -annotation.Y + paddingSize);
    ctx.imageSmoothingEnabled = true;

    // Draw the annotation signature on the canvas 
    const pageMatrix = instance.docViewer.getDocument().getPageMatrix(annotation.PageNumber);
    annotation.draw(ctx, pageMatrix);

    // Draw the image on the final canvas multiple times for better quality 
    if (stackMultiplier > 1) {
      // stackMultiplier - 1 because the first image is already drawn on the canvas (ctx) above 
      const iterationLoop = [...Array(stackMultiplier - 1).keys()]; // [0, 1]
      iterationLoop.forEach((i) => {
        ctx.drawImage(canvas, 0, 0);
      });
    }

    return canvas.toDataURL("image/png");
  }; // END generateAnnotationImage 

  /**
   * generateTextImage
   * @param {string} textString 
   * @param {object} options - { fontSize, fontFamily, paddingSize, width, height } 
   * @returns {string} - Data URL of the generated image 
   */
  const generateTextImage = (textString = '', options = {}) => {
    if (!textString) {
      return null;
    }
    let { fontSize = 12, fontFamily = 'Arial', paddingSize = 10, width, height } = options || {};
    if (isNaN(fontSize) || fontSize < 0) {
      fontSize = 12;
    }
    if (isNaN(paddingSize) || paddingSize < 0) {
      paddingSize = 10;
    }

    const canvas = document.createElement('canvas');
    if (!isNaN(width)) {
      canvas.width = width;
    }
    if (!isNaN(height)) {
      canvas.height = height;
    }

    const ctx = canvas.getContext('2d');
    ctx.font = `${fontSize}px ${fontFamily}`;
    let x = paddingSize, y = fontSize + paddingSize;

    // Create new line every 2 or 3 words 
    let words = textString.split(' ');
    let line = '', h = y;
    for (let n = 0; n < words.length; n += 2) {
      if (words[n] === undefined) {
        break; // Exit loop if no more words 
      }
      if (n > 0) {
        h += Math.round(fontSize * 115) / 100;
      }

      line = words[n]; // Add first word 
      if (words[n + 1] !== undefined) {
        line += ' ' + words[n + 1]; // Add second word 
      }
      if (words[n + 1] !== 'signed' && line.length < 16 && words[n + 2] !== undefined) {
        line += ' ' + words[n + 2]; // Add third word
        n++;
      }

      ctx.fillText(line, x, h);
    }

    return canvas.toDataURL("image/png");
  }; // END generateTextImage 

  /**
   * captureFields
   * @param {*} viewerInstance 
   * @param {function} cb 
   * @param {object} params 
   * @returns 
   */
  const captureFields = async (viewerInstance = instanceRef.current, cb = captureCallback, params = {}) => {
    let { return_type = "pdf", pdf_filename = pdfFilename, scrollToMissingField = false, ...cbParams } = params || {};
    (typeof scrollToMissingField === 'string') && (scrollToMissingField = scrollToMissingField.toLowerCase() === 'true');
    if (!viewerInstance) {
      setWarningMessageType("danger");
      setWarningMessages((prev) => [...prev, "captureFields: viewerInstance is null"]);
      return { ok: false, extractedFields: {}, xfdfString: '', missingRequiredFieldsArray: [] };
    }

    const { Core, Annotations } = viewerInstance || {};
    const { annotationManager, documentViewer } = Core || {};
    if (!annotationManager) {
      setWarningMessageType("danger");
      setWarningMessages((prev) => [...prev, "captureFields: annotationManager is null"]);
      return { ok: false, extractedFields: {}, xfdfString: '', missingRequiredFieldsArray: [] };
    }

    const xfdfString = await annotationManager.exportAnnotations();
    const annots = await annotationManager.getAnnotationsList();
    let renameTypeFields = {
      Tx: 'text',
      Btn: 'button',
      Ch: 'checkbox',
      Sig: 'signature',
    };

    let fieldStack = [];
    const fieldManager = await annotationManager.getFieldManager();
    fieldManager.forEachField(field => {
      fieldStack.push(field);
    });

    let extractedFields = {};
    let missingRequiredFieldsArray = [];
    while (fieldStack.length > 0) {
      const field = fieldStack.pop();
      const key = field.name || field.rd;
      const input_type = field.type in renameTypeFields ? renameTypeFields[field.type] : field.type || field.Ar;
      const is_required = field?.flags?.get('Required');
      const PageNumber = annots.find((a) => a?.Qa?.rd === key)?.PageNumber;

      if (field?.Ve[0] && field.Ve[0] instanceof Annotations.SignatureWidgetAnnotation) {
        let base64 = null;
        annots.forEach(async (annot) => {
          const annoteName = annot?.Qa?.rd || annot?.name;
          if (Object.keys(extractedFields).includes(annoteName)) {
            return; // Skip fields we already captured 
          }

          if (annoteName === key) { // Match signature field with signature annotation to extract the base64 image 
            if (annot?.Bz?.ToolName === "AnnotationCreateFreeHand") {
              base64 = generateAnnotationImage(annot?.Bz || [], 0, viewerInstance, 4);
              extractedFields[key] = base64;
            } else if (annot?.Bz?.ToolName === "AnnotationCreateRubberStamp") {
              base64 = annot?.Bz?.image.src;
            } else if (annot?.ToolName === "AnnotationCreateRubberStamp") {
              base64 = annot?.image?.currentSrc;
            }

            extractedFields[key] = {
              key,
              value: base64,
              input_type,
              page: annot?.Bz?.PageNumber || PageNumber,
              rect: {
                x: annot?.Bz?.X,
                y: annot?.Bz?.Y,
                width: annot?.Bz?.Width,
                height: annot?.Bz?.Height,
              },
            };
          } else if ((annot?.Subject === 'Stamp' || annot?.elementName === 'stamp') && annot?.ToolName === "AnnotationCreateRubberStamp") { // Capture all stamp annotations 
            base64 = annot?.image?.currentSrc;
            extractedFields[annoteName] = {
              key: annoteName,
              value: base64 || annot?.rj?.digitallySignedByStamp,
              input_type: annot?.elementName || 'stamp',
              page: annot?.PageNumber,
              rect: {
                x: annot?.X,
                y: annot?.Y,
                width: annot?.Width,
                height: annot?.Height,
              },
            };
          }
        }); // END annots.forEach
      } else { // All other fields
        let value = field.value || field.getValue();
        extractedFields[key] = {
          key,
          value: ['checkbox', 'button'].includes(input_type) && is_required && ['Off', '0'].includes(value) ? '' : value, // Convert 'Off' or '0' to empty string for checkboxes and buttons
          input_type,
          page: PageNumber,
        };
      }

      if (is_required && !extractedFields[key].value) {
        missingRequiredFieldsArray.push(key);
      }
    } // END while fieldStack.length > 0 

    if (typeof cb === "function") {
      await cb({ fields: extractedFields, xfdf: xfdfString, return_type, pdf_filename, missingRequiredFieldsArray, ...cbParams });
      if (cbParams.request_id) {
        setToggleReset((prev) => !prev);
      }
    } else { // Info Button 
      let flatFields = Object.entries(extractedFields)
        .map(([key, valueObj]) => ({ [key || valueObj.key]: valueObj.value }))
        .filter((obj) => Object.values(obj)[0]); // Remove empty values 

      SHOW_LOG && console.log("captureFields", flatFields);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: missingRequiredFieldsArray.length === 0 ? "btn btn-lg btn-success" : "btn btn-lg btn-warning",
          cancelButton: missingRequiredFieldsArray.length === 0 ? "btn btn-lg btn-danger" : "btn btn-lg btn-secondary"
        },
        buttonsStyling: false
      });
      await swalWithBootstrapButtons.fire({
        title: `${flatFields.length} Fields Captured`,
        html: `<div class="list-group list-group-flush>
          <div class="list-group-item list-group-item-info">${pdfFilename}</div>
          ${flatFields.map((obj) =>
          Object.entries(obj).map(([k, v]) => v.includes(';base64,') ? `<span>${k}<span><img src="${v}" alt="${k}" />` : `${k}: ${v}`)
        ).flat().map((listItem, idx) =>
          `<div class="list-group-item list-group-item-info">${listItem}</div>`).join("")}
          ${missingRequiredFieldsArray.length === 0 ? '' : `<div class="list-group-item list-group-item-danger">Missing Required Fields: ${missingRequiredFieldsArray.join(', ')}</div>`}
        </div>`,
        icon: 'info',
        confirmButtonText: 'Ok'
      });
    }

    // Auto Scroll to page of the first missing required field 
    if (scrollToMissingField && documentViewer && missingRequiredFieldsArray.length > 0) {
      const missingFieldOnPage = annots.find((a) => a?.Qa?.rd === missingRequiredFieldsArray[0])?.PageNumber;
      if (missingFieldOnPage) {
        await documentViewer.setCurrentPage(missingFieldOnPage); // NOTE: When adding true as second parameter for smooth scrolling then it doesn't scroll to the page
      }
    }

    return { ok: true, extractedFields, xfdfString, missingRequiredFieldsArray };
  }; // END captureFields 

  /**
   * updateButtonAFImages
   * @param {object} params - { key, value, digitallySignedByStamp, cb } 
   * @param {object} viewerInstance - WebViewer instance 
   * @returns {object} - { key, value, digitallySignedByStamp, results }
   */
  const updateButtonAFImages = async ({ key, value, rect = {}, page, digitallySignedByStamp = '', cb } = {}, viewerInstance = instanceRef.current) => {
    if (!key) {
      console.error("updateButtonAFImages: key is required");
      return { key, value, digitallySignedByStamp, results: [] };
    }
    if (!value) {
      console.error("updateButtonAFImages: value is required");
      return { key, value, digitallySignedByStamp, results: [] };
    }
    const { Core, Annotations } = viewerInstance || {};
    const { annotationManager } = Core || {};
    if (!annotationManager) {
      console.error("updateButtonAFImages: annotationManager is null");
      return { key, value, digitallySignedByStamp, results: [] };
    }

    const annots = await annotationManager.getAnnotationsList();
    const renameTypeFields = {
      Tx: 'text',
      Btn: 'button',
      Ch: 'checkbox',
      Sig: 'signature',
    };

    let fieldStack = [];
    const fieldManager = await annotationManager.getFieldManager();
    fieldManager.forEachField(field => {
      fieldStack.push(field);
    });

    let results = [], foundAFImage = false;
    while (fieldStack.length > 0) {
      const field = fieldStack.pop();
      const fieldKey = field.name || field.rd;
      const input_type = field.type in renameTypeFields ? renameTypeFields[field.type] : field.type || field.Ar;
      if (fieldKey === `${key}_af_image`) {
        foundAFImage = true;
      }

      // Add digitally signed by stamp to signature fields
      const isFullSignatureField = !`${key}`.toLowerCase().includes("initials"); // We don't care about initial fields like 'initials' 
      if (isFullSignatureField && digitallySignedByStamp && fieldKey === key) {
        const fontSize = 10, fontFamily = 'sans-serif', paddingSize = 8;
        await asyncForEach(annots, async (annot) => {
          const annoteName = annot?.Qa?.rd || annot?.name;
          if (annoteName === fieldKey) {
            const page = annot?.PageNumber;

            // Get rectangle coordinates
            const { x1, y1, x2, y2 } = annot?.rect || {};
            const x = annot?.X || x1;
            const y = annot?.Y || y1;
            const width = annot?.Width;
            const height = annot?.Height;
            const currentRect = { x, y, width, height, x1, y1, x2, y2 };

            // Add Digital Stamp to the signature field
            const digitalStamp = new Annotations.StampAnnotation();
            digitalStamp.PageNumber = page;
            digitalStamp.DateCreated = new Date().toISOString();
            digitalStamp.DateModified = new Date().toISOString();
            digitalStamp.name = `${fieldKey}_stamp`;
            digitalStamp.Qa = { rd: `${fieldKey}_stamp` };
            digitalStamp.Width = 100;
            digitalStamp.Height = 63;
            digitalStamp.Y = currentRect.y - 20;

            // Set X coordinates to be next to the signature image 
            let { width: embededImageWidth = 0 } = await getImageSize(value) || { width: Math.round(currentRect.width * 50) / 100 }; // Default right 50% of the annotation width

            // If embededImageWidth is bigger than currentRect.width than set right 50% of the annotation width 
            if (embededImageWidth > currentRect.width || !embededImageWidth) {
              digitalStamp.X = currentRect.x + (Math.round(currentRect.width * 50) / 100);
            } else {
              digitalStamp.X = currentRect.x + embededImageWidth;
            }

            let base64Value = generateTextImage(digitallySignedByStamp, { fontSize, fontFamily, paddingSize, width: digitalStamp.Width, height: digitalStamp.Height });
            await digitalStamp.setImageData(base64Value); // Set annotiation image
            await annotationManager.addAnnotation(digitalStamp);
            await annotationManager.redrawAnnotation(digitalStamp);

            // Capture stamp field object for callback  
            let cbParams = {
              key: digitalStamp.name,
              value: base64Value,
              input_type: 'stamp',
              is_required: false,
              page,
              rect: {
                x: digitalStamp.X,
                y: digitalStamp.Y,
                width: digitalStamp.Width,
                height: digitalStamp.Height,
              },
            };
            if (typeof cb === 'function') { // cb = onFieldChangeCallBack
              results.push(await cb(cbParams)); // Capture the stamp field 
            } else {
              SHOW_LOG && console.log("updateButtonAFImages digital stamp", cbParams);
            }
          }
        }); // END annots.forEach 
      } // END Add digitally signed by stamp to signature fields 

      // Update all corresponding af_image fields with the base64 image 
      // NOTE: fieldKey !== `${key}_af_image` is because we don't want to update the current key_af_image because it will be updated by the signature value 
      // and updated by af_image later. key_af_image1, key_af_image2, etc. will be updated by signature value and key_af_image later.
      const isAFImageField = fieldKey.includes(`${key}_af_image`);
      if ((['button'].includes(input_type) && isAFImageField && fieldKey !== `${key}_af_image`)) {
        field.setValue(value); // Set field value 
        await asyncForEach(annots, async (annot) => {
          const annoteName = annot?.Qa?.rd || annot?.name;
          if (annoteName === fieldKey) {
            const page = annot?.PageNumber;

            // Get rectangle coordinates 
            const { x1, y1, x2, y2 } = annot?.rect || {};
            const x = annot?.X || x1;
            const y = annot?.Y || y1;
            const width = annot?.Width;
            const height = annot?.Height;
            const currentRect = { x, y, width, height, x1, y1, x2, y2 };

            // Update the af_image field with the base64 image 
            const stampAnnot = new Annotations.StampAnnotation();
            stampAnnot.PageNumber = page;
            stampAnnot.X = currentRect.x;
            stampAnnot.Y = currentRect.y;

            // Use image width and height if available 
            const { width: embededImageWidth = 0, height: embededImageHeight = 0 } = await getImageSize(value) || { width: currentRect.width, height: currentRect.height };

            // If embededImageWidth is bigger than currentRect.width than set to width to currentRect.width
            if (embededImageWidth > currentRect.width || !embededImageWidth) {
              stampAnnot.Width = currentRect.width;
            } else {
              stampAnnot.Width = embededImageWidth;
            }
            stampAnnot.Height = embededImageHeight || currentRect.height;

            await stampAnnot.setImageData(value); // Set annotiation image 
            await annotationManager.addAnnotation(stampAnnot);
            await annotationManager.redrawAnnotation(stampAnnot);
            results.push({ key: annoteName, value, input_type, is_required: false, page, rect: currentRect });
          }
        });
      }
    } // END while fieldStack.length > 0 

    if (!foundAFImage) { // Create corresponding AF Image for signautre fields 
      let AFImage = fieldManager.getField(`${key}_af_image`);
      if (!AFImage) { // Create field if it doesn't exist 
        if (!page) {
          const currentAnnotation = annots.find((a) => a?.Qa?.rd === key);
          page = currentAnnotation?.PageNumber;
        }
        AFImage = new Annotations.StampAnnotation();
        AFImage.name = `${key}_af_image`;
        AFImage.Qa = { rd: `${key}_af_image` };
        AFImage.PageNumber = page;
        AFImage.X = rect.x;
        AFImage.Y = rect.y;

        // Use image width and height if available 
        const { width: embededImageWidth = 0, height: embededImageHeight = 0 } = await getImageSize(value) || { width: rect.width, height: rect.height };

        // If embededImageWidth is bigger than currentRect.width than set to width to currentRect.width 
        if (embededImageWidth > rect.width || !embededImageWidth) {
          AFImage.Width = rect.width;
        } else {
          AFImage.Width = embededImageWidth;
        }
        AFImage.Height = embededImageHeight || rect.height;

        AFImage.ImageData = ''; // Detaults to Draft stamp if value is not empty. 
        // NOTE: We don't need to use the value here because it would just stack on top of the existing signature value anyways. 
        // AFImage.ImageData = value; // alternatively can set annotation image by await AFImage.setImageData(value);

        await annotationManager.addAnnotation(AFImage);
        await annotationManager.redrawAnnotation(AFImage);
        results.push({ key: AFImage.name, value, input_type: 'button', is_required: false, page, rect });
      }
    } // END Create corresponding AF Image for signautre fields 

    return { key, value, digitallySignedByStamp, results };
  }; // END updateButtonAFImages 

  const handleUserPrompt = async ({ alertMissingFields = false, missingRequiredFieldsArray, ...cbParams } = {}) => {
    (typeof alertMissingFields === 'string') && (alertMissingFields = alertMissingFields.toLowerCase() === 'true');
    if (missingRequiredFieldsArray.length > 0) {
      SHOW_LOG && console.log(`[handleUserPrompt] Missing Required Fields: ${missingRequiredFieldsArray.join(', ')}`);
    }
    if (!alertMissingFields && missingRequiredFieldsArray.length > 0) {
      return; // Don't prompt the user if alertMissingFields is false
    }

    // Prompt the user to complete esign request 
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: missingRequiredFieldsArray.length === 0 ? "btn btn-lg btn-success" : "btn btn-lg btn-warning",
        cancelButton: missingRequiredFieldsArray.length === 0 ? "btn btn-lg btn-danger" : "btn btn-lg btn-secondary"
      },
      buttonsStyling: false
    });

    const { isConfirmed, ...results } = await swalWithBootstrapButtons.fire({
      title: missingRequiredFieldsArray.length === 0 ? "Almost Done" : "Missing Required Fields",
      html: missingRequiredFieldsArray.length === 0 ? `<p>All fields have been completed. Click below to submit the document.</p>` : `<p>Missing Required Fields: ${missingRequiredFieldsArray.join(', ')}</p>`,
      icon: missingRequiredFieldsArray.length === 0 ? 'success' : 'warning',
      confirmButtonText: missingRequiredFieldsArray.length === 0 ? 'Submit' : 'Ok'
    }); // Returns { isConfirmed: true, isDenied: false, isDismissed: false, dismiss: "backdrop" }

    if (isConfirmed) { // Submit the document 
      await captureCallback({ missingRequiredFieldsArray, ...cbParams });
    } else {
      console.log("User did not agree to sign document", results); // results = { isDenied: false, isDismissed: true, dismiss: "backdrop" }
    }
  }; // END handleUserPrompt 

  const resetFields = async () => {
    setWarningMessages([]);
    setToggleReset((prev) => !prev);
  };

  const initializeWebViewer = async () => {
    if (viewerRef?.current?.innerHTML) {
      return; // Already initialized WebViewer. Can't create more than 1 instance of WebViewer on the same HTML element.
    }
    const WebViewerParams = {
      path: '/webviewer/lib',
      licenseKey: PDFJS_LICENSE_KEY,
      css: '/webviewer/lib/custom.css',
      disableLogs: !SHOW_LOG,
      disabledElements: ['miscToolGroupButton', 'contextMenuPopup', 'toolsHeader']
    };
    instanceRef.current = await WebViewer(WebViewerParams, viewerRef.current);
    if (instanceRef.current) {
      setToggleReset((prev) => !prev);
    }
  }; // END initializeWebViewer

  const loadDocument = async (instance = instanceRef.current) => {
    // ====================================[ Load Document ]====================================
    setDocumentLoading(true);
    const { Core, docViewer, Annotations } = instance || {};
    const { documentViewer, annotationManager, Tools } = Core || {};
    let claimant_id, user_id, request_id, pdfStream, resp, signature_options = ['drawn', 'typed', 'image'], recipient_name = '';

    try {
      resp = await getPdfStream();
    } catch (error) {
      console.log("initializeWebViewer > getPdfStream Error", error);
      resp = error?.response || error || { status: 500, statusText: error?.message || 'Unknown Error', message: error?.message || 'Unknown Error' };
    }

    if (resp && resp instanceof Blob) {
      pdfStream = resp;
    } else if (resp && 'pdfStream' in resp && resp.pdfStream instanceof Blob) { // resp = { pdfStream, documentSigned, request_id, template_name, filename, signature_options, recipient_name }
      pdfStream = resp.pdfStream;
      pdfFilename = resp.filename || pdfFilename;
      request_id = resp?.request_id;
      recipient_name = resp?.recipient_name;
      if (resp?.signature_options) {
        signature_options = Array.isArray(resp.signature_options) ? resp.signature_options.map((opt) => opt.trim()) : resp.signature_options.split(",");
      }
    }

    if (!pdfStream) {
      const { message, statusText, status, documentSigned, error, redirectToUrl } = isObject(resp) ? resp || {} : {};
      if (!documentSigned) { // Only show error if document is not signed
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: message ? "btn btn-lg btn-success" : "btn btn-lg btn-warning",
            cancelButton: message ? "btn btn-lg btn-danger" : "btn btn-lg btn-secondary"
          },
          buttonsStyling: false
        });
        await swalWithBootstrapButtons.fire({
          title: message ? (isPreviewOnly ? 'Preview Error' : 'Document Not Signed') : 'Error', // title: message ? (documentSigned ? 'Document Is Signed' : 'Document Not Signed') : 'Error',
          text: message || statusText || status || error || 'Unable to load document, You do not have permission to view this document, are you logged in?',
          icon: message ? 'info' : 'error', // icon: message ? (documentSigned ? 'success' : 'info') : 'error',
          confirmButtonText: 'Ok'
        });
      }
      if (documentLoading) {
        console.log("[!pdfStream] Document Loading Timeout");
        setDocumentLoading(false);
      }

      // navigate to redirectToUrl after confirm button 
      if (redirectToUrl) {
        return navigate(redirectToUrl);
      }
      return;
    }

    instance.UI.loadDocument(pdfStream, { filename: pdfFilename });

    annotationManager.setCurrentUser(recipient_name); // Remove the default 'Guest' on text signature
    const esignInfoKeys = ['document_signed', 'filename', 'recipient_email', 'recipient_name', 'request_id', 'template_id', 'template_name', 'signature_options'];
    const esignInfo = Object.entries(renameKeys({ documentSigned: "document_signed" }, resp)).reduce((acc, [k, v]) => ({
      ...acc, ...(esignInfoKeys.includes(k) && {
        [humanize(k)]: Array.isArray(v) ? v.join(', ') : v
      })
    }), {});
    SHOW_LOG && console.table(esignInfo);

    // ====================================[ Setup UI ]========================================
    docViewer.on('toolModeUpdated', (annot) => {  // Force tool mode to always be 'Pan' 
      if (annot.name !== 'Pan') {
        SHOW_LOG && console.log(`toolModeUpdated from ${annot.name} to Pan`);
        instance.setToolMode('Pan');
      }
    });

    // Disable Tools when right clicking on the document 
    const disabledTools = ['EDIT', 'STICKY', 'FREETEXT', 'HIGHLIGHT', 'FREEHAND', 'FREEHAND_HIGHLIGHT', 'ARROW', 'ARROW2', 'ARROW3', 'ARROW4'].map((tool) => Tools.ToolNames[tool]);
    // console.log("All Tools", Tools.ToolNames);
    instance.UI.disableTools(disabledTools);

    // Disable Elements: hide the ribbons, second header, and signature panel buttons 
    const disabledElements = [];
    const signatureOptKey = { drawn: 'inkSignaturePanel', typed: 'textSignaturePanel', image: 'imageSignaturePanel' };
    if (!Array.isArray(signature_options) || signature_options.length === 0) {
      signature_options = Object.keys(signatureOptKey); // Default to all signature options 
    }

    const disabledSignatureButtons = Object.entries(signatureOptKey)
      .reduce((acc, [opt, panelElement]) => ([...acc, ...(!signature_options.includes(opt) ? [panelElement, `${panelElement}Button`] : [])]), []);
    disabledElements.push(...disabledSignatureButtons);
    if (disabledElements.length > 0) {
      instance.UI.disableElements(disabledElements); // Hide all disabledElements
    }

    // Set the currently selected tab of 'signatureModal' to be the 'Type' panel.
    const selectedSignatureButton = `${signatureOptKey[signature_options[0] || 'drawn']}Button`;
    instance.UI.setSelectedTab('signatureModal', selectedSignatureButton);

    // ====================================[ Add Event Listeners ]=============================
    // ------------------------[ Page Number Updated Event Listener ]--------------------------
    Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
      const pageCount = Core.documentViewer.getPageCount();
      SHOW_LOG && console.log(`[pageNumberUpdated] Page number is: ${pageNumber} of ${pageCount}`);

      // Toggle padding bottom on the last page 
      const isLastPage = pageNumber === pageCount;
      const viewerEl = document.querySelector('.PDFJSWebViewer .webviewer');
      if (viewerEl && isLastPage && viewerEl.style.paddingBottom !== "80px") {
        viewerEl.style.paddingBottom = '80px';
      } else if (viewerEl && !isLastPage && viewerEl.style.paddingBottom !== "0px") {
        viewerEl.style.paddingBottom = '0px';
      }
    }); // END pageNumberUpdated Event Listener 

    // ------------------------[ Load Error Event Listener ]-----------------------------------
    instance.UI.iframeWindow.addEventListener(instance.UI.Events.LOAD_ERROR, (error) => {
      console.log('-------------------------[ Load Error ]-------------------------');
      const errorMessage = `Error loading document: ${error?.detail?.message}`;
      console.log(error, errorMessage);
      setWarningMessageType("danger");
      setWarningMessages((prev) => [...new Set([...prev, errorMessage])]); // Remove duplicate errors 
      if (documentLoading) {
        console.log(`[${instance.UI.Events.LOAD_ERROR}] Document Loading Timeout`);
        setDocumentLoading(false);
      }
    }); // END Load Error Event Listener 

    // ------------------------[ Signature Event Listener ]------------------------------------
    const iframeDoc = instance.UI.iframeWindow.document;
    const signatureInput = iframeDoc.querySelector('.text-signature-input');
    const signatureCreateButton = iframeDoc.querySelector('[data-element="signatureModal"] .signature-create');
    signatureCreateButton.innerText = "Save Signature"; // Change text of signature button 

    if (signatureInput) {
      signatureInput.addEventListener('input', (e) => {
        let inputValue = e.target.value.trim().toLowerCase();
        if (inputValue === 'guest') {
          signatureCreateButton.disabled = true;
        } else {
          signatureCreateButton.removeAttribute('disabled');
        }
      }); // END signatureInput Event Listener 
    }

    // ------------------------[ Annotation Changed Event Listener ]---------------------------
    const signatureDateFields = []; // Used to adjust the background of signature date fields to transparent 
    annotationManager.addEventListener('annotationChanged', async (annotations, action, { imported = false, isUndoRedo = {}, autoFocus = false } = {}) => {
      if (annotations.length === 0 || imported) return; // imported: true is when the annotationChanged event will also be fired whenever annotations are imported from your server or inside the document, that is, they weren't created directly by a user.

      let digitallySignedByStamp = '';
      if (action === 'add') {
        digitallySignedByStamp = `Digitally signed${annotationManager.getCurrentUser() ? " by " + annotationManager.getCurrentUser() : ""} Date: ${new Date().toLocaleString('it-IT')}`;
        annotations[0].setCustomData('digitallySignedByStamp', digitallySignedByStamp);
      }

      let renameTypeFields = {
        Tx: 'text',
        Btn: 'button',
        Ch: 'checkbox',
        Sig: 'signature',
      };

      /** 
       * Loop through each annotation 
       * - Get corresponding date field so we can update it's background to transparent later 
       * - Update the corresponding af button fields
       * - Prompt user when all fields are completed
       * - Run onFieldChangeCallBack
       **/
      await asyncForEach(annotations, async (annot) => {
        const isWidgetAnnotation = annot instanceof Annotations.WidgetAnnotation;
        const isSignatureWidgetAnnotation = annot instanceof Annotations.SignatureWidgetAnnotation;
        const shouldHandleChanges = (isWidgetAnnotation && isSignatureWidgetAnnotation) || (!isWidgetAnnotation && !isSignatureWidgetAnnotation);
        if (!shouldHandleChanges) return;

        const annots = await annotationManager.getAnnotationsList();
        let currentAnnotation = annot;
        let base64 = null;
        if (isWidgetAnnotation && isSignatureWidgetAnnotation) {
          base64 = generateAnnotationImage(annot, 0, instance, 4);
        } else {
          if (annot?.ToolName) {
            if (annot.ToolName === 'AnnotationCreateFreeHand') {
              base64 = generateAnnotationImage(annot, 0, instance, 4);
            } else if (annot.ToolName === 'AnnotationCreateRubberStamp') {
              base64 = annot?.image?.src;
            }
          }

          const annotId = annot?.Id || annot?.Yz;
          currentAnnotation = annots.find((a) => a?.Bz?.Id === annotId || a?.Bz?.Yz === annotId) || annot;
        }

        const value = base64 || currentAnnotation?.value;
        const field = currentAnnotation?.Qa;
        const key = currentAnnotation?.fieldName || field?.rd || field?.name;
        const input_type = field?.type in renameTypeFields ? renameTypeFields[field.type] : field?.type || field?.Ar;
        const is_required = field?.flags?.get('Required') || !key;
        const isReadOnly = field?.flags?.get('ReadOnly');
        const page = currentAnnotation?.PageNumber;

        // Get rectangle coordinates 
        const { x1, y1, x2, y2 } = currentAnnotation?.rect || {};
        const x = currentAnnotation?.X || x1;
        const y = currentAnnotation?.Y || y1;
        const width = currentAnnotation?.Width;
        const height = currentAnnotation?.Height;
        const rect = { x, y, width, height, x1, y1, x2, y2 };
        let changedParams = { key, value, input_type, is_required, isReadOnly, page, rect, signature_options, action, imported, isUndoRedo, autoFocus };

        // Get corresponding date field so we can update it's background to transparent later 
        const signatureDateFieldName = `${key}_date`;
        const signatureDateField = annots.find((a) => a?.Qa?.rd === signatureDateFieldName);
        if (signatureDateField) {
          signatureDateFields.push(signatureDateFieldName);
        }

        const signatureNotEmpty = input_type === 'signature' && value && emptySignatureValues.every((emptyValue) => value !== emptyValue) && action && (autoFocus || isUndoRedo);
        if (signatureNotEmpty) {
          await updateButtonAFImages({ key, value, rect, page, digitallySignedByStamp, cb: onFieldChangeCallBack });
          const cbParams = { pdf_filename: pdfFilename, action: 'handleParentCapture', return_type: "immediate", alertMissingFields: false, scrollToMissingField: false }; // return_type: "json" will NOT download a pdf. return_type: "pdf" will download a pdf
          const captureResponse = await captureFields(instanceRef.current, handleUserPrompt, cbParams);
          changedParams = { ...changedParams, ...captureResponse };
        } // END signatureNotEmpty 

        if (input_type) {
          if (typeof onFieldChangeCallBack === "function") {
            await onFieldChangeCallBack(changedParams);
          } else {
            SHOW_LOG && console.log("annotationChanged", changedParams);
          }
        }
      }); // END forEach annotation 
    }); // END annotationChanged Event Listener 

    // ------------------------[ Field Changed Event Listener ]--------------------------------
    annotationManager.addEventListener('fieldChanged', async (field, value) => {
      let renameTypeFields = {
        Tx: 'text',
        Btn: 'button',
        Ch: 'checkbox',
        Sig: 'signature',
      };
      const key = field.name || field.rd;
      const input_type = field.type in renameTypeFields ? renameTypeFields[field.type] : field.type || field.Ar;
      const is_required = field?.flags?.get('Required');
      const page = field?.pageNumber;
      let changedParams = { key, value, input_type, is_required, page };

      if (changedParams.input_type !== 'signature' && changedParams.value && changedParams.is_required) {
        const cbParams = { alertMissingFields: false, scrollToMissingField: false, pdf_filename: pdfFilename, action: 'handleParentCapture', return_type: "immediate" }; // return_type: "json" will NOT download a pdf. return_type: "pdf" will download a pdf
        const captureResponse = await captureFields(instanceRef.current, handleUserPrompt, cbParams);
        changedParams = { ...changedParams, ...captureResponse };
      }

      if (typeof onFieldChangeCallBack === "function") {
        await onFieldChangeCallBack(changedParams);
      } else {
        SHOW_LOG && console.log('fieldChanged', changedParams);
      }
    });

    // ------------------------[ Document Loaded Event Listener ]------------------------------
    Core.documentViewer.addEventListener(instance.UI.Events.DOCUMENT_LOADED, async () => {
      console.log('-------------------------[ document loaded ]-------------------------');
      if (documentLoading) {
        await sleep(2); // Wait for 2 seconds and then check again if document is still loading
        if (documentLoading) {
          SHOW_LOG && console.log(`[${instance.UI.Events.DOCUMENT_LOADED}] Document Loading Timeout`);
          setDocumentLoading(false);
        }
      }

      // Set the default tool mode to 'Pan'
      instance.setToolMode('Pan');

      // Set xfdf initial data 
      documentViewer.getAnnotationsLoadedPromise().then(async () => {
        if (xfdfInitialState) {
          await instance.Core.annotationManager.importAnnotations(xfdfInitialState);
        }
      });

      // Set Custom Styles 
      const customStyles = (widget) => {
        if (signatureDateFields.includes(widget.fieldName)) {
          return {
            'background-color': 'transparent',
          };
        } else if (widget instanceof Annotations.SignatureWidgetAnnotation) {
          // do nothing for signature fields 
        } else if (widget instanceof Annotations.PushButtonWidgetAnnotation) {
          // return { // Removed border-bottom from push button fields 04/05/2024 CC 
          //   'border-bottom': '2.39062px solid #000',
          // };
        } else {
          return {
            'font-weight': 'bold',
            'color': '#1e40af',
            'background-color': 'transparent',
          };
        }
      };
      Annotations.WidgetAnnotation.getCustomStyles = customStyles;

      // Set Header Buttons 
      let cbParams = {
        pdf_filename: pdfFilename,
        ...(claimant_id && { claimant_id }),
        ...(user_id && { user_id }),
        ...(request_id && { request_id }),
      };

      const resetButton = {
        type: 'actionButton',
        img: 'cancel-24px',
        title: 'Reset Fields',
        onClick: () => (resetFields()),
        dataElement: 'resetButton',
      };

      const printButton = {
        type: 'actionButton',
        img: 'icon-header-print-line',
        title: 'Print Flattened PDF',
        onClick: () => {
          instance.UI.print()
        },
        dataElement: 'printButton',
      };

      const downloadZipButton = {
        type: 'actionButton',
        img: 'ic_fileattachment_24px',
        title: 'Generate Flattened PDF, and download as zip',
        onClick: () => (captureFields(instance, captureCallback, { ...cbParams, return_type: 'zip' })),
        dataElement: 'downloadZipButton',
      };

      const downloadPdfButton = {
        type: 'actionButton',
        img: 'icon-header-download',
        title: 'Generate Flattened PDF',
        onClick: () => (captureFields(instance, captureCallback, { ...cbParams, return_type: 'pdf' })),
        dataElement: 'downloadPdfButton',
      };

      const sendReminderEmailButton = {
        type: "actionButton",
        img: "ic_extract_black_24px",
        title: "Send Reminder Email",
        onClick: () => (captureFields(instance, captureCallback, { ...cbParams, action: 'sendReminderEmail' })),
        dataElement: "sendReminderEmailButton",
      };

      const infoButton = {
        type: 'actionButton',
        img: 'icon-info',
        title: 'Show Field Data',
        onClick: () => (captureFields(instance, null, null)),
        dataElement: 'infoButton',
      };

      const settingsButton = {
        type: "toggleElementButton",
        dataElement: "menuButton",
        element: "menuOverlay",
        img: "icon-header-settings-line",
        title: "component.menuOverlay",
        hidden: [
          "small-mobile"
        ]
      };

      const moreButton = {
        type: "actionButton",
        dataElement: "moreButton",
        title: "action.more",
        img: "icon-tools-more",
        hidden: ["mobile", "tablet", "desktop"]
      };

      const togglePagesButton = {
        type: "toggleElementButton",
        img: "icon-header-sidebar-line",
        element: "leftPanel",
        dataElement: "leftPanelButton",
        title: "component.leftPanel"
      };

      const keepHeaderItems = [{
        type: "customElement",
        dataElement: "zoomOverlayButton",
        element: "zoomOverlay",
        hiddenOnMobileDevice: true
      },
      {
        type: "toolButton",
        toolName: "Pan"
      }];
      let enabledButtons = [resetButton, infoButton, printButton, downloadZipButton, downloadPdfButton, sendReminderEmailButton, settingsButton, moreButton]
        .filter((btn) => (headerButtons.includes(btn.dataElement)));

      instance.UI.setHeaderItems((header) => {
        const headerItems = header.getItems() || [];
        const filteredHeaderItems = headerItems.filter(item => keepHeaderItems.some(keepItem => keepItem.type === item.type && keepItem.dataElement === item.dataElement && keepItem.toolName === item.toolName));
        return header.update([togglePagesButton, { type: "divider" }, ...filteredHeaderItems, { type: "divider" }, ...enabledButtons]);
      });
    }); // END documentLoaded Event Listener 

    if (documentLoading) {
      SHOW_LOG && console.log("[loadDocument] Document Loading Timeout");
      setDocumentLoading(false);
    }
  }; // END loadDocument function 

  useEffect(() => {
    let mounted = true;
    const init = async () => (await initializeWebViewer());
    mounted && init();
    return () => mounted = false;
  }, []);

  useEffect(() => {
    if (instanceRef.current) {
      loadDocument();
    }
  }, [toggleReset, toggleResetFromParent]);

  useEffect(() => {
    const runTrigger = async () => {
      if (typeof parentCaptureTrigger !== 'boolean') {
        SHOW_LOG && console.log("parentCaptureTrigger is not a boolean so not calling captureFields");
        captureFields(instanceRef.current, null, null);
        return;
      }

      const cbParams = { pdf_filename: pdfFilename, action: 'handleParentCapture', return_type: "immediate", alertMissingFields: true, scrollToMissingField: true }; // return_type: "json" will NOT download a pdf. return_type: "pdf" will download a pdf 
      return await captureFields(instanceRef.current, captureCallback, cbParams);
    }
    if (!documentLoading) {
      runTrigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentCaptureTrigger]);

  return (<div className="PDFJSWebViewer h-100" {...props}>
    <div className="webviewer h-100" ref={viewerRef} style={{ paddingBottom: "0px" }} ></div>
  </div>);
};

export default PDFJSWebViewer;