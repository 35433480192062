const sharedStyles = {
  primaryColor: "#5ab3ea",       // dark blue
  primaryColorDarker: "#0a2d69", // darker blue
  secondaryColor: "#144291",     // dark blue
  blue: "#144291",
  red: "#E61B1B",
  darkRed: "#ac1010",
  green: "#2F9B2F",
  lightGreen: "#5BAF55",
  darkGreen: "#1a6a1a",
  primaryTextColor: "#2b2b2b",   // black
  linkColor: "#144291",          // dark blue
  subLinkColor: "#2F9B2F",       // green
  lightGrey: "#ECECEC",
  darkGrey: "#d1d1d1",
  grayLine: "#c7c7c7",
  gray: "#CDCDCD",
  white: "#ffffff",
  mutedText: "#b7b7b7",
  gray2: "#f1f3f5",
};

export const lightTheme = {
  ...sharedStyles,
};

export const darkTheme = {
  ...sharedStyles,
};