import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
:root {
  --accent-text: ${({ theme: { textBlue = "#64d2ff" } = {} } = {}) => textBlue};
  --body-text: #1E2022;
  --primary-menu-background: #52616B;
  --secondary-menu-text: #F0F5F9;
  --secondary-menu-background: rgba(0, 0, 0, 0.8);
  --body-background: #F0F5F9;
  --button-text: #C9D6Df;
  --button-background: #1E2022;
  --cool-red: #E9290F;
  --footer-hover-text: rgba(233, 41, 15, .9);
  --primary-color: ${({ theme: { primaryColor = "#144291" } = {} } = {}) => primaryColor}; // dark blue
  --primary-color-darker:${({ theme: { primaryColorDarker = "#0a2d69" } = {} } = {}) => primaryColorDarker}; // darker blue
  --secondary-color: ${({ theme: { secondaryColor = "#144291" } = {} } = {}) => secondaryColor}; // dark blue
  --blue: ${({ theme: { blue = "#144291" } = {} } = {}) => blue};
  --red: ${({ theme: { red = "#E61B1B" } = {} } = {}) => red};
  --dark-red:${({ theme: { darkRed = "#ac1010" } = {} } = {}) => darkRed};
  --green:${({ theme: { green = "#2F9B2F" } = {} } = {}) => green};
  --light-green:${({ theme: { lightGreen = "#5BAF55" } = {} } = {}) => lightGreen};
  --dark-green: ${({ theme: { darkGreen = "#1a6a1a" } = {} } = {}) => darkGreen};
  --primary-text-color: ${({ theme: { primaryTextColor = "#2b2b2b" } = {} } = {}) => primaryTextColor};  // black
  --link-color: ${({ theme: { linkColor = "#144291" } = {} } = {}) => linkColor}; // dark blue
  --sub-link-color: ${({ theme: { subLinkColor = "#2F9B2F" } = {} } = {}) => subLinkColor}; // green
  --light-grey: ${({ theme: { lightGrey = "#ECECEC" } = {} } = {}) => lightGrey};
  --dark-grey: ${({ theme: { darkGrey = "#d1d1d1" } = {} } = {}) => darkGrey};
  --grey: ${({ theme: { grey = "#CDCDCD" } = {} } = {}) => grey};
  --gray-line: ${({ theme: { grayLine = "#c7c7c7" } = {} } = {}) => grayLine};
  --white: ${({ theme: { white = "#ffffff" } = {} } = {}) => white};
  --muted-text: ${({ theme: { mutedText = "#b7b7b7" } = {} } = {}) => mutedText};
  --text-orange: ${({ theme: { textOrange = "#ff9f0a" } = {} } = {}) => textOrange};
  --text-yellow: ${({ theme: { textYellow = "#ffd60a" } = {} } = {}) => textYellow};
  --text-red: ${({ theme: { textRed = "#ff453a" } = {} } = {}) => textRed};
  --text-green: ${({ theme: { textGreen = "#32d74b" } = {} } = {}) => textGreen};
  --text-blue: ${({ theme: { textBlue = "#64d2ff" } = {} } = {}) => textBlue};
  --text-purple: ${({ theme: { textPurple = "#bf5af2" } = {} } = {}) => textPurple};
  --gray-2: ${({ theme: { gray2 = "#f1f3f5" } = {} } = {}) => gray2};
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bold-text {
  font-weight: bold !important;
}

.App {
	text-align: left;
  height: 100vh;
  width: 100%;
}

h1,
h3 {
	text-align: center;
}

.page-container {
  padding: 0px 25px;
  margin: 0 auto;
  max-width: 2000px;
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-orange {
  color: var(--text-orange);
}

.text-yellow {
  color: var(--text-yellow);
}

.text-red {
  color: var(--text-red);
}

.text-green {
  color: var(--text-green);
}

.text-blue {
  color: var(--text-blue);
}

.text-purple {
  color: var(--text-purple);
}

.text-success {
  color: var(--text-green);
}

.text-danger {
  color: var(--text-red);
}

li.active, 
.pagination li.active .page { 
  color: var(--primary-color) !important;
}

.btn-falcon-default {
  color: #4d5969;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 1px rgba(43,45,80,0.1),0 2px 5px 0 rgba(43,45,80,0.08),0 1px 1.5px 0 rgba(0,0,0,0.07),0 1px 2px 0 rgba(0,0,0,0.08);
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  color: var(--button-text); 
  background-color: var(--button-background); 
  border-color: #fff;
  box-shadow: 0 0 0 1px rgba(43,45,80,0.1),0 2px 5px 0 rgba(43,45,80,0.08),0 1px 1.5px 0 rgba(0,0,0,0.07),0 1px 2px 0 rgba(0,0,0,0.08);
}

.bg-dark {
  color: #fff;
}

.header-main-nav {
  margin-left: 30px;
  margin-right: 30px;
  cursor: pointer;
}

.login-form {
  min-width: 350px;
}

#main-footer { 
  position: relative;
  padding: 15px 30px; 
  bottom: 0;
  width: 100%; 
}

#all-accounts-list > .list-group-item {
  cursor: pointer; 
}

.form-control {
  font-size: 1rem; 
}

.form-control.bg-dark:focus {
  color: var(--primary-color);
}

.cursor-pointer {
  cursor: pointer;
}

iframe#webpack-dev-server-client-overlay{
  display:none!important
}

.card-header {
  background-color: transparent;
  border-bottom: none; 
  padding: 0.5rem; 
}

.bg-auth-circle-shape {
  position: absolute;
  right: -8.75rem;
  top: -5.125rem;
}

.bg-auth-circle-shape-2 {
  position: absolute;
  left: -6.25rem;
  bottom: -2.4375rem;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.esign-doc {
  background-color: var(--gray-2);  
}

.PDFJSWebViewer {
  background-color: var(--gray-2);  
}

.top-bar {
  background-color: #025cb9;
  color: #FFFFFF;
}
  
.cursor-pointer {
  cursor: pointer;
}
`;