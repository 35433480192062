import React, { useContext, useState, useEffect } from "react";
import { UserAPI } from "../utils/API";
import { pdfAPI } from "../utils/pdf-api";
import { sleep } from "../utils/";

const AuthContext = React.createContext(-1);
const useUserData = () => useContext(AuthContext);

const defaultUserState = {
  token: "",
  email: "",
  hasAccessToPage: false, // Does the user have access to the page?
  hasAccessToPages: [],   // What pages does the user have access to?
  isAuthenticated: false, // Are we logged in? 
  referrer: "/",          // The referrer of the user
  request_id: "",         // The request_id of the user
  role_name: "",          // The role_name of the user 
  profile_picture: "/images/other/user_profile_image_default.png",
  access_token: "",
  refresh_token: "",
};

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(defaultUserState);

  const checkUserSession = async () => {
    try {
      await sleep(0.5); // Wait half a second before checking user session 
      let res = await UserAPI.session();
      let { status, data = {} } = res || {};
      if (status === 200) {
        setUserData(prevState => ({ ...prevState, ...data, isAuthenticated: true }));
      } else {
        setUserData(defaultUserState);
        throw new Error(typeof res?.text === 'function' ? await res.text() : res?.text || res?.statusText || res?.status || res?.message || 'Unknown Error');
      }
    } catch (error) {
      console.error('checkUserSession Error:', error);
    }
  };

  const handleLogin = async ({ email = "noreply@esignapp.com", password, referrer, ...formFields } = {}) => {
    let resp;
    try {
      resp = await UserAPI.tryLogin({ email, password, referrer, ...formFields });
    } catch (error) {
      resp = error?.response;
    }

    let { status, statusText, data = {} } = resp || {};
    let { isVerified = false, hasAccessToPage = null, hasAccessToPages = [], status: statusMessage = '', message = '', role_name = '', tokenData = {}, access_token: accessToken, refresh_token: refreshToken, ...userFields } = data || {};
    // if (accessToken && accessToken !== tokenData.access_token) {
    //   tokenData.access_token = accessToken;
    // }
    // if (refreshToken && refreshToken !== tokenData.refresh_token) {
    //   tokenData.refresh_token = refreshToken;
    // }
    const { access_token, refresh_token, email: tokenEmail } = tokenData || {};
    if (Object.keys(tokenData).length > 0) {
      Object.entries(tokenData).forEach(([key, value]) => {
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem(key, value);
        } else if (typeof window?.localStorage !== 'undefined') {
          window.localStorage.setItem(key, value);
        }
      });
    }

    if (typeof data === 'string') {
      message = data;
    } else if (statusMessage && !message) {
      message = statusMessage;
    }

    if (isVerified) {
      if (formFields.template_id) {
        hasAccessToPage = `/templates/${formFields.template_id}`;
      }
      setUserData(prevState => ({
        ...prevState,
        hasAccessToPage,
        hasAccessToPages,
        isAuthenticated: isVerified,
        role_name,
        ...(tokenEmail && { email: tokenEmail }),
        access_token,
        refresh_token,
        ...userFields,
        ...(!prevState?.referrer?.includes(hasAccessToPage) && { referrer: hasAccessToPage })
      }));
    } else {
      setUserData(prevState => ({ ...prevState, isAuthenticated: false, hasAccessToPage: false }));
    }

    return { isVerified, hasAccessToPage, hasAccessToPages, status, statusText, message };
  };

  const handleLogout = async (e) => {
    if (e) e.preventDefault();
    setUserData(defaultUserState);
    try {
      return await UserAPI.logout();
    } catch (error) {
      console.error('handleLogout Error:', error);
    }
  };

  const verifyAccess = async (params = {}) => {
    let resp;
    try {
      resp = await pdfAPI.verifyAccessToEsignRequest(params);
    } catch (error) {
      resp = error?.response;
    }

    let { status, statusText, data = {} } = resp || {};
    let { isVerified = false, request_id = params?.request_id, hasAccessToPage = null, status: statusMessage = '', message = '', tokenData = {} } = data || {};
    if (Object.keys(tokenData).length > 0) {
      Object.entries(tokenData).forEach(([key, value]) => {
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem(key, value);
        } else if (typeof window?.localStorage !== 'undefined') {
          window.localStorage.setItem(key, value);
        }
      });
    }

    if (typeof data === 'string') {
      message = data;
    } else if (statusMessage && !message) {
      message = statusMessage;
    }

    if (isVerified) {
      if (hasAccessToPage !== `/esign/${request_id}`) {
        hasAccessToPage = `/esign/${request_id}`;
      }
      setUserData(prevState => ({
        ...prevState,
        hasAccessToPage,
        isAuthenticated: isVerified,
        ...(!prevState?.referrer?.includes(hasAccessToPage) && { referrer: hasAccessToPage })
      }));
    } else {
      setUserData(prevState => ({ ...prevState, isAuthenticated: false, hasAccessToPage: false }));
    }

    return { isVerified, request_id, hasAccessToPage, status, statusText, message };
  };

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        checkUserSession,
        handleLogin,
        handleLogout,
        verifyAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useUserData };
