import React from "react";
import { Row, Col } from 'react-bootstrap';
import { humanize } from "../utils/";
const APP_NAME = process.env.REACT_APP_NAME || "assure-esign";

const Footer = ({ theme, ...props } = {}) => {
  return (<footer id="main-footer" {...props}>
    <div id="footer-bottom">
      <div id="footer-info">
        <Row>
          <Col className="d-flex justify-content-start">
            Copyright © {new Date().getFullYear()} {humanize(APP_NAME.replace("cc-", ""))}
          </Col>
        </Row>
      </div>
    </div>
  </footer>);
}

export default Footer;
