import { useState, useRef } from "react";
import { Form as BootstrapForm, Button, Spinner } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faFloppyDisk, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { pdfAPI } from "../../utils/pdf-api";

export default function TagForm({ tag = null, isNewTag = false, closeModal = () => { }, children, ...props } = {}) {
  const { tag_id, title: initialTitle } = tag || {};
  const initialFormValues = {
    tag_id, // Pass this so formik can update whenever this values changes. NOTE: enableReinitialize={true} is required for this to work  
    title: initialTitle,
  };

  const formRef = useRef(null);
  const [editable, setEditable] = useState(isNewTag);

  const updateTag = async (values) => {
    let { title = '', ...otherValues } = values || {};
    const formValues = { title, ...otherValues };

    let resp;
    try {
      setEditable(false);
      resp = await pdfAPI.updateTag(tag_id, formValues);
    }
    catch (error) {
      console.error(error)
    } finally {
      if (typeof closeModal === 'function') {
        closeModal();
      }
    }

    let { status, statusText, data = {} } = resp || {};
    if (status !== 200) {
      console.log(data);
      console.error(`updateTag Error: ${statusText} (${status})`);
    }

    return data;
  };

  const deleteTag = async (values, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let tagID = values?.tag_id || tag_id;
    if (!tagID) {
      console.error("deleteTag Error: No tag ID provided", values);
    }

    let resp;
    try {
      resp = await pdfAPI.deleteTagById(tagID);
    }
    catch (error) {
      console.error(error)
    } finally {
      if (typeof closeModal === 'function') {
        closeModal();
      }
    }

    let { status, statusText, data = {} } = resp || {};
    if (status !== 200) {
      console.log(data);
      console.error(`deleteTag Error: ${statusText} (${status})`);
    }

    return data;
  };

  return (<Formik
    enableReinitialize
    initialValues={initialFormValues}
    onSubmit={async (values, { setSubmitting }) => {
      await updateTag(values);
      setSubmitting(false);
    }}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm, setFieldValue }) => (<Form ref={formRef} className="mt-4 position-relative" encType="multipart/form-data">
      <BootstrapForm.Group className="my-2">
        <BootstrapForm.Label className="h6">Tag Name</BootstrapForm.Label>
        <Field as={BootstrapForm.Control} type="text" disabled={!editable || isSubmitting} name="title"></Field>
      </BootstrapForm.Group>

      <div className="d-flex justify-content-end">
        {editable ? <Button type="submit" className="my-3 me-2" variant="success">
          <FontAwesomeIcon icon={faFloppyDisk} size="1x" /> {tag_id ? "Save" : "Create"}
        </Button> : <Button className="my-3 me-2" variant="danger" onClick={(e) => deleteTag(values, e)}>
          <FontAwesomeIcon icon={faTrashCan} size="1x" /> Delete
        </Button>}
        <Button className="my-3" variant="primary" onClick={() => {
          editable && resetForm();
          setEditable((prevState) => !prevState);
        }}><FontAwesomeIcon icon={faPenToSquare} size="1x" /> {editable ? "Cancel Edit" : "Edit"}
        </Button>
      </div>

      {Object.keys(tag || {}).length === 0 && !isNewTag &&
        <Spinner animation="border" role="status" aria-hidden="true" className="spinner-lg position-absolute bottom-50 start-50">
          <span className="visually-hidden">Loading...</span>
        </Spinner>}
    </Form>)}
  </Formik>);
}; 